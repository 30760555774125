import React, { useState } from 'react'
import Header from '../components/Layout/Header'
import styles from '../styles/styles'
import ProfileSideBar from "../components/Profile/ProfileSideBar";
import ProfileContent from "../components/Profile/ProfileContent";

const ProfilePage = () => {
    const[active,setActive] = useState(1);
  return (
    <div>
        <Header />
        <div className='h-[11vh]'></div>
        <div className="w-[100%] h-[89vh] bg-[#f5f5f5]">
            <div className={`${styles.container} flex gap-5 py-10 bg-[#f5f5f5]`}>
                <div className="w-[50px] 800px:w-[335px] sticky 800px:mt-0 mt-[18%]">
                    <ProfileSideBar active={active} setActive={setActive} />
                </div>
                <ProfileContent active={active} />
            </div>
        </div>
    </div>
  )
}

export default ProfilePage
