import React, { useState, useCallback } from "react";
import { navItems } from "../../static/data";
import { Link } from "react-router-dom";
import { BiChevronDown, BiChevronUp, BiSolidUserDetail } from "react-icons/bi";
import "../../styles/styles.css";
import { AiOutlineHeart } from "react-icons/ai";
import Wishlist from "../Wishlist/Wishlist.jsx";
import { BsCartPlus } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { useSelector } from "react-redux";
import { GrLogin } from "react-icons/gr";
import Drawer from "@mui/material/Drawer";

const Navbar = ({ active }) => {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { isAuthenticated } = useSelector((state) => state.user);
  const [dropDown, setDropDown] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  return (
    <div className="flex 800px:flex-row 800px:items-center 800px:justify-between flex-col">
      {navItems &&
        navItems.map((i, index) => (
          <div className="800px:py-0 py-3">
            {index === 6 ? (
              // <Link
              //   to={i.url}
              //   className="relative text-[#fff] bg-[#3C3C3C] border-[1.5px] border-[#3C3C3C] rounded-[2em] py-[0.6rem] px-[0.8rem] font-[1rem] cursor-pointer uppercase ml-3 800px:ml-0"
              // >
              //   {i.title}
              //   <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
              //     1
              //   </span>
              // </Link>
              <Link to={i.url} className="relative">
                <FiShoppingCart
                  // size={25}
                  color="#3C3C3C"
                  className="cursor-pointer ml-[10px] text-[25px] 1000px:text-[25px] 800px:text-[22px]"
                />
                {cart?.length > 0 && (
                  <span className="absolute 800px:-right-1 left-7 -top-1 rounded-full bg-[#3bc177] w-3.5 h-3.5 top right p-0 m-0 text-white font-mono text-[10px] leading-tight text-center">
                    {cart && cart.length}
                  </span>
                )}
              </Link>
            ) : index === 8 ? (
              <Link to={isAuthenticated ? "/profile" : "/login"}>
                {isAuthenticated ? (
                  <BiSolidUserDetail
                    size={33}
                    color="#3C3C3C"
                    className="cursor-pointer ml-[10px] text-[33px] 1000px:text-[33px] 800px:text-[31px]"
                    title="profile"
                  />
                ) : (
                  <GrLogin
                    // size={23}
                    color="#3C3C3C"
                    className="cursor-pointer ml-[10px] text-[23px] 1000px:text-[23px] 800px:text-[21px]"
                    title="login"
                  />
                )}
              </Link>
            ) : index === 7 ? (
              <>
                <div
                  className="relative cursor-pointer"
                  onClick={openDrawer}
                >
                  <AiOutlineHeart
                    // size={27}
                    color="#3C3C3C"
                    className="cursor-pointer ml-[10px] text-[27px] 1000px:text-[27px] 800px:text-[25px]"
                  />
                  {wishlist?.length > 0 && (
                    <span className="absolute 800px:right-0 left-7 -top-1 rounded-full bg-[#3bc177] w-3.5 h-3.5 top right p-0 m-0 text-white font-mono text-[10px] leading-tight text-center">
                      {wishlist && wishlist.length}
                    </span>
                  )}
                </div>
                {/* wishlist popup */}
                <Drawer anchor="right" open={isVisible} onClose={closeDrawer}>
                  <Wishlist setIsVisible={setIsVisible} />
                </Drawer>
              </>
            ) : index === 5 ? (
              <>
                <div
                  className={`${
                    active === 6
                      ? "font-Poppins font-semibold"
                      : active === 7
                      ? "font-Poppins font-semibold"
                      : active === 8
                      ? "font-Poppins font-semibold"
                      : "font-Poppins font-[400]"
                  } cursor-pointer relative w-[80px] hover:bg-[#3bc177] hover:text-white rounded-md py-2 px-2 1000px:text-[18px] 800px:text-[16px] text-[18px] hidden 800px:block`}
                  // onClick={() => setDropDown(!dropDown)}
                  onMouseEnter={() => setDropDown(!dropDown)}
                  onMouseLeave={() => setDropDown(!dropDown)}
                >
                  {i.title}
                  {dropDown ? (
                    <BiChevronUp
                      size={20}
                      className="absolute 1000px:right-0 1000px:top-3 800px:right-1 800px:top-2 right-0 top-3 cursor-pointer"
                    />
                  ) : (
                    <BiChevronDown
                      size={20}
                      className="absolute 1000px:right-0 1000px:top-3 800px:right-1 800px:top-2 right-0 top-3 cursor-pointer"
                    />
                  )}

                  {dropDown ? (
                    <div className="pb-4 w-[200px] bg-white absolute mt-2 -ml-2 z-30 rounded-b-md shadow-sm">
                      <Link
                        to="/gallery"
                        className={`flex hover:bg-slate-50`}
                        onClick={() => setDropDown(!dropDown)}
                      >
                        <h3
                          className={`${
                            active === 6
                              ? "font-Poppins font-semibold"
                              : "font-Poppins font-[400]"
                          } cursor-pointer select-none text-[#3C3C3C] 1000px:text-[18px] 800px:text-[16px] text-[18px] px-6 py-3`}
                        >
                          Gallery
                        </h3>
                      </Link>
                      <Link
                        to="/blog"
                        className={`flex hover:bg-slate-50`}
                        onClick={() => setDropDown(!dropDown)}
                      >
                        <h3
                          className={`${
                            active === 7
                              ? "font-Poppins font-semibold"
                              : "font-Poppins font-[400]"
                          } cursor-pointer select-none text-[#3C3C3C] 1000px:text-[18px] 800px:text-[16px] text-[18px] px-6 py-3`}
                        >
                          Blog
                        </h3>
                      </Link>
                      <Link
                        to="/press"
                        className={`flex hover:bg-slate-50`}
                        onClick={() => setDropDown(!dropDown)}
                      >
                        <h3
                          className={`${
                            active === 8
                              ? "font-Poppins font-semibold"
                              : "font-Poppins font-[400]"
                          } cursor-pointer select-none text-[#3C3C3C] 1000px:text-[18px] 800px:text-[16px] text-[18px] px-6 py-3`}
                        >
                          Press
                        </h3>
                      </Link>
                    </div>
                  ) : null}
                </div>
                <Link
                  to="/gallery"
                  className={`${
                    active === 6
                      ? "font-Poppins font-semibold"
                      : "font-Poppins font-[400]"
                  } text-[#3C3C3C] 1000px:text-[18px] 800px:text-[16px] text-[18px] font-normal 1000px:px-6 800px:px-2 ml-[10px] 800px:ml-0 cursor-pointer navitems relative whitespace-nowrap block 800px:hidden pb-3`}
                >
                  Gallery
                </Link>
                <Link
                  to="/blog"
                  className={`${
                    active === 7
                      ? "font-Poppins font-semibold"
                      : "font-Poppins font-[400]"
                  } text-[#3C3C3C] 1000px:text-[18px] 800px:text-[16px] text-[18px] font-normal 1000px:px-6 800px:px-2 ml-[10px] 800px:ml-0 cursor-pointer navitems relative whitespace-nowrap block 800px:hidden py-3`}
                >
                  Blog
                </Link>
                <Link
                  to="/press"
                  className={`${
                    active === 8
                      ? "font-Poppins font-semibold"
                      : "font-Poppins font-[400]"
                  } text-[#3C3C3C] 1000px:text-[18px] 800px:text-[16px] text-[18px] font-normal 1000px:px-6 800px:px-2 ml-[10px] 800px:ml-0 cursor-pointer navitems relative whitespace-nowrap block 800px:hidden py-3`}
                >
                  Press
                </Link>
              </>
            ) : (
              <Link
                to={i.url}
                className={`${
                  active === index + 1
                    ? "font-Poppins font-semibold"
                    : "font-Poppins font-[400]"
                } text-[#3C3C3C] 1000px:text-[18px] 800px:text-[16px] text-[18px] font-normal 1000px:px-6 800px:px-2 ml-[10px] 800px:ml-0 cursor-pointer navitems relative whitespace-nowrap`}
              >
                {i.title}
              </Link>
            )}
          </div>
        ))}
    </div>
  );
};

export default Navbar;
