import React, { useEffect } from 'react'
import styles from '../styles/styles'
import Header from '../components/Layout/Header'
import Hero from '../components/Layout/Hero'
import Footer from '../components/Layout/Footer'
import FranchiseForm from '../components/Franchise/FranchiseForm.jsx'

const FranchiseInfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header activeHeading={4} />
        <div className="h-[11vh]"></div>
        <Hero page={'franchise'} />
        <FranchiseForm />
        <Footer />
    </div>
  )
}

export default FranchiseInfoPage