import React, { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  getAllOrdersOfAdmin,
  getAllOrdersOfUser,
} from "../../redux/actions/order";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import { TiTick, TiTimes } from "react-icons/ti";
import Ratings from "../ProductDetails/Ratings";
import { toast } from "react-toastify";

const AdminProductReviewList = () => {
  const [reviews, setReviews] = useState("");
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);

  const getReviews = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/review/get-all-reviews`, {
        withCredentials: true,
      });
      setReviews(data?.reviews);
    } catch (error) {
      if (error) {
        setError("Unauthorized");
      }
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredReviews = reviews?.filter((review) =>
      review?.userName?.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(filteredReviews);
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              PRODUCT REVIEW
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] mb-[20px]">
            <div className="flex">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-[218px] h-[38px] bg-[#fff] flex items-center px-3 rounded-l-[8px]"
                placeholder="search with user name"
              ></input>
              <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
                <LuSearch size={25} className="text-white" />
              </div>
            </div>
            {/* <div className="flex items-center gap-4">
              <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                Download All Reviews
              </button>
            </div> */}
          </div>
          <div>
            {error ? <h1>{error}</h1> : <ReviewList searchData={searchData} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const ReviewList = ({ searchData }) => {
  const [pageSize, setPageSize] = useState(6);

  const calcRows = () => {
    if (window.innerHeight >= 1250) {
      setPageSize(10);
    } else if (window.innerHeight > 1150) {
      setPageSize(9);
    } else if (window.innerHeight > 1050) {
      setPageSize(8);
    } else if (window.innerHeight > 950) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(6);
    } else if (window.innerHeight > 750) {
      setPageSize(5);
    } else if (window.innerHeight > 660) {
      setPageSize(4);
    } else if (window.innerHeight > 600) {
      setPageSize(3);
    } else if (window.innerHeight > 550) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  useEffect(() => {
    calcRows();
  }, []);

  const [reviews, setReviews] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getReviews = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/review/get-all-reviews`, {
        withCredentials: true,
      });
      setReviews(data?.reviews);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  const handleStatusUpdate = async (id) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    await axios
      .put(`${process.env.REACT_APP_SERVER}/review/block-review/${id}`, config, {
        withCredentials: true,
      })
      .then((res) => {
        getReviews();
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const columns = [
    // { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    {
      field: "user",
      headerName: "Customer Name",
      minWidth: 130,
      flex: 0.8,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Product Name",
      minWidth: 130,
      flex: 0.8,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "rating",
      flex: 1,
      minWidth: 150,
      headerName: "Rating",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Link to={`/admin-order/${params.id}`}>
              <Button>
                {params.value === 'blocked' ? (
                  <TiTimes size={30} className="text-[#D11F1F]" />
                ) : (
                  <TiTick size={30} className="text-[green]" />
                )}
              </Button>
            </Link> */}
            <Ratings rating={params?.value} />
          </>
        );
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      minWidth: 130,
      flex: 0.7,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "published",
      flex: 1,
      minWidth: 150,
      headerName: "Status",
      type: "number",
      sortable: false,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {/* <Link to={`/admin-order/${params.id}`}> */}
            {/* <Button>
                {params.value === 'blocked' ? (
                  <TiTimes size={30} className="text-[#D11F1F]" />
                ) : (
                  <TiTick size={30} className="text-[green]" />
                )}
              </Button> */}

            <div className="flex gap-1 px-1 py-0.5 shadow-sm border-1 border-[#3C3C3C] rounded-[16px] bg-[#d0d5dd]">
              {params.value === false ? (
                <div
                  className={`py-0.5 px-1.5 text-[#40890F] bg-green-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                >
                  {params.value}UnBlocked
                </div>
              ) : (
                <div
                  className={`py-0.5 px-1.5 text-[#D11F1F] bg-red-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                >
                  {params.value}Blocked
                </div>
              )}
              {params.value === false ? (
                <div
                  className={`py-0.5 px-1.5  rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                  onClick={() => handleStatusUpdate(params.id)}
                >
                  block
                </div>
              ) : (
                <div
                  className={`py-0.5 px-1.5 rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                  onClick={() => handleStatusUpdate(params.id)}
                >
                  unblock
                </div>
              )}
            </div>
            {/* </Link> */}
          </>
        );
      },
    },
  ];

  const row = [];

  if (searchData) {
    searchData &&
      searchData.forEach((item) => {
        row.push({
          id: item._id,
          user: item.userName,
          title: item.productName,
          rating: item.rating,
          comment: item.comment,
          published: item.blocked,
        });
      });
  } else {
    reviews &&
      reviews.forEach((item) => {
        row.push({
          id: item._id,
          user: item.userName,
          title: item.productName,
          rating: item.rating,
          comment: item.comment,
          published: item.blocked,
        });
      });
  }

  const getRowHeight = (params) => {
    return 70;
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0
      ? "products-grid-row-even"
      : "products-grid-row-odd";
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-end border-b">
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : reviews?.length === 0 || searchData?.length === 0 ? (
        <h1>No data found!</h1>
      ) : (
        <div className={``}>
          <DataGrid
            rows={row}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: pageSize, page: 0 },
              },
            }}
            getRowHeight={getRowHeight}
            getRowClassName={getRowClassName}
            disableSelectionOnClick
            isRowSelectable={() => false}
          />
        </div>
      )}
    </>
  );
};

export default AdminProductReviewList;
