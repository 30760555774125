import React, { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  getAllOrdersOfAdmin,
  getAllOrdersOfUser,
} from "../../redux/actions/order";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const AdminOrdersList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { orders, adminOrders, error, isLoading } = useSelector(
    (state) => state.order
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);

  useEffect(() => {
    // dispatch(getAllOrdersOfUser(user?._id));
    dispatch(getAllOrdersOfAdmin());
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredOrders = adminOrders?.filter((order) =>
      order._id.includes(term)
    );
    setSearchData(filteredOrders);
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              ORDERS LIST
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] mb-[20px]">
            <div className="flex">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-[218px] h-[38px] bg-[#fff] flex items-center px-3 rounded-l-[8px]"
                placeholder="search with order Id"
              ></input>
              <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
                <LuSearch size={25} className="text-white" />
              </div>
            </div>
            {/* <div className="flex items-center gap-4">
              <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                Download All Orders
              </button>
            </div> */}
          </div>
          <div>
            {error ? (
              <h1>{error}</h1>
            ) : (
              <OrdersList
                adminOrders={adminOrders}
                searchData={searchData}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const OrdersList = ({ adminOrders, searchData, isLoading }) => {
  const [pageSize, setPageSize] = useState(6);

  const calcRows = () => {
    if (window.innerHeight >= 1250) {
      setPageSize(10);
    } else if (window.innerHeight > 1150) {
      setPageSize(9);
    } else if (window.innerHeight > 1050) {
      setPageSize(8);
    } else if (window.innerHeight > 950) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(6);
    } else if (window.innerHeight > 750) {
      setPageSize(5);
    } else if (window.innerHeight > 660) {
      setPageSize(4);
    } else if (window.innerHeight > 600) {
      setPageSize(3);
    } else if (window.innerHeight > 550) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  useEffect(() => {
    calcRows();
  }, []);

  const columns = [
    {
      field: "sno",
      flex: 0.5,
      minWidth: 50,
      headerName: "S.No",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <h1>{params.value}</h1>
          </>
        );
      },
    },
    {
      field: "image",
      flex: 0.8,
      minWidth: 130,
      headerName: "Order Image",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="h-[69px] w-[100px] flex items-center">
              <img
                src={`${process.env.REACT_APP_BUCKET_URL}${params.value}`}
                alt=""
                className="object-contain"
              />
            </div>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 150,
      flex: 0.7,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      flex: 0.7,
      // minWidth: 150,
      headerName: "Date",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <h1>{params.value}</h1>
          </>
        );
      },
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      // type: "number",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.value === 'Delivered' ? (
              <div className="bg-[#40890F33] px-2 py-1 rounded-[8px]">
                <h1 className="text-[#40890F]">{params.value}</h1>
              </div>
            ) : (
              <div className="bg-[#D11F1F33] px-2 py-1 rounded-[8px] truncate">
                <h1 className="text-[#D11F1F]">{params.value}</h1>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "total",
      headerName: "Amount",
      type: "number",
      minWidth: 130,
      flex: 0.8,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: " ",
      flex: 0.5,
      // minWidth: 150,
      headerName: "Edit",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      type: "number",
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Link to={`/admin-order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  if (searchData) {
    searchData &&
      searchData.forEach((item, index) => {
        row.push({
          id: item._id,
          sno: index + 1,
          date: item.createdAt.slice(0, 10),
          image: item.cart[0].images[0],
          paymentMethod: item.paymentInfo.type,
          total: "₹ " + item.totalPrice,
          status: item.status,
        });
      });
  } else {
    adminOrders &&
      adminOrders.forEach((item, index) => {
        row.push({
          id: item._id,
          sno: index + 1,
          date: item.createdAt.slice(0, 10),
          image: item.cart[0].images[0],
          paymentMethod: item.paymentInfo.type,
          total: "₹ " + item.totalPrice,
          status: item.status,
        });
      });
  }

  const getRowHeight = (params) => {
    return 70;
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0
      ? "products-grid-row-even"
      : "products-grid-row-odd";
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-end border-b">
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : adminOrders?.length === 0 || searchData?.length === 0 ? (
        <h1>No data found!</h1>
      ) : (
        <div className={``}>
          <DataGrid
            rows={row}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: pageSize, page: 0 },
              },
            }}
            getRowHeight={getRowHeight}
            getRowClassName={getRowClassName}
            disableSelectionOnClick
            isRowSelectable={() => false}
            slots={{ toolbar: CustomToolbar }}
          />
        </div>
      )}
    </>
  );
};

export default AdminOrdersList;
