import React from 'react'
import styles from '../../../styles/styles';
import { Link } from 'react-router-dom';

const Franchise = () => {
  return (
    <div className="w-full bg-white py-[50px]">
      <div className={`${styles.container}`}>
        <div className="flex-col mx-5">
          <div className="w-full flex justify-center mb-5">
            <h2 className="font-Poppins font-[500] 800px:text-[40px] text-[30px]">
              Open a Franchise With Us
            </h2>
          </div>
          <div className="w-full flex flex-col 800px:flex-row mb-10">
            <div className="flex flex-col items-center m-5">
              <h3 className="mb-5 font-Poppins font-[500] 800px:text-[22px] text-[20px]">
                Lorem Ipsum
              </h3>
              <p className="font-Poppins font-[400] text-[14px] text-justify">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Necessitatibus odit error perferendis doloribus nisi porro
                tempore velit. Vel iure eaque ex molestias architecto, nihil
                facere iste, quos blanditiis excepturi sit.
              </p>
            </div>
            <div className="flex flex-col items-center m-5">
              <h3 className="mb-5 font-Poppins font-[500] 800px:text-[22px] text-[20px]">
                Lorem Ipsum
              </h3>
              <p className="font-Poppins font-[400] text-[14px] text-justify">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Necessitatibus odit error perferendis doloribus nisi porro
                tempore velit. Vel iure eaque ex molestias architecto, nihil
                facere iste, quos blanditiis excepturi sit.
              </p>
            </div>
            <div className="flex flex-col items-center m-5">
              <h3 className="mb-5 font-Poppins font-[500] 800px:text-[22px] text-[20px]">
                Lorem Ipsum
              </h3>
              <p className="font-Poppins font-[400] text-[14px] text-justify">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Necessitatibus odit error perferendis doloribus nisi porro
                tempore velit. Vel iure eaque ex molestias architecto, nihil
                facere iste, quos blanditiis excepturi sit.
              </p>
            </div>
          </div>
          <div className='w-full flex justify-center'>
            <Link to="/franchise-info">
              <button className={`${styles.button1}`}>Know more</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Franchise
