import React from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminOrderDetails from '../../components/Admin/AdminOrderDetails.jsx'

const AdminOrderDetailsPage = () => {
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={3} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminOrderDetails />
        </div>
    </div>
  )
}

export default AdminOrderDetailsPage  