import React from 'react'
import styles from '../../styles/styles'

const ContactInfo = () => {
  return (
    <div className={`${styles.container}`}>
        <div className="flex flex-col gap-2">
            <h3 className="font-Poppins font-[500] 400px:text-[40px] text-[30px]">Get in touch</h3>
            <p className="font-Poppins font-[400] 400px:text-[18px] text-[15px]">you can call us in working time or visit our office. All mails will get the response within 24 hours. Love to hear from you!</p>
        </div>
        <div className="flex 800px:flex-row flex-col 800px:gap-0 gap-3 justify-between mt-14">
            <div className="flex flex-col gap-1">
                <h2 className="font-Poppins font-[500] 400px:text-[30px] text-[24px]">Address</h2>
                <p className="font-Poppins font-[400] 400px:text-[20px] text-[17px]">THE CHAI WALAH <br /> #7, Vasanthapura Main Road, <br /> Konanakunte Cross Road, <br /> Bangalore 560062 </p>
            </div>
            <div className="flex flex-col gap-1">
                <h2 className="font-Poppins font-[500] 400px:text-[30px] text-[24px]">Contact</h2>
                <p className="font-Poppins font-[400] 400px:text-[20px] text-[17px]">+91 9606464166 <br /> hello@thechaiwalah.com </p>
            </div>
            <div className="800px:w-[50%]">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250420.59813175458!2d75.52482604980467!3d11.274502863733593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65d0dd472fb0b%3A0x8bf1a5444e47320c!2sCHAIWALAH%20Kozhikode!5e0!3m2!1sen!2sin!4v1694514772757!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                />
                </div>
            </div>
    </div>
  )
}

export default ContactInfo