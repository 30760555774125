import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Form, Formik } from "formik";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
  AiOutlineStar,
} from "react-icons/ai";
import styles from "../../styles/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Country, State } from "country-state-city";
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineTrackChanges } from "react-icons/md";
import { toast } from "react-toastify";
import {
  deleteUserAddress,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import {
  getAllOrdersOfUser,
  getAllOrdersOfAdmin,
} from "../../redux/actions/order";
import {
  addressFormSchema,
  profileFormSchema,
} from "../../schema/valiationSchema";
import { BiError } from "react-icons/bi";

const ProfileContent = ({ active }) => {
  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.user);

  // useEffect(() => {
  //   if (error) {
  //     toast.error(error);
  //   }
  // }, [error]);

  const handleSubmit = async (e, i) => {
    dispatch(updateUserInformation(e.name, e.email, e.phoneNumber, e.password))
    i.resetForm();
  };

  return (
    <div className="w-full max-w-[80%]">
      {/* profile */}
      {active === 1 && (
        <>
          {/* <div className="flex justify-center w-full">
            <div className="relative">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${user?.avatar}`}
                className="h-[150px] w-[150px] rounded-full object-cover border-[3px] border-[#3ad132]"
                alt=""
              />
              <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                <AiOutlineCamera />
              </div>
            </div>
          </div> 
          <br />
          <br /> */}
          <div className="w-full px-5">
            <Formik
              initialValues={{
                name: user && user.name,
                email: user && user.email,
                phoneNumber: user && user.phoneNumber,
                password: "",
              }}
              validationSchema={profileFormSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange }) => (
                <Form>
                  <div className="w-full 800px:flex block pb-3">
                    <div className="w-[100%] 800px:w-[50%]">
                      <label className="block pb-2 text-[18px] font-normal font-Poppins">
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0 px-2`}
                        value={values.name}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="name">
                        {(msg) => (
                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 w-max self-start">
                            * {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="w-[100%] 800px:w-[50%]">
                      <label className="block pb-2 text-[18px] font-normal font-Poppins">
                        Email Address
                      </label>
                      <input
                      disabled
                        type="text"
                        name="email"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0 px-2`}
                        value={values.email}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 w-max self-start">
                            * {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="w-full 800px:flex block pb-3">
                    <div className="w-[100%] 800px:w-[50%]">
                      <label className="block pb-2 text-[18px] font-normal font-Poppins">
                        Phone Number
                      </label>
                      <input
                        type="number"
                        name="phoneNumber"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0 px-2`}
                        value={values.phoneNumber}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="phoneNumber">
                        {(msg) => (
                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 w-max self-start">
                            * {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="w-[100%] 800px:w-[50%]">
                      <label className="block pb-2 text-[18px] font-normal font-Poppins">
                        Enter your password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className={`${styles.input} !w-[95%] mb-4 800px:mb-0 px-2`}
                        value={values.password}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="password">
                        {(msg) => (
                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 w-max self-start">
                            * {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="800px:w-[250px] w-[150px] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer"
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}

      {/* order */}
      {active === 2 && (
        <div>
          <AllOrders />
        </div>
      )}

      {/* refund */}
      {active === 3 && (
        <div>
          <AllRefundOrders />
        </div>
      )}

      {/* track order */}
      {active === 5 && (
        <div>
          <TrackOrder />
        </div>
      )}

      {/* payment method */}
      {active === 6 && (
        <div>
          <ChangePassword />
        </div>
      )}

      {/* address */}
      {active === 7 && (
        <div>
          <Address />
        </div>
      )}
    </div>
  );
};

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const [orderDetails, setOrderDetails] = useState(null);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(6);

  const calcRows = () => {
    if (window.innerHeight >= 930) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(5);
    } else if (window.innerHeight > 750) {
      setPageSize(4);
    } else if (window.innerHeight > 680) {
      setPageSize(3);
    } else if (window.innerHeight > 580) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  useEffect(() => {
    calcRows();
  }, []);

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user?._id));
  }, []);

  const eligibleOrders =
    orders && orders.filter((item) => item.status === "Processing refund");

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      // cellClassName: (params) => {
      //   return params.getValue(params.id, "status") === "Delivered"
      //     ? "greenColor"
      //     : "redColor";
      // },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Link to={`/order/${params.id}`}> */}
            <Button onClick={() => openOrderDetails(params.id)}>
              <AiOutlineArrowRight size={20} />
            </Button>
            {/* </Link> */}
          </>
        );
      },
    },
  ];

  const row = [];

  eligibleOrders &&
    eligibleOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "₹ " + item.totalPrice,
        status: item.status,
      });
    });

  const openOrderDetails = (id) => {
    setOrderDetails(id);
  };
  return (
    <div className="pl-8 pt-1">
      {!orderDetails ? (
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: { pageSize: pageSize, page: 0 },
            },
          }}
          autoHeight
          disableSelectionOnClick
        />
      ) : (
        <OrderDetails id={orderDetails} setOrderDetails={setOrderDetails} />
      )}
    </div>
  );
};

const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const [orderDetails, setOrderDetails] = useState(null);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(6);

  const calcRows = () => {
    if (window.innerHeight >= 930) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(5);
    } else if (window.innerHeight > 750) {
      setPageSize(4);
    } else if (window.innerHeight > 680) {
      setPageSize(3);
    } else if (window.innerHeight > 580) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  useEffect(() => {
    calcRows();
  }, []);

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user?._id));
  }, []);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      // cellClassName: (params) => {
      //   return params.getValue(params.id, "status") === "Delivered"
      //     ? "greenColor"
      //     : "redColor";
      // },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Link to={`/order/${params.id}`}> */}
            <Button onClick={() => openOrderDetails(params.id)}>
              <AiOutlineArrowRight size={20} />
            </Button>
            {/* </Link> */}
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "₹ " + item.totalPrice,
        status: item.status,
      });
    });

  const openOrderDetails = (id) => {
    setOrderDetails(id);
  };

  return (
    <div className="800px:pl-8 pl-3 pt-1">
      {!orderDetails ? (
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: { pageSize: pageSize, page: 0 },
            },
          }}
          autoHeight
          disableSelectionOnClick
        />
      ) : (
        <OrderDetails id={orderDetails} setOrderDetails={setOrderDetails} />
      )}
    </div>
  );
};

const OrderDetails = ({ id, setOrderDetails }) => {
  const navigate = useNavigate();
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //   const [status, setStatus] = useState("");
  // const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const data = orders && orders.find((item) => item._id === id);

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const reviewHandler = async (e) => {
    if (comment === "") {
      toast.error("Comment is required");
    } else {
      await axios
        .put(
          `${process.env.REACT_APP_SERVER}/review/create-new-review`,
          {
            userId: user._id,
            userName: user.name,
            productName: selectedItem.name,
            rating,
            comment,
            productId: selectedItem?._id,
            orderId: id,
          },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getAllOrdersOfUser(user._id));
          setComment("");
          setRating(null);
          setOpen(false);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const refundHandler = async () => {
    await axios
      .put(`${process.env.REACT_APP_SERVER}/order/order-refund/${id}`, {
        status: "Processing refund",
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="w-full h-full flex flex-col">
      {/* <div className="flex justify-between">
          <div
          onClick={() => navigate('/profile')}
            className={`${styles.button3} !rounded-md`}
          >
            <span className="text-[#fff] font-[Montserrat] font-[600] text-[20px]">Back</span>
          </div>
        </div> */}
      <div className="flex justify-between mt-[20px] w-full flex-1">
        <div className="flex 700px:flex-row flex-col gap-6 700px:gap-0 h-full w-full">
          <div className="flex-1 flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              Order Number{" "}
              <span className="font-[Montserrat] font-[500] text-[20px] text-[#D11F1F]">
                #{data?._id?.slice(0, 8)}
              </span>
            </h2>
            <table className="mt-[20px]">
              <th className="flex justify-between border-[#3C3C3C80] border-t border-b py-2">
                <td className="font-[Montserrat] font-[500] text-[18px] w-[50%] text-start">
                  Items
                </td>
                {/* <td className="font-[Montserrat] font-[500] text-[18px] w-[20%] text-start">
                    Quantity
                  </td>
                  <td className="font-[Montserrat] font-[500] text-[18px] w-[20%] text-start">
                    Amount
                  </td>
                  <td className="w-[10%]"></td> */}
              </th>
              {data &&
                data?.cart.map((item, index) => (
                  <tr className="flex justify-between items-center border-[#3C3C3C80] border-b py-2 relative">
                    <td className="font-[Montserrat] font-[500] text-[18px] flex gap-2 items-center w-[50%]">
                      <img
                        src={`${process.env.REACT_APP_BUCKET_URL}${item.images[0]}`}
                        alt=""
                        className="object-contain h-[45px] w-[65px]"
                      />
                      <h3 className="font-[Montserrat] font-[500] 500px:text-[16px] text-[14px] whitespace-nowrap truncate">
                        {item.name}
                      </h3>
                    </td>
                    <td className="font-[Montserrat] font-[500] 500px:text-[16px] text-[14px] w-[25%] 500px:text-left text-right">
                      {item.qty} <span className="text-[#3C3C3C80]">Qty</span>
                    </td>
                    <td className="font-[Montserrat] font-[500] 500px:text-[16px] text-[14px] w-[25%] 500px:text-left text-right">
                      ₹{item.price * item.qty}
                    </td>

                    {!item.isReviewed && data?.status === "Delivered" ? (
                      <td
                        className={`bg-blue-100 px-2 rounded-md py-1 text-[#000] font-[Montserrat] font-[500] text-[16px] w-max cursor-pointer absolute -right-10`}
                        onClick={() => setOpen(true) || setSelectedItem(item)}
                      >
                        review
                      </td>
                    ) : null}
                  </tr>
                ))}
              <tr className="flex flex-col gap-2 border-[#3C3C3C80] border-b py-2">
                <tr className="flex justify-between">
                  <h3 className="font-[Montserrat] font-[500] text-[18px] w-[75%]">
                    Subtotal :
                  </h3>
                  <h3 className="font-[Montserrat] font-[500] text-[18px] w-[25%]">
                    ₹{data?.totalPrice}
                  </h3>
                </tr>
                <tr className="flex justify-between">
                  <h3 className="font-[Montserrat] font-[500] text-[18px] w-[75%]">
                    Shipping :
                  </h3>
                  <h3 className="font-[Montserrat] font-[500] text-[18px] w-[25%]">
                    ₹{data?.shippingCost}
                  </h3>
                </tr>
              </tr>
              <tr className="flex flex-col gap-2 border-[#3C3C3C80] border-b py-2">
                <tr className="flex justify-between">
                  <h3 className="font-[Montserrat] font-[600] text-[20px] w-[75%]">
                    Total Price :
                  </h3>
                  <h3 className="font-[Montserrat] font-[600] text-[20px] w-[25%]">
                    ₹{data?.totalPrice + data?.shippingCost}
                  </h3>
                </tr>
              </tr>
            </table>
          </div>
          <div className="flex-1 flex flex-col gap-3 700px:ml-20 ml-0">
            <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
              <h3 className="font-[Montserrat] font-[600] text-[18px]">
                Summery
              </h3>
              <ul className="list-disc px-5">
                <li className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                  Order ID : {data?._id?.slice(0, 8)}
                </li>
                <li className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                  Order Date : {data?.createdAt?.slice(0, 10)}
                </li>
                <li className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                  Order Total : ₹{data?.totalPrice + data?.shippingCost}
                </li>
              </ul>
            </div>
            <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
              <h3 className="font-[Montserrat] font-[600] text-[18px]">
                Shipping Address
              </h3>
              <h4 className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                {data?.shippingAddress?.country}, {data?.shippingAddress?.state}
                , {data?.shippingAddress?.district}, <br />
                {data?.shippingAddress?.city}, {data?.shippingAddress?.pinCode}{" "}
                <br />
                Contact No. {data?.shippingAddress?.phoneNumber}
              </h4>
            </div>
            <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
              <h3 className="font-[Montserrat] font-[600] text-[18px]">
                Payment Method
              </h3>
              <h4 className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                {data?.paymentInfo?.type} <br />
                {data?.paymentInfo?.status ? data?.paymentInfo?.status : null}
              </h4>
              {data?.status === "Delivered" && (
                <div
                  className={`bg-[#FCE1E6] rounded-[4px] text-[#E94560] font-[600] text-[18px] w-max h-min py-1 px-2 cursor-pointer`}
                  onClick={refundHandler}
                >
                  Request Refund
                </div>
              )}
            </div>
            <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
              <h3 className="font-[Montserrat] font-[600] text-[18px]">
                Track Order
              </h3>
              <h4 className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                Current status: {data?.status}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setOrderDetails(null)}
        className="mt-3 flex items-center gap-1 hover:bg-blue-100 w-max py-1 px-2 rounded-md text-[18px] font-normal font-Poppins self-end cursor-pointer"
      >
        <AiOutlineArrowLeft size={20} className="text-[#D11F1F]" />
        Order List
      </div>

      {/* review popup */}
      {open && (
        <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
          <div className="w-[50%] h-min bg-[#fff] shadow rounded-md p-3 pb-10">
            <div className="w-full flex justify-end p-3">
              <RxCross1
                size={30}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <h2 className="text-[30px] font-[500] font-Poppins text-center">
              Give a Review
            </h2>
            <div className="w-full flex items-center">
              <img
                src={`${process.env.REACT_APP_BUCKET_URL}${selectedItem?.images[0]}`}
                alt=""
                className="w-[80px] h-[80px] object-contain"
              />
              <h4 className="pl-3 text-[20px]">{selectedItem?.name}</h4>
            </div>

            {/* ratings */}
            <h5 className="pl-3 text-[20px] font-[500]">
              Give a Rating <span className="text-red-500">*</span>
            </h5>
            <div className="flex w-full ml-2 pt-1">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            <div className="w-full ml-3">
              <label className="block text-[20px] font-[500]">
                Write a comment
                {/* <span className="ml-1 font-[400] text-[16px] text-[#00000052]">
                  (optional)
                </span> */}
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write here your review"
                className="mt-2 w-[95%] border p-2 outline-none"
              ></textarea>
            </div>
            <div
              className={`bg-black w-max py-1 px-2 rounded-md text-white text-[20px] ml-3 cursor-pointer`}
              onClick={rating > 1 ? reviewHandler : null}
            >
              Submit
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// const AllOrders = () => {
//   const { user } = useSelector((state) => state.user);
//   const { orders } = useSelector((state) => state.order);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllOrdersOfUser(user?._id));
//   }, []);

//   const columns = [
//     { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

//     {
//       field: "status",
//       headerName: "Status",
//       minWidth: 130,
//       flex: 0.7,
//       // cellClassName: (params) => {
//       //   return params.getValue(params.id, "status") === "Delivered"
//       //     ? "greenColor"
//       //     : "redColor";
//       // },
//     },
//     {
//       field: "itemsQty",
//       headerName: "Items Qty",
//       type: "number",
//       minWidth: 130,
//       flex: 0.7,
//     },

//     {
//       field: "total",
//       headerName: "Total",
//       type: "number",
//       minWidth: 130,
//       flex: 0.8,
//     },

//     {
//       field: " ",
//       flex: 1,
//       minWidth: 150,
//       headerName: "",
//       type: "number",
//       sortable: false,
//       renderCell: (params) => {
//         return (
//           <>
//             <Link to={`/order/${params.id}`}>
//               <Button>
//                 <AiOutlineArrowRight size={20} />
//               </Button>
//             </Link>
//           </>
//         );
//       },
//     },
//   ];

//   const row = [];

//   orders &&
//     orders.forEach((item) => {
//       row.push({
//         id: item._id,
//         itemsQty: item.cart.length,
//         total: "₹ " + item.totalPrice,
//         status: item.status,
//       });
//     });

//   return (
//     <div className="800px:pl-8 pl-3 pt-1">
//       <DataGrid
//         rows={row}
//         columns={columns}
//         pageSize={10}
//         autoHeight
//         disableSelectionOnClick
//       />
//     </div>
//   );
// };

const TrackOrder = () => {
  const [pageSize, setPageSize] = useState(6);

  const calcRows = () => {
    if (window.innerHeight >= 930) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(5);
    } else if (window.innerHeight > 750) {
      setPageSize(4);
    } else if (window.innerHeight > 680) {
      setPageSize(3);
    } else if (window.innerHeight > 580) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  useEffect(() => {
    calcRows();
  }, []);

  const orders = [
    {
      _id: "123",
      orderItems: [
        {
          name: "Iphone 14 pro max",
        },
      ],
      totalPrice: 699,
      orderStatus: "Processing",
    },
    {
      _id: "123",
      orderItems: [
        {
          name: "Iphone 14 pro max",
        },
      ],
      totalPrice: 699,
      orderStatus: "Processing",
    },
  ];

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      // cellClassName: (params) => {
      //   return params.getValue(params.id, "status") === "Delivered"
      //     ? "greenColor"
      //     : "redColor";
      // },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 130,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Link to={`/order/${params.id}`}> */}
            <Button>
              <MdOutlineTrackChanges size={20} />
            </Button>
            {/* </Link> */}
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.orderItems.length,
        total: "₹ " + item.totalPrice,
        status: item.orderStatus,
      });
    });

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        initialState={{
          pagination: {
            paginationModel: { pageSize: pageSize, page: 0 },
          },
        }}
        disableRowSelectionOnClick
        autoHeight
      />
    </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${process.env.REACT_APP_SERVER}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
        Change Password
      </h1>
      <div className="w-full">
        <form
          aria-required
          onSubmit={passwordChangeHandler}
          className="flex flex-col items-center"
        >
          <div className=" w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">Enter your old password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your new password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your confirm password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              className={`w-[95%] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
              required
              value="Update"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const Address = () => {
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    {
      name: "Default",
    },
    {
      name: "Home",
    },
    {
      name: "Office",
    },
  ];

  const handleSubmit = async (e, i) => {
    dispatch(
      updatUserAddress(
        e.country,
        e.state,
        e.district,
        e.city,
        e.pincode,
        e.addressType
      )
    );
    setOpen(false);
    i.resetForm();
  };

  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };
  return (
    <div className="w-full px-5">
      {open && (
        <div className="fixed w-full h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center">
          <div className="w-[35%] h-[80vh] bg-white rounded shadow relative overflow-y-scroll mt-10 disableScrollBar">
            <div className="w-full flex justify-end p-3">
              <RxCross1
                size={30}
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <h1 className="text-center text-[25px] font-Poppins">
              Add New Address
            </h1>
            <div className="w-full">
              <Formik
                initialValues={{
                  country: "IN",
                  state: "KL",
                  district: "",
                  city: "",
                  pincode: "",
                  addressType: "Default",
                }}
                validationSchema={addressFormSchema}
                onSubmit={handleSubmit}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <div className="w-full block p-4">
                      <div className="w-full pb-2">
                        <label className="block pb-2">Country</label>
                        <select
                          disabled
                          name="country"
                          id=""
                          value={values.country}
                          onChange={handleChange}
                          className="w-[100%] border h-[40px] rounded-[5px]"
                        >
                          <option value="" className="block border pb-2">
                            choose your country
                          </option>
                          {Country &&
                            Country.getAllCountries().map((item) => (
                              <option
                                className="block pb-2"
                                key={item.isoCode}
                                value={item.isoCode}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">Choose your State</label>
                        <select
                          name="state"
                          id=""
                          value={values.state}
                          onChange={handleChange}
                          className="w-[100%] border h-[40px] rounded-[5px]"
                        >
                          {/* <option value="" className="block border pb-2">
                        choose your state
                      </option> */}
                          {State &&
                            State.getStatesOfCountry(values.country).map(
                              (item) => (
                                <option
                                  className="block pb-2"
                                  key={item.isoCode}
                                  value={item.isoCode}
                                >
                                  {item.name}
                                </option>
                              )
                            )}
                        </select>
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">District</label>
                        <input
                          type="address"
                          name="district"
                          className={`${styles.input}`}
                          value={values.district}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="district">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 w-max">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="w-full pb-2">
                        <label className="block pb-2">City</label>
                        <input
                          type="address"
                          name="city"
                          className={`${styles.input}`}
                          value={values.city}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="city">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 w-max">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">Pin Code</label>
                        <input
                          type="text"
                          name="pincode"
                          className={`${styles.input}`}
                          value={values.pincode}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="pincode">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 w-max">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="w-full pb-2">
                        <label className="block pb-2">Address Type</label>
                        <select
                          name="addressType"
                          id=""
                          value={values.addressType}
                          onChange={handleChange}
                          className="w-[100%] border h-[40px] rounded-[5px]"
                        >
                          {/* <option value="" className="block border pb-2">
                        Choose your Address Type
                      </option> */}
                          {addressTypeData &&
                            addressTypeData.map((item) => (
                              <option
                                className="block pb-2"
                                key={item.name}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className=" w-full pb-2">
                        <button type="submit" className={`${styles.button2} `}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
      <div className="w-full flex flex-col 800px:flex-row items-center justify-between">
        <h1 className="text-[25px] font-[600] text-[#000000ba]">
          My Addresses
        </h1>
        <div
          onClick={() => setOpen(true)}
          className={`${styles.button3} !rounded-md`}
        >
          <span className="text-[#fff]">Add new</span>
        </div>
      </div>
      {user &&
        user.addresses.map((item, index) => (
          <div
            className="w-full bg-white h-min 800px:h-[70px] rounded-[4px] flex items-center px-3 shadow justify-between pr-10 mb-5"
            key={index}
          >
            <div className="flex items-center">
              <h5 className="pl-5 font-[600]">{item.addressType}</h5>
            </div>
            <div className="pl-8 flex items-center">
              <h6 className="text-[12px] 800px:text-[unset]">
                {item.state} {item.city}
              </h6>
            </div>
            <div className="pl-8 flex items-center">
              <h6 className="text-[12px] 800px:text-[unset]">
                {user && user.phoneNumber}
              </h6>
            </div>
            <div className="min-w-[10%] flex items-center justify-between pl-8">
              <AiOutlineDelete
                size={25}
                className="cursor-pointer"
                onClick={() => handleDelete(item)}
              />
            </div>
          </div>
        ))}

      {user && user.addresses.length === 0 && (
        <h5 className="text-center pt-8 text-[18px]">
          You not have any saved address!
        </h5>
      )}
    </div>
  );
};

export default ProfileContent;
