import React, { useEffect } from 'react';
import Header from '../components/Layout/Header';
import Hero from '../components/Layout/Hero';
import SpecialChai from '../components/Route/SpecialChai/SpecialChai';
import Benefits from '../components/Route/Benefits/Benefits';
import Products from '../components/Route/Products/Products';
import PromoVideo from '../components/Route/PromoVideo/PromoVideo';
import Franchise from "../components/Route/Franchise/Franchise";
import Footer from "../components/Layout/Footer";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header activeHeading={1} />
        <div className="h-[11vh]"></div>
        <Hero page={'home'} />
        <SpecialChai />
        <Benefits />
        <Products />
        <PromoVideo />
        <Franchise />
        <Footer />
    </div>
  )
}

export default HomePage