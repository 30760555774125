import React, { useState } from 'react'
import styles from '../../../styles/styles';

const PromoVideo = () => {
    const [youtubeID] = useState("nLIssdyREi0");
  return (
    <div className="w-full bg-[#F4F3F3] py-[50px]">
      <div className={`${styles.container}`}>
        <iframe
          className="w-full h-[50vh]"
          frameborder="0"
          allowfullscreen
          title="Youtube player"
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://youtube.com/embed/${youtubeID}?autoplay=true`}
        ></iframe>
      </div>
    </div>
  );
}

export default PromoVideo
