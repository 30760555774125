import React, { useEffect } from "react";
import styles from "../../styles/styles";
import { Skeleton, Stack } from "@mui/material";
import { Link } from "react-router-dom";

function NewsDetails({ data, latest }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className={`${styles.container}`}>
        {data ? (
          <div className="flex gap-5">
            <div className="1100px:w-[70%] w-full">
              <h1 className="font-Poppins font-[400] text-[24px] 800px:text-[30px]">
                {data[0].title}
              </h1>
              <h3 className="font-[Poppins] font-[400] text-[12px] 800px:text-[16px] text-[#9A9A9A] whitespace-nowrap overflow-hidden text-ellipsis z-10 mt-3">
                {data[0].createdAt.slice(0, 10)}
              </h3>
              <img
                src={`${process.env.REACT_APP_BUCKET_URL}${data[0].images[0]}`}
                alt=""
                className="w-full h-full max-h-[40vh] 1100px:max-w-[50vw] object-cover rounded-[19px] mt-3"
              />
              <h4 className="font-[Poppins] font-[400] text-[14px] 800px:text-[18px] z-10 mt-5">
                {data[0].description}
              </h4>
            </div>
            <div className="w-[30%] 1100px:flex hidden flex-col gap-2 mt-[30vh]">
              <h1 className="font-Poppins font-[500] text-[22px] 800px:text-[28px] text-right">
                Latest
              </h1>
              <div className="w-full  bg-gray-300 flex justify-end">
                <div className="w-[25%] border border-red-500"></div>
              </div>
              {latest &&
                latest.map((i, index) => (
                    <a href={`/press/${i._id}`}>
                        <div className="flex gap-3">
                            <img src={`${process.env.REACT_APP_BUCKET_URL}${i.images[0]}`} alt="" className="w-[80px] h-[70px] object-cover" />
                            <div className="flex-1 h-[70px]">
                                <h3 className="w-full font-Poppins font-[400] text-[14px] 800px:text-[18px] line-clamp-2">{i.title}</h3>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
          </div>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "24px" }} />
            <Skeleton variant="text" sx={{ fontSize: "24px" }} />
            <Skeleton
              variant="rectangular"
              sx={{ width: "50vw", height: "40vh" }}
            />
          </Stack>
        )}
      </div>
    </div>
  );
}

export default NewsDetails;
