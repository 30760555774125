import React from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminProfileSettings from '../../components/Admin/AdminProfileSettings.jsx'

const AdminSettingsPage = () => {
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={10} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminProfileSettings />
        </div>
    </div>
  )
}

export default AdminSettingsPage    