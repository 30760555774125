import React, { useState } from "react";
import styles from "../../styles/styles";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ErrorMessage, Form, Formik } from "formik";
import {
  loginFormSchema,
  findAccFormSchema,
  verCodeFormSchema,
  adminResetPasswordFormSchema,
} from "../../schema/valiationSchema";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logoW.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loadAdmin } from "../../redux/actions/user";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [npVisible, setNpVisible] = useState(false);
  const [cpVisible, setCpVisible] = useState(false);
  const [code, setCode] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [page, setPage] = useState("login");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e, i) => {
    const newForm = new FormData();

    newForm.append("email", e.email);
    newForm.append("password", e.password);

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    await axios
      .post(
        `${process.env.REACT_APP_SERVER}/admin/login-admin`,
        newForm,
        config
      )
      .then((res) => {
        i.resetForm();
        dispatch(loadAdmin());
        navigate("/admin-dashboard");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleForgotPassword = async (e) => {
    setLoading(true);
    const newForm = new FormData();

    newForm.append("email", e.email);

    setEmail(e.email);

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    await axios
      .post(
        `${process.env.REACT_APP_SERVER}/admin/forgot-password`,
        newForm,
        config
      )
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);
        setPage("verification");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleVerification = async (e) => {
    const newForm = new FormData();

    newForm.append("code", e.code);
    newForm.append("email", email);

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    await axios
      .post(
        `${process.env.REACT_APP_SERVER}/admin/verify-code`,
        newForm,
        config
      )
      .then((res) => {
        setPage("resetPassword");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleResetPassword = async (e) => {
    const newForm = new FormData();

    newForm.append("newPassword", e.newPassword);
    newForm.append("confirmPassword", e.confirmPassword);
    newForm.append("email", email);

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    await axios
      .post(
        `${process.env.REACT_APP_SERVER}/admin/reset-password`,
        newForm,
        config
      )
      .then((res) => {
        toast.success(res.data.message);
        setPage("login");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div>
      <div
        className={`${styles.container} absolute left-0 right-0 top-[20vh] flex items-center justify-center z-20`}
      >
        <div className="w-[70vw] h-[560px] shadow-lg bg-transparent flex">
          <div className="flex-1 bg-[#3C3C3C] hidden 1000px:flex flex-col justify-center items-center ">
            <div className="w-full flex justify-center mt-[20px] absolute top-10">
              <img src={Logo} alt="" className="w-[234px] h-[63.82px]" />
            </div>
            <div className="flex flex-col items-center px-20">
              <h2 className="font-Poppins font-[600] text-[20px] text-white">
                Welcome to THE CHAI WALAH
              </h2>
              <p className="font-Poppins font-[400] text-[12px] text-white text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
          </div>
          {page === "login" ? (
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginFormSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange }) => (
                <Form className="flex-1">
                  <div className="flex gap-3 flex-col justify-center items-center h-full">
                    <h3 className="font-Poppins font-[500] text-[30px] text-black">
                      Admin Login
                    </h3>
                    <h6 className="font-Poppins font-[400] 800px:text-[18px] text-[15px] text-black text-center">
                      Please login using account detail below.
                    </h6>
                    <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] 800px:mt-10 mt-5">
                      <label
                        htmlFor="email"
                        className="w-[80px] font-Poppins font-[400] text-[16px] text-black "
                      >
                        Email
                      </label>
                      <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                        <input
                          type="email"
                          name="email"
                          autoComplete="email"
                          value={values.email}
                          onChange={handleChange}
                          className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                        />
                        <ErrorMessage name="email">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] relative">
                      <label
                        htmlFor="password"
                        className="w-[80px] font-Poppins font-[400] text-[16px] text-black"
                      >
                        Password
                      </label>
                      <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                        <input
                          type={visible ? "type" : "password"}
                          name="password"
                          autoComplete="current-password"
                          value={values.password}
                          onChange={handleChange}
                          className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                        />
                        <ErrorMessage name="password">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      {visible ? (
                        <AiOutlineEye
                          className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                          size={25}
                          onClick={() => setVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                          size={25}
                          onClick={() => setVisible(true)}
                        />
                      )}
                    </div>
                    <div className="text-start 400px:w-[380px] w-[280px] flex justify-end cursor-pointer">
                      <p onClick={() => setPage("findEmail")}>
                        Forgot password?
                      </p>
                    </div>
                    {/* <div className="flex">
                    <h4>Not have any account?</h4>
                    <Link to="/signup" className="text-black underline pl-2">
                      Sign Up
                    </Link>
                  </div> */}
                    <button type="submit" className={`${styles.button2}`}>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : page === "findEmail" ? (
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={findAccFormSchema}
              onSubmit={handleForgotPassword}
            >
              {({ values, handleChange }) => (
                <Form className="flex-1">
                  <div className="flex gap-3 flex-col justify-center items-center h-full">
                    <h6 className="font-Poppins font-[400] 800px:text-[18px] text-[15px] text-black text-center">
                      Find your account
                    </h6>
                    <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] mt-5">
                      <label
                        htmlFor="email"
                        className="w-[80px] font-Poppins font-[400] text-[16px] text-black "
                      >
                        Email
                      </label>
                      <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                        <input
                          type="email"
                          name="email"
                          autoComplete="email"
                          value={values.email}
                          onChange={handleChange}
                          className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                        />
                        <ErrorMessage name="email">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className={loading ? styles.LoadingBtn : styles.button2}
                    >
                      {!loading ? (
                        <h1>Submit</h1>
                      ) : (
                        <ThemeProvider theme={customTheme}>
                          <CircularProgress color="primary" size="1.5rem" />
                        </ThemeProvider>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : page === "verification" ? (
            <Formik
              initialValues={{
                code: "",
              }}
              validationSchema={verCodeFormSchema}
              onSubmit={handleVerification}
            >
              {({ values, handleChange }) => (
                <Form className="flex-1">
                  <div className="flex gap-3 flex-col justify-center items-center h-full">
                    <h6 className="font-Poppins font-[400] 800px:text-[18px] text-[15px] text-black text-center">
                      Enter the verification code sent to your email
                    </h6>
                    <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] mt-5">
                      <label
                        htmlFor="code"
                        className="w-[80px] font-Poppins font-[400] text-[16px] text-black "
                      >
                        Code
                      </label>
                      <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                        <input
                          type="number"
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                          className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                        />
                        <ErrorMessage name="code">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <button type="submit" className={`${styles.button2}`}>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : page === "resetPassword" ? (
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={adminResetPasswordFormSchema}
              onSubmit={handleResetPassword}
            >
              {({ values, handleChange }) => (
                <Form className="flex-1">
                  <div className="flex gap-3 flex-col justify-center items-center h-full">
                    <h6 className="font-Poppins font-[400] 800px:text-[18px] text-[15px] text-black text-center">
                      Reset Password
                    </h6>
                    <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] relative">
                      <label
                        htmlFor="newPassword"
                        className="w-[80px] font-Poppins font-[400] text-[16px] text-black"
                      >
                        New Password
                      </label>
                      <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                        <input
                          type={npVisible ? "type" : "password"}
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                        />
                        <ErrorMessage name="newPassword">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      {npVisible ? (
                        <AiOutlineEye
                          className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                          size={25}
                          onClick={() => setNpVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                          size={25}
                          onClick={() => setNpVisible(true)}
                        />
                      )}
                    </div>
                    <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] relative">
                      <label
                        htmlFor="confirmPassword"
                        className="w-[80px] font-Poppins font-[400] text-[16px] text-black"
                      >
                        Confirm Password
                      </label>
                      <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                        <input
                          type={cpVisible ? "type" : "password"}
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                        />
                        <ErrorMessage name="confirmPassword">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                              * {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      {cpVisible ? (
                        <AiOutlineEye
                          className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                          size={25}
                          onClick={() => setCpVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                          size={25}
                          onClick={() => setCpVisible(true)}
                        />
                      )}
                    </div>
                    <button type="submit" className={`${styles.button2}`}>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
