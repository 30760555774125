import axios from "axios";
import { toast } from "react-toastify";

// load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadUserRequest",
    });
    const { data } = await axios(`${process.env.REACT_APP_SERVER}/user/getuser`, {
      withCredentials: true,
    });
    localStorage.setItem("userData", JSON.stringify(data.user));
    dispatch({
      type: "LoadUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    if(error?.response?.data?.message === "Your account has been blocked by the admin")
    localStorage.removeItem("userData");
    dispatch({
      type: "LogoutUser",
    });
    dispatch({
      type: "LoadUserFail",
      payload: error?.response?.data?.message,
    });
  }
};

// load admin
export const loadAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadAdminRequest",
    });
    const { data } = await axios(`${process.env.REACT_APP_SERVER}/admin/getAdmin`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadAdminSuccess",
      payload: data.admin,
    });
  } catch (error) {
    dispatch({
      type: "LoadAdminFail",
      payload: error?.response?.data?.message,
    });
  }
};

// user update information
export const updateUserInformation =
  (name, email, phoneNumber, password) => async (dispatch) => {
    try {
      dispatch({
        type: "updateUserInfoRequest",
      });

      const { data } = await axios.put(
        `${process.env.REACT_APP_SERVER}/user/update-user-info`,
        {
          email,
          password,
          phoneNumber,
          name,
        },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      dispatch({
        type: "updateUserInfoSuccess",
        payload: data.user,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message)
      dispatch({
        type: "updateUserInfoFailed",
        payload: error?.response?.data?.message,
      });
    }
  };

// update user address
export const updatUserAddress =
  (country, state, district, city, pinCode, addressType) =>
  // (country, city, address1, address2, zipCode, addressType) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "updateUserAddressRequest",
      });

      const { data } = await axios.put(
        `${process.env.REACT_APP_SERVER}/user/update-user-addresses`,
        // {
        //   country,
        //   city,
        //   address1,
        //   address2,
        //   zipCode,
        //   addressType,
        // },
        {
          country,
          state,
          district,
          city,
          pinCode,
          addressType,
        },
        { withCredentials: true }
      );

      dispatch({
        type: "updateUserAddressSuccess",
        payload: {
          successMessage: "User address updated succesfully!",
          user: data.user,
        },
      });
    } catch (error) {
      dispatch({
        type: "updateUserAddressFailed",
        payload: error?.response?.data?.message,
      });
    }
  };

// delete user address
export const deleteUserAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteUserAddressRequest",
    });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_SERVER}/user/delete-user-address/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: "deleteUserAddressSuccess",
      payload: {
        successMessage: "User deleted successfully!",
        user: data.user,
      },
    });
  } catch (error) {
    dispatch({
      type: "deleteUserAddressFailed",
      payload: error?.response?.data?.message,
    });
  }
};

// update user status -- admin
export const updatUserStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: "updateUserStatusRequest",
    });

    const config = {headers: {"Content-Type":"multipart/form-data"}};

    const { data } = await axios.put(
      `${process.env.REACT_APP_SERVER}/user/update-user-status/${id}`, 
      config,
      { withCredentials: true }
    );
    dispatch({
      type: "updateUserStatusSuccess",
      payload: {
        successMessage: data.message,
        allUsers: data.allUsers,
      },
    });
  } catch (error) {
    dispatch({
      type: "updateUserStatusFailed",
      payload: error?.response?.data?.message,
    });
  }
};

// get all users
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersRequest",
    });

    const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/user/get-all-users`, {withCredentials: true});
    dispatch({
      type: "getAllUsersSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersFailed",
      payload: error?.response?.data?.message,
    });
  }
};
