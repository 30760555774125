import React, { useState } from "react";
import styles from "../../styles/styles";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ErrorMessage, Form, Formik } from "formik";
import { SignupFormSchema } from "../../schema/valiationSchema";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#3C3C3C",
    },
  },
});

const Signup = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e, i) => {
    // e.preventDefault()
    setLoading(true);
    const config = { headers: { "Content-Type": "application/json" } };

    const newForm = new FormData();

    newForm.append("name", e.name);
    newForm.append("email", e.email);
    newForm.append("password", e.password);

    axios
      .post(`${process.env.REACT_APP_SERVER}/user/create-user`, newForm, config)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);
        i.resetForm();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={SignupFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
        <Form>
          <div
            className={`${styles.container} absolute left-0 right-0 800px:top-[20vh] top-[11vh] flex items-center justify-center z-20`}
          >
            <div className="w-[783px] py-10 shadow-lg bg-[#FFFFFFB0] flex gap-3 flex-col justify-center items-center">
              <h3 className="font-Poppins font-[500] text-[30px] text-black">
                Signup
              </h3>
              <h6 className="font-Poppins font-[400] text-[18px] text-black">
                Register as a new user.
              </h6>
              <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] 800px:mt-10 mt-5">
                <label
                  htmlFor="name"
                  className="w-[80px] font-Poppins font-[400] text-[16px] text-black"
                >
                  Name
                </label>
                <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                  <input
                    type="text"
                    name="name"
                    autoComplete="name"
                    value={values.name}
                    onChange={handleChange}
                    className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                  />
                  <ErrorMessage name="name">
                    {(msg) => (
                      <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                        * {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
              </div>
              <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px]">
                <label
                  htmlFor="email"
                  className="w-[80px] font-Poppins font-[400] text-[16px] text-black"
                >
                  Email
                </label>
                <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                  />
                  <ErrorMessage name="email">
                    {(msg) => (
                      <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                        * {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
              </div>
              <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] relative">
                <label
                  htmlFor="password"
                  className="w-[80px] font-Poppins font-[400] text-[16px] text-black"
                >
                  Password
                </label>
                <div className="flex flex-col gap-1 400px:w-[300px] w-[260px]">
                  <input
                    type={visible ? "type" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={values.password}
                    onChange={handleChange}
                    className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
                  />
                  <ErrorMessage name="password">
                    {(msg) => (
                      <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                        * {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                {visible ? (
                  <AiOutlineEye
                    className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute 800px:right-2 800px:top-3 right-[50px] top-14 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
              <div className="flex">
                <h4>Already have an account?</h4>
                <Link to="/login" className="text-black underline pl-2">
                  Sign In
                </Link>
              </div>
              <button
              type="submit"
              className={
                loading
                  ? `${styles.LoadingBtn} !bg-transparent !border-transparent`
                  : `${styles.button2}`
              }
            >
              {!loading ? (
                <h1>Submit</h1>
              ) : (
                <ThemeProvider theme={customTheme}>
                  <CircularProgress color="primary" size="1.5rem" />
                </ThemeProvider>
              )}
            </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Signup;
