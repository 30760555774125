import React, { useEffect } from 'react'
import Header from '../components/Layout/Header'
import Hero from '../components/Layout/Hero'
import Footer from '../components/Layout/Footer'
import About from '../components/About/About'

const AboutusPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header activeHeading={2} />
        <div className="h-[11vh]"></div>
        <Hero page={"aboutus"} />
        <About />
        <Footer />
    </div>
  )
}

export default AboutusPage