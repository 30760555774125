import Product3 from "../assets/product3.png";
import Product2 from "../assets/product2.png";
import Product1 from "../assets/product1.png";
import { AiOutlineDashboard } from "react-icons/ai";
import { BsHandbag  } from "react-icons/bs";
import { CgList } from "react-icons/cg";
import { RxAvatar } from "react-icons/rx";
import { PiFolderSimpleUserBold } from "react-icons/pi";
import { FaRegHandshake } from "react-icons/fa";
import { BiLogOut, BiSolidStarHalf } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { GrGallery } from "react-icons/gr";
import { RiGalleryFill, RiNewspaperFill } from "react-icons/ri";

// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About us",
    url: "/about-us",
  },
  {
    title: "Shop",
    url: "/shop",
  },
  {
    title: "Franchise",
    url: "/franchise-info",
  },
  // {
  //   title: "Gallery",
  //   url: "/gallery",
  // },
  // {
  //   title: "Blog",
  //   url: "/blog",
  // },
  // {
  //   title: "Press",
  //   url: "/press",
  // },
  {
    title: "Contact us",
    url: "/contact",
  },
  {
    title: "Other",
    url: ""
  },
  {
    title: "Cart",
    url: "/cart",
  },
  {
    title: "Wishlist",
    url: "/wishlist",
  },
  {
    title: "Profile",
    url: "/login",
  },
];

//admin nav data
export const adminNavItems = [
  {
    title: "Dashboard",
    url: "/admin-dashboard",
    icon: <AiOutlineDashboard size={25} className='text-[#fff]' />
  },
  {
    title: "Product",
    url: "/admin-products",
    icon: <BsHandbag  size={25} className='text-[#fff]' />
  },
  {
    title: "Orders",
    url: "/admin-orders",
    icon: <CgList size={25} className='text-[#fff]' />
  },
  {
    title: "Customers",
    url: "/admin-customers",
    icon: <RxAvatar size={25} className='text-[#fff]' />
  },
  {
    title: "Sub-Admins",
    url: "/admin-subAdmin",
    icon: <PiFolderSimpleUserBold size={25} className='text-[#fff]' />
  },
  {
    title: "Enquiry",
    url: "/admin-enquiry",
    icon: <FaRegHandshake size={25} className='text-[#fff]' />
  },
  {
    title: "Product Review",
    url: "/admin-product-review",
    icon: <BiSolidStarHalf size={25} className='text-[#fff]' />
  },
  {
    title: "Gallery",
    url: "/admin-gallery",
    icon: <RiGalleryFill size={25} className='text-[#fff]' />
  },
  {
    title: "Press",
    url: "/admin-press",
    icon: <RiNewspaperFill size={25} className='text-[#fff]' />
  },
  {
    title: "Settings",
    url: "/admin-settings",
    icon: <IoSettingsOutline size={25} className='text-[#fff]' />
  },
  {
    title: "Logout",
    url: "#",
    icon: <BiLogOut size={25} className='text-[#fff]' />
  },
];

//product data
export const products = [
  {
    title: "Organic Tea",
    price: 150,
    image: Product1,
    sold: 53,
  },
  {
    title: "Organic Herbal Green Tea",
    price: 190,
    image: Product2,
    sold: 14,
  },
  {
    title: "Black Tea",
    price: 90,
    image: Product3,
    sold: 49,
  },
  {
    title: "Organic Herbal Tea",
    price: 290,
    image: Product2,
    sold: 18,
  },
  // {
  //   title: "Organic Herbal Green Tea",
  //   price: 190,
  //   image: Product1,
  //   sold: 33,
  // },
  // {
  //   title: "Organic Herbal Green Tea",
  //   price: 190,
  //   image: Product2,
  //   sold: 14,
  // },
  // {
  //   title: "Organic Herbal Green Tea",
  //   price: 190,
  //   image: Product3,
  //   sold: 6,
  // },
  // {
  //   title: "Organic Herbal Green Tea",
  //   price: 190,
  //   image: Product1,
  //   sold: 53,
  // },
  // {
  //   title: "Organic Herbal Green Tea",
  //   price: 190,
  //   image: Product2,
  //   sold: 15,
  // },
];
