import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import ProdImg from "../../assets/product1.png";
import { SlCalender, SlClose } from "react-icons/sl";
import { MdDeleteForever } from "react-icons/md";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, emptyCart, removeFromCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import axios from "axios";

const CartDetails = () => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchSuggestions = async (input, setter) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/order/places-autocomplete`,
        {
          params: {
            input,
            apiKey: "AIzaSyD-J0K_qCDMlZUsFQSnwyuJNn66g96CbaM",
          },
        }
      );
      const suggestions = response.data.predictions.map(
        (prediction) => prediction.description
      );
      setter(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  const onSuggestionsFetchRequested = async ({ value }, setter) => {
    fetchSuggestions(value, setter);
  };
  const onSuggestionsClearRequested = (setter) => {
    setter([]);
  };
  const onFromChange = (event, { newValue }) => {
    setFrom(newValue);
  };
  const onToChange = (event, { newValue }) => {
    setTo(newValue);
  };
  const onSuggestionSelected = async (event, { suggestionValue }) => {
    setTo(suggestionValue);
    fetchData();
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/order/distance-matrix`,
        {
          params: {
            origin: from,
            destination: to,
            apiKey: "AIzaSyD-J0K_qCDMlZUsFQSnwyuJNn66g96CbaM",
          },
        }
      );
      const { rows } = response.data;
      const { text: distance, value } = rows[0].elements[0].distance;
      const { text: duration } = rows[0].elements[0].duration;
      setDistance(distance);
      setDuration(duration);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const inputPropsFrom = {
    placeholder: "From",
    value: from,
    onChange: onFromChange,
  };
  const inputPropsTo = {
    placeholder: "To",
    value: to,
    onChange: onToChange,
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce((acc, item) => acc + item.qty * item.price, 0);

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  const [value, setValue] = useState(1);

  const increment = (cart) => {
    if (cart.stock <= cart.qty) {
      toast.error("Product stock limited!");
    } else {
      setValue(value + 1);
      const updateCartData = { ...cart, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (cart) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...cart, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  const clearCartHandler = () => {
    dispatch(emptyCart());
  };

  const handleCheckout = () => {
    var checkbox = document.getElementById("termsCheckbox");
    if (cart.length === 0) {
      toast.error("Cart is empty");
    } else if (!checkbox.checked) {
      toast.error("Please agree to the terms and condition");
    } else {
      navigate("/checkout");
    }
  };
  return (
    <div>
      <div className={`${styles.container}`}>
        <div className="800px:flex hidden flex-col w-full border-t-[1.5px] border-l-[1.5px] border-[#3C3C3C]">
          <div className="flex w-full h-[60px]">
            <div className="w-[36%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
              PRODUCT
            </div>
            <div className="w-[16%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
              PRICE
            </div>
            <div className="w-[16%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
              QUANTITY
            </div>
            <div className="w-[16%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
              TOTAL
            </div>
            <div className="w-[16%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center"></div>
          </div>
          {cart && cart.length === 0 ? (
            <div className="flex w-full h-[90px]">
              <div className="w-[100%] border-r-[1.5px] border-b-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                <h5>Cart Items is empty!</h5>
              </div>
            </div>
          ) : (
            <>
              {cart &&
                cart.map((i, index) => (
                  <div key={index} className="flex w-full h-[90px]">
                    <div className="w-[12%] border-r-[1.5px] border-b-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                      <img
                        src={`${process.env.REACT_APP_BUCKET_URL}${i?.images[0]}`}
                        alt=""
                        className="h-[94px] w-[128px] object-contain"
                      />
                    </div>
                    <div className="w-[24%] border-r-[1.5px] border-b-[1.5px] border-[#3C3C3C] flex flex-col justify-center items-center">
                      <p>{i.name}</p>
                      <p>{i.size}</p>
                    </div>
                    <div className="w-[16%] border-r-[1.5px] border-b-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                      ₹{i.price}
                    </div>
                    <div className="w-[16%] border-r-[1.5px] border-b-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                      <div className="w-[75px] h-[36px] flex justify-between items-center border-solid border-[1px] rounded-lg border-[#3C3C3C]">
                        <AiOutlineLeft
                          size={20}
                          onClick={() => decrement(i)}
                          className="cursor-pointer"
                        />
                        {i.qty}
                        <AiOutlineRight
                          size={20}
                          onClick={() => increment(i)}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                    <div className="w-[16%] border-r-[1.5px] border-b-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                      ₹{i.price * i.qty}
                    </div>
                    <div
                      onClick={() => removeFromCartHandler(i)}
                      className="w-[16%] border-r-[1.5px] border-b-[1.5px] border-[#3C3C3C] flex justify-center items-center"
                    >
                      <MdDeleteForever
                        size={25}
                        className="cursor-pointer text-[red]"
                      />
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>

        {/* mobile section */}
        <div className="block 800px:hidden">
          {cart && cart.length === 0 ? (
            <div className="flex w-full h-[90px]">
              <div className="w-[100%] flex justify-center items-center">
                <h5>Cart Items is empty!</h5>
              </div>
            </div>
          ) : (
            cart &&
            cart.map((i, index) => (
              <div className="flex gap-2 bg-gray-100 rounded-md p-2 py-3 mb-2">
                <div className="flex flex-col items-center gap-2">
                  <img
                    src={`${process.env.REACT_APP_BUCKET_URL}${i?.images[0]}`}
                    alt=""
                    className="h-[94px] w-[128px] object-contain"
                  />
                  <div className="w-[100px] h-[36px] flex justify-between items-center border-solid border-[1px] rounded-lg border-[#3C3C3C]">
                    <AiOutlineLeft
                      size={20}
                      onClick={() => decrement(i)}
                      className="cursor-pointer border-r-2 flex-1"
                    />
                    <h3 className="flex-1 text-center">{i.qty}</h3>
                    <AiOutlineRight
                      size={20}
                      onClick={() => increment(i)}
                      className="cursor-pointer border-l-2 flex-1"
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <p className="font-Poppins font-normal text-[16px]">
                    {i.name}
                  </p>
                  <p className="font-Poppins font-medium text-[20px]">
                    ₹{i.price}
                  </p>
                  <p className="font-Poppins font-semibold text-[14px]">
                    Size:{" "}
                    <span className="font-normal">
                      {i.variants[0]?.size}
                      {i.variants[0]?.unit}
                    </span>
                  </p>
                  <button
                    className="h-[36px] w-max border-[1px] rounded-lg border-[#3C3C3C] px-2 bg-white mt-auto cursor-pointer"
                    onClick={() => removeFromCartHandler(i)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex gap-3 justify-end w-full mt-4">
          {/* <button className={`${styles.button2} `}>Update Cart</button> */}
          <Link to="/shop">
            <button className={`${styles.button2} `}>Continue Shopping</button>
          </Link>
          {cart && cart.length !== 0 ? (
            <button onClick={clearCartHandler} className={`${styles.button2} `}>
              Clear Cart
            </button>
          ) : null}
        </div>

        <div className="flex justify-between mt-8">
          <div className="flex flex-col gap-8">
            {/* <div className="flex flex-col gap-3">
              <h4 className="text-[#000] font-Poppins font-[500] text-[20px]">
                Pick preferred delivery date and time
              </h4>
              <div className="flex gap-5 items-center">
                <SlCalender size={25} />
                <div className="w-[160px] h-[53px] bg-[#F4F3F3] flex justify-center items-center">
                  <input type="time" name="" id="" className="bg-[#F4F3F3]" />
                </div>
                <div className="w-[160px] h-[53px] bg-[#F4F3F3] flex justify-center items-center">
                  <input type="date" name="" id="" className="bg-[#F4F3F3]" />
                </div>
              </div>
            </div> */}
            {/* <div className="flex flex-col gap-3">
              <h4 className="text-[#000] font-Poppins font-[500] text-[20px]">
                Get shipping estimates
              </h4>
              <Autosuggest
                suggestions={fromSuggestions}
                onSuggestionsFetchRequested={({ value }) =>
                  onSuggestionsFetchRequested({ value }, setFromSuggestions)
                }
                onSuggestionsClearRequested={() =>
                  onSuggestionsClearRequested(setFromSuggestions)
                }
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion) => <div>{suggestion}</div>}
                onSuggestionSelected={(event, { suggestionValue }) =>
                  onSuggestionSelected(event, { suggestionValue })
                }
                inputProps={inputPropsFrom}
              />
              <Autosuggest
                suggestions={toSuggestions}
                onSuggestionsFetchRequested={({ value }) =>
                  onSuggestionsFetchRequested({ value }, setToSuggestions)
                }
                onSuggestionsClearRequested={() =>
                  onSuggestionsClearRequested(setToSuggestions)
                }
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion) => <div>{suggestion}</div>}
                onSuggestionSelected={(event, { suggestionValue }) =>
                  onSuggestionSelected(event, { suggestionValue })
                }
                inputProps={inputPropsTo}
              />
              {distance && <p>Distance: {distance}</p>}
              {duration && <p>Duration: {duration}</p>}
            </div> */}
          </div>
          {cart && cart.length !== 0 ? (
            <div className="w-[446px] bg-[#F4F3F3] flex flex-col gap-3 px-10 py-10">
              <h4 className="text-[#000] font-Poppins font-[500] text-[20px] self-center">
                Cart Totals
              </h4>
              <div className="flex flex-col w-full border-t-[1.5px] border-l-[1.5px] border-[#3C3C3C]">
                <div className="flex w-full h-[60px]">
                  <div className="w-[60%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                    SUB TOTAL
                  </div>
                  <div className="w-[40%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                    ₹{totalPrice}
                  </div>
                </div>
                <div className="flex w-full h-[60px]">
                  <div className="w-[60%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                    TOTAL
                  </div>
                  <div className="w-[40%] border-b-[1.5px] border-r-[1.5px] border-[#3C3C3C] flex justify-center items-center">
                    ₹{totalPrice}
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <input type="checkbox" name="" id="termsCheckbox" />
                <label for="myCheckbox">
                  I agree with the terms and conditions
                </label>
                {/* <p>I agree with the terms and conditions</p> */}
              </div>
              <button onClick={handleCheckout} className={`${styles.button2} `}>
                Checkout
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CartDetails;
