import React from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminEnquiryList from '../../components/Admin/AdminEnquiryList.jsx'

const AdminEnquiryPage = () => {
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={6} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminEnquiryList />
        </div>
    </div>
  )
}

export default AdminEnquiryPage   