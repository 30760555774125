import React, { useEffect } from 'react'
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import Signup from '../components/Signup/Signup.jsx'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SignupPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);
  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    }
  });
  return (
    <div>
      <Header />
      <div className="h-[11vh]"></div>
      <Signup />
      <Footer page={"register"} />
    </div>
  );
}

export default SignupPage
