import React, { useEffect } from 'react'
import Header from '../components/Layout/Header'
import Hero from '../components/Layout/Hero'
import Footer from '../components/Layout/Footer'
import ProductList from '../components/ProductList/ProductList.jsx'

const ShopPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header activeHeading={3} />
      <div className="h-[11vh]"></div>
      <Hero page={'shop'} />
      <ProductList />
      <Footer />
    </div>
  );
}

export default ShopPage
