import React, { useEffect, useState } from "react";
import { GrUploadOption } from "react-icons/gr";
import { TiDelete } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TbPlaylistAdd, TbPlaylistX } from "react-icons/tb";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { productFormSchema } from "../../schema/valiationSchema";
import { BiError } from "react-icons/bi";

const AdminEditProduct = ({ id, data }) => {
  const navigate = useNavigate();

  const [images, setImages] = useState(data && data.images);
  const [newImages, setNewImages] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [variants, setVariants] = useState(data && data.variants);
  const [initialValues, setInitialValues] = useState({});
  const [idx, setIdx] = useState("");
  const [img, setImg] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      setImages(data.images);

      setInitialValues((preInitialValues) => ({
        ...preInitialValues,
        name: data.name,
        variants: data && data.variants,
        stock: data && data.stock,
        description: data && data.description,
      }));
    }
  }, [data]);

  const handleChangeUnit = (index, value) => {
    const updatedItems = [...variants];
    updatedItems[index] = { ...updatedItems[index], unit: value };
    setVariants(updatedItems);
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    const fileInput = e.target;
    const maxFiles = 3;

    if (
      images?.length + newImages?.length + fileInput.files.length >
      maxFiles
    ) {
      alert(`You can only select upto ${maxFiles} files!`);
      fileInput.value = "";
    } else {
      const files = Array.from(e.target.files);
      setNewImages((prevImages) => [...prevImages, ...files]);
    }
  };

  const handleDeleteImage1 = async (index, img) => {
    handleClose();
    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.put(
        `${process.env.REACT_APP_SERVER}/product/delete-product-image/${id}?img=${img}`,
        {
          withCredentials: true,
        },
        config
      );
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteImage2 = (index) => {
    const updatedImages = [...newImages];
    updatedImages.splice(index, 1);
    setNewImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    if (images?.length > 0 || newImages?.length > 0) {
      const formData = new FormData();

      newImages.forEach((image) => {
        formData.append("images", image);
      });
      formData.append("oldImages", JSON.stringify(images));
      formData.append("name", e.name);
      e.variants.forEach((variant, index) => {
        formData.append(`variants[${index}][size]`, variant.size);
        formData.append(`variants[${index}][unit]`, variant.unit);
        formData.append(`variants[${index}][price]`, variant.price);
      });
      formData.append("stock", e.stock);
      formData.append("description", e.description);

      const config = { headers: { "Content-Type": "multipart/form-data" } };

      await axios
        .put(`${process.env.REACT_APP_SERVER}/product/update-product/${id}`, formData, {
          withCredentials: true,
        })
        .then((res) => {
          navigate("/admin-products");
          toast.success(res.data.message);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Image is required");
    }
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              EDIT PRODUCT
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px]">
            <div className="flex-col hidden 1000px:flex">
              {/* {images.length === 0 ? (
                            <div className="w-[250px] h-[280px] bg-[#E5E5E5] rounded-[10px] flex justify-center items-center">
                            <input
                                type="file"
                                name=""
                                id="upload"
                                className= "hidden"
                                multiple
                                onChange={handleImageChange}
                            />
                            <label htmlFor="upload">
                                <GrUploadOption size={30} className='cursor-pointer' title='add product' />
                            </label>
                        </div>
                        ) : (
                            <div className="flex items-center flex-wrap gap-2 bg-[#E5E5E5] rounded-[10px] px-2 py-1">
                                <input
                                    type="file"
                                    name=""
                                    id="upload"
                                    className= "hidden"
                                    multiple
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="upload">
                                    <GrUploadOption size={30} className='cursor-pointer' title='add product' />
                                </label>
                                {
                                    images && images.map((i, index) => (
                                        <div className="relative">
                                            <img src={URL.createObjectURL(i)} alt="" key={i} className='h-[120px] w-[120px] m-2 object-contain' />
                                            <TiDelete size={25} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 cursor-pointer' onClick={() => handleDeleteImage1(index)} />
                                        </div>

                                    ))
                                }
                            </div>
                        )} */}

              <div className="w-[250px] h-[280px] bg-[#E5E5E5] rounded-[10px] flex justify-center items-center">
                <img
                  src="https://www.pngall.com/wp-content/uploads/2016/06/Ecommerce-Download-PNG.png"
                  alt=""
                  className="object-contain w-[80%]"
                />
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={productFormSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, errors }) => (
                (
                  <Form className="w-full 1000px:w-[60%] flex flex-col gap-4">
                    {/* <form onSubmit={handleSubmit} className='w-full 1000px:w-[60%] flex flex-col gap-4'> */}
                    <div className="w-full flex justify-between items-center">
                      <label className="font-[Montserrat] font-[500] text-[16px]">
                        Title :
                      </label>
                      <div className="flex flex-col w-[70%]">
                        <input
                          type="text"
                          name="name"
                          value={values?.name}
                          className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          onChange={handleChange}
                          // placeholder="Enter your product name..."
                        />
                        <ErrorMessage name="name">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                              *{" "}
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <label className="font-[Montserrat] font-[500] text-[16px] self-start">
                        Select Variant:
                      </label>
                      <div className="w-[70%] flex flex-col gap-5">
                        <FieldArray name="variants">
                          {({ insert, remove, push }) => (
                            <div>
                              {values.variants?.length > 0 &&
                                values.variants.map((item, index) => (
                                  <div
                                    key={index}
                                    className="w-[100%] flex gap-5"
                                  >
                                    {/* <label htmlFor={`size${index}`} className="font-[Montserrat] font-[500] text-[16px] self-start hidden">Size:</label> */}
                                    <div className="flex flex-col w-[35%]">
                                      <Field
                                        type="number"
                                        id={`size${index}`}
                                        name={`variants.${index}.size`}
                                        placeholder="Size..."
                                        className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.size`}
                                      >
                                        {(msg) => (
                                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                                            *{" "}
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                    <select
                                      id={`sizeUnit${index}`}
                                      className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[20%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                      value={item.unit}
                                      onChange={(e) =>
                                        handleChangeUnit(index, e.target.value)
                                      }
                                    >
                                      <option value="G">g</option>
                                      <option value="KG">kg</option>
                                    </select>
                                    {/* <label htmlFor={`price${index}`} className='hidden'>Price:</label> */}
                                    <div className="flex flex-col w-[35%]">
                                      <Field
                                        type="number"
                                        id={`price${index}`}
                                        name={`variants.${index}.price`}
                                        placeholder="Price..."
                                        className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.price`}
                                      >
                                        {(msg) => (
                                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                                            *{" "}
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                    {index === values.variants?.length - 1 ? (
                                      <div
                                        className="w-[10%] self-center"
                                        onClick={() =>
                                          push({
                                            size: "",
                                            unit: "g",
                                            price: "",
                                          })
                                        }
                                      >
                                        <TbPlaylistAdd
                                          size={25}
                                          className="text-[#40890F] cursor-pointer"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="w-[10%] self-center"
                                        onClick={() => remove(index)}
                                      >
                                        <TbPlaylistX
                                          size={25}
                                          className="text-[red] cursor-pointer"
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <label className="font-[Montserrat] font-[500] text-[16px]">
                        Total Stock :
                      </label>
                      <div className="flex flex-col w-[70%]">
                        <input
                          type="text"
                          name="stock"
                          value={values.stock}
                          className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          onChange={handleChange}
                          // placeholder="Enter available stock for the product..."
                        />
                        <ErrorMessage name="stock">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                              *{" "}
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-start">
                      <label className="font-[Montserrat] font-[500] text-[16px]">
                        Add Description :
                      </label>
                      <div className="flex flex-col w-[70%]">
                        <textarea
                          name="description"
                          value={values.description}
                          rows="6"
                          className="rounded-[8px] px-3 py-2 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          onChange={handleChange}
                        />
                        <ErrorMessage name="description">
                          {(msg) => (
                            <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                              *{" "}
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="w-full flex items-center">
                      <label className="font-[Montserrat] font-[500] text-[16px] w-[30%]">
                        Upload Images :
                      </label>
                      <div className="flex items-center flex-wrap gap-2 bg-[#E5E5E5] rounded-[10px] px-2 py-1 w-[70%]">
                        <input
                          type="file"
                          name=""
                          id="upload"
                          className="hidden"
                          multiple
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                        <label htmlFor="upload">
                          <GrUploadOption
                            size={30}
                            className="cursor-pointer"
                            title="add product"
                          />
                        </label>
                        {images &&
                          images?.map((i, index) => (
                            <>
                              <div className="relative" key={index}>
                                {/* <img src={URL.createObjectURL(i)} alt="" key={i} className='h-[120px] w-[120px] m-2 object-contain' /> */}
                                <img
                                  // src={`${process.env.REACT_APP_BACKEND_URL}/${i}`}
                                  src={`${process.env.REACT_APP_BUCKET_URL}${i}`}
                                  alt=""
                                  key={i}
                                  className="h-[120px] w-[120px] m-2 object-contain"
                                />
                                <TiDelete
                                  size={25}
                                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 cursor-pointer"
                                  onClick={() => {handleClickOpen();setIdx(index);setImg(i)}}
                                />

                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {"Please confirm to delete the image?"}
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      Deleted image cannot be discarded
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose}>
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleDeleteImage1(idx, img)
                                      }
                                      autoFocus
                                    >
                                      Confirm
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </>
                          ))}
                        {newImages &&
                          newImages?.map((i, index) => (
                            <div className="relative">
                              <img
                                src={URL.createObjectURL(i)}
                                alt=""
                                key={i}
                                className="h-[120px] w-[120px] m-2 object-contain"
                              />
                              {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/${i}`} alt="" key={i} className='h-[120px] w-[120px] m-2 object-contain' /> */}
                              <TiDelete
                                size={25}
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 cursor-pointer"
                                onClick={() => handleDeleteImage2(index)}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="w-full flex items-start">
                      <div className="w-[30%]"></div>
                      <div className="flex gap-3 w-[70%]">
                        <input
                          type="submit"
                          value="UPDATE"
                          className="bg-[#40890F] text-white font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-3 py-1 cursor-pointer"
                        />
                        <Link to="/admin-products">
                          <div className="bg-[#bbbaba] text-black font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-3 py-1 cursor-pointer">
                            DISCARD
                          </div>
                        </Link>
                      </div>
                    </div>

                    {/* </form> */}
                  </Form>
                )
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditProduct;
