import React, { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import styles from "../../styles/styles";
import axios from "axios";
import { franchiseFormSchema } from "../../schema/valiationSchema";
import { BiError } from "react-icons/bi";
import io from "socket.io-client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});

const socket = io(process.env.REACT_APP_BACKEND_URL);

const FranchiseForm = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e, i) => {
    setLoading(true)
    const config = { headers: { "Content-Type": "application/json" } };

    const formData = new FormData();
    formData.append("Name", e.Name);
    formData.append("City", e.City);
    formData.append("Phone", e.Phone);
    formData.append("Email", e.Email);
    formData.append("Message", e.Message);
    formData.append("Investment", e.Investment);

    fetch(
      "https://script.google.com/macros/s/AKfycbxF3WNMDKhBKyYPHqK7iY30K1gsk2BjDDGjgA4g_bNrg_vjFrZqWJi8zVQvB_-3rC929Q/exec", {
        method: "POST",
        body: formData
      }
    )

    axios
      .post(
        `${process.env.REACT_APP_SERVER}/enquirie/create-enquirie`,
        e,
        config
      )
      .then((res) => {
        setLoading(false)
        toast.success(res.data.message);
        i.resetForm();

        socket.emit("enquiry", { date: new Date() });
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Formik
      initialValues={{
        Name: "",
        Email: "",
        Phone: "",
        City: "",
        Investment: "",
        Message: "",
      }}
      validationSchema={franchiseFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
        <Form>
          <div
            className={`${styles.container} flex flex-col items-center gap-5 my-5`}
          >
            <h3 className="font-Poppins font-[500] text-[30px]">
              Apply For The Chai Walah Franchise
            </h3>
            <input
              type="text"
              name="Name"
              autoComplete="name"
              value={values.Name}
              onChange={handleChange}
              className="w-full 800px:w-[70%] 1100px:w-[60%] h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 focus:bg-gray-200 hover:bg-gray-200"
              placeholder="Full Name"
            />
            <ErrorMessage name="Name">
              {(msg) => (
                <div className="w-full 800px:w-[70%] 1100px:w-[60%] flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md -mt-5 text-[red]">
                  * {msg}
                </div>
              )}
            </ErrorMessage>
            <input
              type="email"
              name="Email"
              autoComplete="email"
              value={values.Email}
              onChange={handleChange}
              className="w-full 800px:w-[70%] 1100px:w-[60%] h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 focus:bg-gray-200 hover:bg-gray-200"
              placeholder="Email-ID"
            />
            <ErrorMessage name="Email">
              {(msg) => (
                <div className="w-full 800px:w-[70%] 1100px:w-[60%] flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md -mt-5 text-[red]">
                  * {msg}
                </div>
              )}
            </ErrorMessage>
            <input
              type="number"
              name="Phone"
              autoComplete="phone"
              value={values.Phone}
              onChange={handleChange}
              className="w-full 800px:w-[70%] 1100px:w-[60%] h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 focus:bg-gray-200 hover:bg-gray-200"
              placeholder="Phone No."
            />
            <ErrorMessage name="Phone">
              {(msg) => (
                <div className="w-full 800px:w-[70%] 1100px:w-[60%] flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md -mt-5 text-[red]">
                  * {msg}
                </div>
              )}
            </ErrorMessage>
            <input
              type="text"
              name="City"
              autoComplete="location"
              value={values.City}
              onChange={handleChange}
              className="w-full 800px:w-[70%] 1100px:w-[60%] h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 focus:bg-gray-200 hover:bg-gray-200"
              placeholder="Location"
            />
            <ErrorMessage name="City">
              {(msg) => (
                <div className="w-full 800px:w-[70%] 1100px:w-[60%] flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md -mt-5 text-[red]">
                  * {msg}
                </div>
              )}
            </ErrorMessage>
            <input
              type="text"
              name="Investment"
              value={values.Investment}
              onChange={handleChange}
              className="w-full 800px:w-[70%] 1100px:w-[60%] h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 focus:bg-gray-200 hover:bg-gray-200"
              placeholder="Investment"
            />
            <ErrorMessage name="Investment">
              {(msg) => (
                <div className="w-full 800px:w-[70%] 1100px:w-[60%] flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md -mt-5 text-[red]">
                  * {msg}
                </div>
              )}
            </ErrorMessage>
            <textarea name="Message" value={values.Message} onChange={handleChange} id=""  rows="6" placeholder="Message" className="w-full 800px:w-[70%] 1100px:w-[60%] border-[1.5px] border-[#3C3C3C40] px-3 pt-3 focus:bg-gray-200 hover:bg-gray-200 textarea"></textarea>
            <ErrorMessage name="Message">
              {(msg) => (
                <div className="w-full 800px:w-[70%] 1100px:w-[60%] flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md -mt-5 text-[red]">
                  * {msg}
                </div>
              )}
            </ErrorMessage>
            <button type="submit" className={loading ? styles.LoadingBtn : styles.button2}>
              {!loading ? (
                <h1>Submit</h1>
              ) : (
                <ThemeProvider theme={customTheme}>
                  <CircularProgress color="primary" size='1.5rem' />
                </ThemeProvider>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FranchiseForm;
