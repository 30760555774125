const styles = {
  section: "w-11/12 mx-auto",
  container: "max-w-[1400px] w-[100%] m-auto px-[20px] py-[20px]",
  container2: "",
  fontColor: "#3C3C3C",
  button1:
    "bg-[#3C3C3C] rounded-[10px] 800px:text-[20px] text-[17px] font-Poppins font-[500] text-white cursor-pointer border-[1.5px] border-[#3C3C3C] 800px:py-[0.4rem] py-[0.3rem] 800px:px-[2rem] px-[2rem]",
  button2: "bg-[#3C3C3C] text-[12px] font-Poppins font-[500] text-white cursor-pointer border-[1.5px] border-[#3C3C3C] py-[0.5rem] px-[2rem]",
  LoadingBtn: "bg-[#3C3C3C] text-[12px] font-Poppins font-[500] text-white cursor-pointer border-[1.5px] border-[#3C3C3C] py-[0.3rem] px-[2.5rem] flex items-center justify-center",
  input:"w-full border p-1 rounded-[5px]",
  button3: 'w-[150px] bg-black h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer',
};

export default styles;