import React from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminAddSubAdmin from '../../components/Admin/AdminAddSubAdmin.jsx'

const AdminAddSubAdminPage = () => {
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={5} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminAddSubAdmin />
        </div>
    </div>
  )
}

export default AdminAddSubAdminPage 