import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { AiOutlineLeft } from "react-icons/ai";
import  io  from "socket.io-client";
import { emptyCart } from "../../redux/actions/cart";

const socket = io(process.env.REACT_APP_BACKEND_URL)

const Payment = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [select, setSelect] = useState(1);
  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const navigate = useNavigate();
  // const stripe = useStripe();
  // const elements = useElements();

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    subTotal: 0,
    totalPrice: orderData?.totalPrice,
    shippingCost: orderData?.shipping,
  };

  const paymentData = {
    amount: Math.round((orderData?.totalPrice + orderData?.shipping) * 100),
  };

  const handleOpenRazorpay = (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var options = {
      key: "rzp_test_cc4fz9j24Lrfa4",
      amount: data.amount,
      currency: data.currency,
      order_id: data.id,
      name: "The Chai Walah",
      description: "xyzzz",
      prefill: {
        contact: user.phoneNumber,
      },
      handler: async function (response) {
        // await axios
        //   .post(`${process.env.REACT_APP_SERVER}/payment/verify`, { response: response })
        //   .then((res) => {
        //     console.log(res, "59");
        //     console.log(res.data.message, ">>>>>>");
            order.paymentInfo = {
              id: data.id,
              status: 'succeeded',
              type: "Credit Card",
            };
    
            axios
              .post(`${process.env.REACT_APP_SERVER}/order/create-order`, order, config)
              .then((res) => {
                toast.success("Order successful!");
                localStorage.setItem("cartItems", JSON.stringify([]));
                localStorage.setItem("latestOrder", JSON.stringify([]));
                socket.emit("order", { date: new Date()})
                dispatch(emptyCart())
                navigate("/");
              });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      },
    };

    const rzp = new window.Razorpay(options);

    rzp.open();
  };

  const paymentHandler = async (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_SERVER}/payment/orderz`, paymentData)
      .then((res) => {
        handleOpenRazorpay(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const paymentHandler = async (e) => {
  //   e.preventDefault();
  // try {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   const { data } = await axios.post(
  //     `${process.env.REACT_APP_SERVER}/payment/process`,
  //     paymentData,
  //     config
  //   );

  //   const client_secret = data.client_secret;

  //   if (!stripe || !elements) return;
  //   const result = await stripe.confirmCardPayment(client_secret, {
  //     payment_method: {
  //       card: elements.getElement(CardNumberElement),
  //     },
  //   });

  //   if (result.error) {
  //     toast.error(result.error.message);
  //   } else {
  //     if (result.paymentIntent.status === "succeeded") {
  //       order.paymentInfo = {
  //         id: result.paymentIntent.id,
  //         status: result.paymentIntent.status,
  //         type: "Credit Card",
  //       };

  //       await axios
  //         .post(`${process.env.REACT_APP_SERVER}/order/create-order`, order, config)
  //         .then((res) => {
  //           setOpen(false);
  //           toast.success("Order successful!");
  //           localStorage.setItem("cartItems", JSON.stringify([]));
  //           localStorage.setItem("latestOrder", JSON.stringify([]));
  //             navigate("/");
  //         });
  //     }
  //   }
  // } catch (error) {
  //   toast.error(error);
  // }
  // };

  const cashOnDeliveryHandler = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    order.paymentInfo = {
      type: "Cash On Delivery",
    };

    await axios
      .post(`${process.env.REACT_APP_SERVER}/order/create-order`, order, config)
      .then((res) => {
        setOpen(false);
        toast.success("Order successful!");
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("latestOrder", JSON.stringify([]));
        socket.emit("order", { date: new Date()})
        dispatch(emptyCart())
        navigate("/");
      });
  };
  return (
    <div className="flex flex-col gap-5 mt-16 max-w-[840px] w-[100%] m-auto">
      <div className="w-full border-[1.5px] border-[#3C3C3C80] flex flex-col gap-3 px-5 py-4">
        <div className="flex w-full">
          <h4 className="w-[70px] font-Poppins font-[400] text-[14px] text-[#3C3C3C80]">
            Contact
          </h4>
          <h4 className="flex-1 font-Poppins font-[400] text-[14px] text-[#3C3C3C] truncate">
            {user?.email}
          </h4>
        </div>
        <hr className="border-[#3C3C3C80]" />
        <div className="flex w-full">
          <h4 className="w-[70px] font-Poppins font-[400] text-[14px] text-[#3C3C3C80]">
            Ship to
          </h4>
          <h4 className="flex-1 font-Poppins font-[400] text-[14px] text-[#3C3C3C] truncate">
            {orderData?.shippingAddress?.state},{" "}
            {orderData?.shippingAddress?.city}
          </h4>
        </div>
        <hr className="border-[#3C3C3C80]" />
        <div className="flex w-full">
          <h4 className="w-[70px] font-Poppins font-[400] text-[14px] text-[#3C3C3C80]">
            Method
          </h4>
          <h4 className="flex-1 font-Poppins font-[400] text-[14px] text-[#3C3C3C] truncate">
            Standard Shipping. ₹{orderData?.shipping}
          </h4>
        </div>
      </div>
      <h3 className="font-Poppins font-[500] text-[25px]">Payment</h3>
      <h4 className="font-Poppins font-[400] text-[14px] text-[#3C3C3C80]">
        All transactions are secure and encrypted.
      </h4>
      <div className="w-full border-[1.5px] border-[#3C3C3C80] flex justify-center items-center gap-3 px-5 py-4">
        {/* <h4 className="font-Poppins font-[400] text-[14px] text-[#3C3C3C80]">
                This store can’t accept payments right now.
              </h4> */}
        <div className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8">
          {/* select buttons */}
          <div>
            <div className="flex w-full pb-5 border-b mb-2">
              <div
                className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
                onClick={() => setSelect(1)}
              >
                {select === 1 ? (
                  <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
                ) : null}
              </div>
              <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
                Pay with Debit/credit card
              </h4>
            </div>

            {/* pay with card */}
            {select === 1 ? (
              <div className="w-full flex border-b">
                <form className="w-full" onSubmit={paymentHandler}>
                  {/* <div className="w-full flex pb-3">
                    <div className="w-[50%]">
                      <label className="block pb-2">Name On Card</label>
                      <input
                        required
                        placeholder={user && user.name}
                        className={`${styles.input} !w-[95%] text-[#444]`}
                        value={user && user.name}
                      />
                    </div>
                    <div className="w-[50%]">
                      <label className="block pb-2">Exp Date</label>
                      <CardExpiryElement
                        className={`${styles.input}`}
                        options={{
                          style: {
                            base: {
                              fontSize: "19px",
                              lineHeight: 1.5,
                              color: "#444",
                            },
                            empty: {
                              color: "#3a120a",
                              backgroundColor: "transparent",
                              "::placeholder": {
                                color: "#444",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full flex pb-3">
                    <div className="w-[50%]">
                      <label className="block pb-2">Card Number</label>
                      <CardNumberElement
                        className={`${styles.input} !h-[35px] !w-[95%]`}
                        options={{
                          style: {
                            base: {
                              fontSize: "19px",
                              lineHeight: 1.5,
                              color: "#444",
                            },
                            empty: {
                              color: "#3a120a",
                              backgroundColor: "transparent",
                              "::placeholder": {
                                color: "#444",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="w-[50%]">
                      <label className="block pb-2">CVV</label>
                      <CardCvcElement
                        className={`${styles.input} !h-[35px]`}
                        options={{
                          style: {
                            base: {
                              fontSize: "19px",
                              lineHeight: 1.5,
                              color: "#444",
                            },
                            empty: {
                              color: "#3a120a",
                              backgroundColor: "transparent",
                              "::placeholder": {
                                color: "#444",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div> */}
                  <input
                    type="submit"
                    value="Confirm"
                    className={`px-2 bg-[#40890F] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600] mb-3`}
                  />
                </form>
              </div>
            ) : null}
          </div>

          <br />
          {/* cash on delivery */}
          <div>
            <div className="flex w-full pb-5 border-b mb-2">
              <div
                className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
                onClick={() => setSelect(3)}
              >
                {select === 3 ? (
                  <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
                ) : null}
              </div>
              <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
                Cash on Delivery
              </h4>
            </div>

            {/* cash on delivery */}
            {select === 3 ? (
              <div className="w-full flex border-b">
                <form className="w-full" onSubmit={cashOnDeliveryHandler}>
                  <input
                    type="submit"
                    value="Confirm"
                    className={`px-2 bg-[#40890F] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600] mb-3`}
                  />
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Link to="/checkout">
          <div className="flex gap-2">
            <AiOutlineLeft
              size={20}
              className="text-[#40890F] cursor-pointer"
            />
            <h5 className="font-Poppins font-[300] text-[14px] text-[#40890F] cursor-pointer">
              Return to information
            </h5>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Payment;
