import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { BiError } from "react-icons/bi";
import { toast } from "react-toastify";
import { loadAdmin } from "../../redux/actions/user";
import { adminProfileFormSchema } from "../../schema/valiationSchema";

const AdminProfileSettings = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.admin);

  const handleSubmit = async (e) => {
    const name = e.name;
    const email = e.email;
    const phoneNumber = e.phoneNumber;
    const password = e.password;

    await axios
      .put(
        `${process.env.REACT_APP_SERVER}/admin/update-admin-info`,
        {
          name,
          email,
          phoneNumber,
          password,
        },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then((res) => {
        dispatch(loadAdmin());
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <h2 className="font-[Montserrat] font-[600] text-[20px]">
                PROFILE SETTINGS
              </h2>
              <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
                THE CHAI WALAH Admin Panel
              </span>
            </div>
            <Link to="/admin-settings-password">
              <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                Change Password
              </button>
            </Link>
          </div>
          <Formik
            initialValues={{
              name: admin && admin.name,
              email: admin && admin.email,
              phoneNumber: admin && admin.phoneNumber,
              password: "",
            }}
            validationSchema={adminProfileFormSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form className="mt-[20px] flex flex-col gap-4">
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    Email :
                  </label>
                  <div className="flex flex-col w-[70%]">
                    <input
                      disabled
                      type="email"
                      name="email"
                      value={values.email}
                      className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                      // placeholder="Enter your product price..."
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    Name :
                  </label>
                  <div className="flex flex-col w-[70%]">
                    <input
                      type="text"
                      name="name"
                      value={values.name}
                      className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                      // placeholder="Enter your product price..."
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    Phone Number :
                  </label>
                  <div className="flex flex-col w-[70%]">
                    <input
                      type="number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                      // placeholder="Enter your product price..."
                    />
                    <ErrorMessage name="phoneNumber">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    Password :
                  </label>
                  <div className="flex flex-col w-[70%]">
                    <input
                      type="password"
                      name="password"
                      value={values.password}
                      className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                      // placeholder="Enter your product price..."
                    />
                    <ErrorMessage name="password">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-end">
                  <input
                    type="submit"
                    value="Update"
                    className="bg-[#40890F] text-white font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-5 py-2 cursor-pointer"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AdminProfileSettings;
