import React, { useEffect, useState } from "react";
import Chai1 from "../../assets/product2.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import styles from "../../styles/styles";

const AdminOrderDetails = () => {
  const { adminOrders } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, [dispatch]);

  const data = adminOrders && adminOrders.find((item) => item._id === id);

  const orderUpdateHandler = async (e) => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVER}/order/update-order-status/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order updated!");
        navigate(`/admin-orders`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const refundOrderUpdateHandler = async (e) => {
    await axios
    .put(
      `${process.env.REACT_APP_SERVER}/order/order-refund-success/${id}`,
      {
        status,
      },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Order updated!");
      dispatch(getAllOrdersOfAdmin());
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
  }

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full flex flex-col">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              ORDER DETAILS
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] w-full flex-1">
            <div className="flex 1000px:flex-row 1000px:gap-0 gap-8 flex-col h-full w-full">
              <div className="flex-1 flex flex-col">
                <h2 className="font-[Montserrat] font-[600] text-[20px]">
                  Order Number{" "}
                  <span className="font-[Montserrat] font-[500] text-[20px] text-[#D11F1F]">
                    #{data?._id?.slice(0, 8)}
                  </span>
                </h2>
                <table className="mt-[20px]">
                  <th className="flex justify-between border-[#3C3C3C80] border-t border-b py-2">
                    <td className="font-[Montserrat] font-[500] text-[18px] w-[50%] text-start">
                      Items
                    </td>
                    <td className="font-[Montserrat] font-[500] text-[18px] w-[25%] text-start">
                      Quantity
                    </td>
                    <td className="font-[Montserrat] font-[500] text-[18px] w-[25%] text-start">
                      Amount
                    </td>
                  </th>
                  {data &&
                    data?.cart.map((item, index) => (
                      <tr className="flex justify-between items-center border-[#3C3C3C80] border-b py-2">
                        <td className="font-[Montserrat] font-[500] text-[18px] flex gap-2 items-center w-[50%]">
                          <img
                            src={`${process.env.REACT_APP_BUCKET_URL}${item.images[0]}`}
                            alt=""
                            className="object-contain h-[45px] w-[65px]"
                          />
                          <h3 className="font-[Montserrat] font-[500] text-[16px]">
                            {item.name}
                          </h3>
                        </td>
                        <td className="font-[Montserrat] font-[500] text-[16px] w-[25%]">
                            {item.qty}
                        </td>
                        <td className="font-[Montserrat] font-[500] text-[16px] w-[25%]">
                          ₹{item.price * item.qty}
                        </td>
                      </tr>
                    ))}

                  
                  <tr className="flex flex-col gap-2 border-[#3C3C3C80] border-b py-2">
                    <tr className="flex justify-between">
                      <h3 className="font-[Montserrat] font-[500] text-[18px] w-[75%]">
                        Subtotal :
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[18px] w-[25%]">
                        ₹{data?.totalPrice}
                      </h3>
                    </tr>
                    <tr className="flex justify-between">
                      <h3 className="font-[Montserrat] font-[500] text-[18px] w-[75%]">
                        Shipping :
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[18px] w-[25%]">
                        ₹{data?.shippingCost?.toFixed(2)}
                      </h3>
                    </tr>
                  </tr>
                  <tr className="flex flex-col gap-2 border-[#3C3C3C80] border-b py-2">
                    <tr className="flex justify-between">
                      <h3 className="font-[Montserrat] font-[600] text-[20px] w-[75%]">
                        Total Price :
                      </h3>
                      <h3 className="font-[Montserrat] font-[600] text-[20px] w-[25%]">
                        ₹{data?.totalPrice + data?.shippingCost}
                      </h3>
                    </tr>
                  </tr>
                </table>
              </div>
              <div className="flex-1 flex flex-col gap-3 1000px:ml-20 ml-0">
                <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
                  <h3 className="font-[Montserrat] font-[600] text-[18px]">
                    Summery
                  </h3>
                  <ul className="list-disc px-5">
                    <li className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                      Order ID : {data?._id?.slice(0, 8)}
                    </li>
                    <li className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                      Placed On : {data?.createdAt?.slice(0, 10)}
                    </li>
                    <li className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                      Order Total : ₹{data?.totalPrice + data?.shippingCost}
                    </li>
                  </ul>
                </div>
                <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
                  <h3 className="font-[Montserrat] font-[600] text-[18px]">
                    Shipping Address
                  </h3>
                  <h4 className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                    {data?.shippingAddress?.country}, {data?.shippingAddress?.state}, {data?.shippingAddress?.district}, <br /> 
                    {data?.shippingAddress?.city}, {data?.shippingAddress?.pinCode} <br />
                    Contact No. {data?.shippingAddress?.phoneNumber}
                  </h4>
                </div>
                <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
                  <h3 className="font-[Montserrat] font-[600] text-[18px]">
                    Payment Method
                  </h3>
                  <h4 className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                    {data?.paymentInfo?.type} <br /> 
                    {data?.paymentInfo?.status ? data?.paymentInfo?.status : null}
                  </h4>
                </div>
                <div className="bg-white pt-3 pb-8 px-5 rounded-[10px] flex flex-col gap-2">
                  <h3 className="font-[Montserrat] font-[600] text-[18px]">
                    Track Order
                  </h3>
                  <h4 className="font-[Montserrat] font-[400] text-[14px] text-[#3C3C3C]">
                    Current status: {data?.status}
                  </h4>
                  <div className="flex justify-between items-center">
                        {data?.status !== "Processing refund" && data?.status !== "Refund Success" && (
                            <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            className="w-[200px] mt-2 border h-[35px] rounded-[5px]"
                            >
                            {[
                                "Processing",
                                "Transferred to delivery partner",
                                "Shipping",
                                "Received",
                                "On the way",
                                "Delivered",
                            ]
                                .slice(
                                [
                                    "Processing",
                                    "Transferred to delivery partner",
                                    "Shipping",
                                    "Received",
                                    "On the way",
                                    "Delivered",
                                ].indexOf(data?.status)
                                )
                                .map((option, index) => (
                                <option value={option} key={index}>
                                    {option}
                                </option>
                                ))}
                            </select>
                        )}
                        {
                            data?.status === "Processing refund" || data?.status === "Refund Success" ? (
                            <select value={status} 
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-[200px] mt-2 border h-[35px] rounded-[5px]"
                        >
                            {[
                                "Processing refund",
                                "Refund Success",
                            ]
                                .slice(
                                [
                                    "Processing refund",
                                    "Refund Success",
                                ].indexOf(data?.status)
                                )
                                .map((option, index) => (
                                <option value={option} key={index}>
                                    {option}
                                </option>
                                ))}
                        </select>
                            ) : null
                        }

                        <div
                            className={`bg-[#FCE1E6] rounded-[4px] text-[#E94560] font-[600] text-[18px] w-max h-min py-1 px-2 cursor-pointer`}
                            onClick={data?.status !== "Processing refund" ? orderUpdateHandler : refundOrderUpdateHandler}
                        >
                            Update Status
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrderDetails;
