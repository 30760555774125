import React from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import Login from '../components/Login/Login.jsx'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const LoginPage = () => {
  const navigate = useNavigate();
  const {isAuthenticated} = useSelector((state) => state.user)
  useEffect(() => {
    if(isAuthenticated === true){
      navigate('/')
    }
  })
  return (
    <div>
      <Header />
      <div className="h-[11vh]"></div>
      <Login />
      <Footer page={"register"} />
    </div>
  );
}

export default LoginPage
