import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { FiDelete, FiEdit } from "react-icons/fi";
import { LuSearch } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import image from "../../assets/product1.png";
import "../../styles/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getAllProductsAdmin,
} from "../../redux/actions/product";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const AdminProductList = () => {
  const navigate = useNavigate();
  const { products, isLoading, error } = useSelector((state) => state.products);
  const { admin } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const [prod,] = useState(products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);

  useEffect(() => {
    dispatch(getAllProductsAdmin());
  }, [dispatch,prod]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts = products?.filter((product) =>
      product.name.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(filteredProducts);
  };

  const handleAddBtn = () => {
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("createProduct")
    ) {
      navigate("/admin-add-product");
    } else {
      toast.error("Unauthorized");
    }
  };

  return (
    <div className="h-[89vh] w-[80vw] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              PRODUCTS LIST
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] mb-[20px]">
            <div className="flex">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-[218px] h-[38px] bg-[#fff] flex items-center px-3 rounded-l-[8px]"
                placeholder="search with product name"
              ></input>
              <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
                <LuSearch size={25} className="text-white" />
              </div>
            </div>
            <div className="flex items-center gap-4">
              {/* <h4 className="font-[Montserrat] font-[500] text-[14px] cursor-pointer">
                Export
              </h4> */}
              {/* <Link to="/admin-add-product"> */}
              <div onClick={handleAddBtn}>
                <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                  Add Product
                </button>
              </div>
              {/* </Link> */}
            </div>
          </div>
          <div>
            {error ? (
              <h1>{error}</h1>
            ) : (
              <ProductList
                products={products}
                isLoading={isLoading}
                searchData={searchData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductList = ({ products, isLoading, searchData }) => {
  const { admin } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [pageSize, setPageSize] = useState(6);

  const calcRows = () => {
    if (window.innerHeight >= 1250) {
      setPageSize(10);
    } else if (window.innerHeight > 1150) {
      setPageSize(9);
    } else if (window.innerHeight > 1050) {
      setPageSize(8);
    } else if (window.innerHeight > 950) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(6);
    } else if (window.innerHeight > 750) {
      setPageSize(5);
    } else if (window.innerHeight > 660) {
      setPageSize(4);
    } else if (window.innerHeight > 600) {
      setPageSize(3);
    } else if (window.innerHeight > 550) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  useEffect(() => {
    calcRows();
  }, []);

  const handleDeleteBtn = (id) => {
    handleClickOpen();
    setSelectedId(id);
  };

  const handleDelete = () => {
    handleClose();
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("deleteProduct")
    ) {
      dispatch(deleteProduct(selectedId)).then(() => {
        dispatch(getAllProductsAdmin());
      }).catch((error) => {
        console.log(error)
      })
    } else {
      toast.error("Unauthorized");
    }
  };

  const handleEditBtn = (id) => {
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("updateProduct")
    ) {
      navigate(`/admin-edit-product/${id}`);
    } else {
      toast.error("Unauthorized");
    }
  };

  const columns = [
    {
      field: "sno",
      flex: 0.5,
      minWidth: 45,
      headerName: "S.No",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <h1>{params.value}</h1>
          </>
        );
      },
    },
    // {
    //   field: "id",
    //   headerName: "Product Id",
    //   flex: 0.8,
    //   align: "left",
    //   headerAlign: "left",
    //   headerClassName: "products-grid-header",
    //   cellClassName: "products-grid-cell",
    // },
    {
      field: "image",
      flex: 0.8,
      minWidth: 140,
      headerName: "Product Image",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="h-[69px] w-[100px] flex items-center">
              <img
                // src={`${process.env.REACT_APP_BACKEND_URL}${params.value}`}
                src={`${process.env.REACT_APP_BUCKET_URL}${params.value}`}
                alt=""
                className="object-contain"
              />
            </div>
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Product Name",
      flex: 1,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
    },
    {
      field: "stock",
      headerName: "Stock",
      flex: 0.8,
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
    },
    {
      field: "sold",
      headerName: "Sold out",
      flex: 0.8,
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
    },
    {
      field: " ",
      headerName: "Options",
      flex: 1,
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      renderCell: (params) => {
        return (
          <div className="flex gap-2">
            {/* <Link to={`/admin-edit-product/${params.id}`}> */}
            <div onClick={() => handleEditBtn(params.id)} title="Edit product">
              <FiEdit size={20} className="cursor-pointer text-[green]" />
            </div>
            {/* </Link> */}
            <FiDelete
              size={20}
              onClick={() => handleDeleteBtn(params.id)}
              className="cursor-pointer text-[red]"
              title="Delete product"
            />
          </div>
        );
      },
    },
  ];

  const row = [];

  if (searchData) {
    searchData &&
      searchData.forEach((item, index) => {
        const price = Math.min.apply(
          null,
          item?.variants?.map((item) => item?.price)
        );
        row.push({
          id: item._id,
          sno: index + 1,
          image: item.images[0],
          title: item.name,
          stock: item.stock,
          sold: item.sold_out,
          price: "₹ " + price,
        });
      });
  } else {
    products &&
      products.forEach((item, index) => {
        const price = Math.min.apply(
          null,
          item?.variants?.map((item) => item?.price)
        );
        row.push({
          id: item._id,
          sno: index + 1,
          image: item.images[0],
          title: item.name,
          stock: item.stock,
          sold: item.sold_out,
          price: "₹ " + price,
        });
      });
  }

  const getRowHeight = (params) => {
    return 70;
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0
      ? "products-grid-row-even"
      : "products-grid-row-odd";
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-end border-b">
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : products?.length === 0 || searchData?.length === 0 ? (
        <h1>No data found!</h1>
      ) : (
        <div className={``}>
          <DataGrid
            rows={row}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: pageSize, page: 0 },
              },
            }}
            getRowHeight={getRowHeight}
            getRowClassName={getRowClassName}
            disableSelectionOnClick
            isRowSelectable={() => false}
            slots={{ toolbar: CustomToolbar }}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Please confirm to delete the product?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDelete} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default AdminProductList;
