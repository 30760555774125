import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import { loadUser } from "../redux/actions/user";
import Store from "../redux/store";

const ProtectedRoute = ({ children }) => {
  const { loading, isAuthenticated, user } = useSelector((state) => state.user);

  console.log(user,">>")
  if (loading === true) {
    return <h1>Loading...</h1>;
  } else {
    return user ? <Outlet /> : <Navigate to="/login"  />;
  }
};

export default ProtectedRoute;
