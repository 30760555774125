import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Modal } from "@mui/material";

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState("");
  const [pageNum, setPageNum] = useState(1);

  const [open, setOpen] = useState(false);
  const [img, setImg] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getGalleryItems = async () => {
    try {
      let limit = 10;
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/gallery/get-all-gallery-items-public?pageNum=${pageNum}&limit=${limit}`,
        {
          withCredentials: true,
        }
      );
      setGalleryItems(data?.media);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGalleryItems();
  }, []);

  useEffect(() => {
    getGalleryItems();
  }, [pageNum]);

  function handleZoom(img) {
    handleOpen()
    setImg(img)
  }

  return (
    <div className="w-full bg-white py-16">
      <div
        className={`${styles.container} h-[70vh] grid grid-rows-4 800px:grid-cols-4 grid-cols-2 grid-flow-row gap-10`}
      >
        {galleryItems.docs &&
          galleryItems.docs.map((i, index) => (
            <>
              <div
                className={`${
                  index === 0 || index === 7
                    ? "800px:col-span-2 800px:row-span-2 col-span-1 row-span-1"
                    : "col-span-1 row-span-1"
                } rounded-[19px] relative`}
              >
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}${i.images[0]}`}
                  alt=""
                  className="absolute inset-0 w-full h-full object-cover rounded-[19px] cursor-zoom-in"
                  onClick={() => handleZoom(i.images[0])}
                />
              </div>
            </>
          ))}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            className="h-full w-full flex justify-center items-center cursor-zoom-out"
            onClick={handleClose}
          >
            <img
              src={`${process.env.REACT_APP_BUCKET_URL}${img}`}
              alt=""
              className="inset-0 max-h-[50%] max-w-[50%] object-contain rounded-[19px]"
            />
          </div>
        </Modal>
      </div>
      <div className="flex justify-center">
        <Stack spacing={2}>
          <Pagination
            count={galleryItems?.totalPages}
            page={pageNum}
            onChange={(e, number) => setPageNum(number)}
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

export default Gallery;
