import logo from "./logo.svg";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import {
  HomePage,
  ShopPage,
  ProductDetailsPage,
  CartPage,
  LoginPage,
  SignupPage,
  CheckoutPage,
  ShippingPage,
  AboutusPage,
  FranchiseInfoPage,
  ContactPage,
  ActivationPage,
  ProfilePage,
  OrderDetailsPage,
  AdminLoginPage,
  PaymentPage,
  GalleryPage,
  BlogPage,
  PressPage,
  NewsDetailsPage,
  ErrorPage,
} from "./routes/Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useEffect, useState } from "react";
import Store from "./redux/store";
import { loadAdmin, loadUser } from "./redux/actions/user";
import ProtectedRoute from "./routes/ProtectedRoute";
import AdminProtectedRoute from "./routes/AdminProtectedRoute";
import {
  AdminHomePage,
  AdminDashboardPage,
  AdminProductsPage,
  AdminAddProductPage,
  AdminEditProductPage,
  AdminOrdersPage,
  AdminOrderDetailsPage,
  AdminCustomersList,
  AdminSubadminList,
  AdminAddSubAdminPage,
  AdminProductReviewPage,
  AdminEnquiryPage,
  AdminGalleryPage,
  AdminAddGalleryPage,
  AdminPressPage,
  AdminAddNewsPage,
  AdminEditNewsPage,
  AdminSettingsPage,
  AdminSettingsPasswordPage,
} from "./routes/AdminRoutes.js";
import { getAllProducts } from "./redux/actions/product";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMediaQuery } from "@mui/material";
import screenError from "../src/assets/screen-error.png";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  // const [stripeApikey, setStripeApiKey] = useState("");

  // async function getStripeApikey() {
  //   const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/payment/stripeapikey`);
  //   setStripeApiKey(data.stripeApikey);
  // }

  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadAdmin());
    Store.dispatch(getAllProducts());
    // getStripeApikey();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_URL);
  }, []);

  const isBigScreen = useMediaQuery("(min-width: 700px)");

  return (
    <BrowserRouter>
      {/* {stripeApikey && (
        <Elements stripe={loadStripe(stripeApikey)}>
          <Routes>
            <Route
              path="/payment"
              element={
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Elements>
      )} */}

      {!isBigScreen && window.location.pathname.startsWith("/admin") ? (
        <>
          <div className="flex flex-col justify-center items-center h-screen bg-[#11181F]">
            <img
              src={screenError}
              alt="mobile error"
              style={{ width: "50vw" }}
            />
            <h1 className="text-white text-[1rem] mt-2 mr-3 ml-3 text-center font-Poppins">
              Mobile Preview is not available; please open in a Desktop browser.
            </h1>
          </div>
        </>
      ) : (
        <Routes>
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/product/:id" element={<ProductDetailsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/press" element={<PressPage />} />
          <Route path="/press/:id" element={<NewsDetailsPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/about-us" element={<AboutusPage />} />
          <Route path="/franchise-info" element={<FranchiseInfoPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/activation/:activation_token" element={<ActivationPage />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/shipping" element={<ShippingPage />} />
            <Route path="/profile" exact element={<ProfilePage />} />
          </Route>

          {isBigScreen && (
            <Route path="/admin-login" element={<AdminLoginPage />} />
          )}
          <Route element={<AdminProtectedRoute />}>
            {isBigScreen && (
              <>
                <Route path="/admin/:id" element={<AdminHomePage />} />
                <Route
                  path="/admin-dashboard"
                  element={<AdminDashboardPage />}
                />
                <Route path="/admin-orders" element={<AdminOrdersPage />} />
                <Route
                  path="/admin-order/:id"
                  element={<AdminOrderDetailsPage />}
                />
                <Route path="/admin-products" element={<AdminProductsPage />} />
                <Route
                  path="/admin-add-product"
                  element={<AdminAddProductPage />}
                />
                <Route
                  path="/admin-edit-product/:id"
                  element={<AdminEditProductPage />}
                />
                <Route
                  path="/admin-customers"
                  element={<AdminCustomersList />}
                />
                <Route path="/admin-subAdmin" element={<AdminSubadminList />} />
                <Route
                  path="/admin-add-subAdmin"
                  element={<AdminAddSubAdminPage />}
                />
                <Route path="/admin-enquiry" element={<AdminEnquiryPage />} />
                <Route
                  path="/admin-product-review"
                  element={<AdminProductReviewPage />}
                />
                <Route path="/admin-gallery" element={<AdminGalleryPage />} />
                <Route
                  path="/admin-add-gallery"
                  element={<AdminAddGalleryPage />}
                />
                <Route path="/admin-press" element={<AdminPressPage />} />
                <Route path="/admin-add-news" element={<AdminAddNewsPage />} />
                <Route
                  path="/admin-edit-news/:id"
                  element={<AdminEditNewsPage />}
                />
                <Route path="/admin-settings" element={<AdminSettingsPage />} />
                <Route
                  path="/admin-settings-password"
                  element={<AdminSettingsPasswordPage />}
                />
              </>
            )}
          </Route>
          <Route path="*" element={<ErrorPage />} />
          {/* <Route
          path="/order/:id"
          element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          }
        /> */}
        </Routes>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
}

export default App;
