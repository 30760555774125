import React from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import CartDetails from '../components/Cart/CartDetails.jsx'

const CartPage = () => {
  return (
    <div>
        <Header />
        <div className="h-[11vh]"></div>
        <CartDetails />
        <Footer />
    </div>
  )
}

export default CartPage