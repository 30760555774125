import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IoBag } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import Drawer from "@mui/material/Drawer";

const MobileCartItems = () => {
  const { cart } = useSelector((state) => state.cart);

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.price,
    0
  );

  // this is shipping cost variable
  let shipping;
  if (subTotalPrice >= 500) {
    shipping = 0;
  } else {
    shipping = 50;
  }

  const totalPrice = (subTotalPrice + shipping).toFixed(2);

  return (
    <div className="800px:hidden block w-full">
      <IoBag className="text-[30px] ml-auto" onClick={openDrawer} />
      <Drawer anchor="right" open={isVisible} onClose={closeDrawer}>
        <div className="min-w-[250px] flex flex-col gap-3 px-5 py-10">
          <IoMdClose className="text-[30px]" onClick={closeDrawer} />
          {cart &&
            cart.map((i, index) => (
              <div
                key={index}
                className="flex gap-3 max-w-[560px] shadow-lg rounded-lg"
              >
                <div className="relative bg-white w-[120px] h-[120px] rounded-lg flex justify-center items-center">
                  <div className="absolute -top-2 right-0 w-[20px] h-[20px] bg-[#40890F] text-white text-[10px] flex justify-center items-center rounded-full">
                    {i.qty}
                  </div>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_URL}${i?.images[0]}`}
                    alt=""
                    className="object-contain"
                  />
                </div>
                <div className="flex flex-col mt-2">
                  <h4 className="font-Poppins font-[400] text-[18px]">
                    {i.name}
                  </h4>
                  <h4 className="font-Poppins font-[500] text-[18px] text-[#40890F]">
                    ₹{i.price}
                  </h4>
                </div>
              </div>
            ))}

          <div className="flex justify-between tems-center max-w-[560px] w-[100%]">
            <h5 className="font-Poppins font-[300] text-[18px]">Subtotal</h5>
            <h4 className="font-Poppins font-[300] text-[18px]">
              ₹{subTotalPrice}
            </h4>
          </div>
          <div className="flex justify-between tems-center max-w-[560px] w-[100%]">
            <h5 className="font-Poppins font-[300] text-[18px]">Shipping</h5>
            <h4 className="font-Poppins font-[300] text-[18px]">
              {shipping.toFixed(2)}
            </h4>
          </div>
          <div className="flex justify-between tems-center max-w-[560px] w-[100%]">
            <h4 className="font-Poppins font-[500] text-[20px]">Total</h4>
            <h4 className="font-Poppins font-[500] text-[18px]">
              ₹{totalPrice}
            </h4>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileCartItems;
