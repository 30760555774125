import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AdminProtectedRoute = ({ children }) => {
  const { isLoading, isAdmin } = useSelector((state) => state.admin);

  if (isLoading === true) {
    return <h1>Loading...</h1>;
  } else {
    return isAdmin ? <Outlet /> : <Navigate to="/admin-login" />;
  }
};

export default AdminProtectedRoute;
