import React, { useEffect, useState } from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import ProductDetails from '../components/ProductDetails/ProductDetails.jsx'
import { useParams } from 'react-router-dom'
import { products } from '../static/data'
import { useSelector } from 'react-redux'

const ProductDetailsPage = () => {
  const {allProducts} = useSelector((state) => state.products);
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const data = allProducts && allProducts.find((i) => i._id === id);
    setData(data);
  }, [data,allProducts]);
  return (
    <div>
      <Header />
      <div className="h-[11vh]"></div>
      <ProductDetails data={data} />
      <Footer />
    </div>
  );
}

export default ProductDetailsPage