import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TiDelete } from "react-icons/ti";
import { ErrorMessage, Form, Formik } from "formik";
import { GrUploadOption } from "react-icons/gr";
import { useSelector } from "react-redux";
import { FiDelete } from "react-icons/fi";
import { DataGrid } from "@mui/x-data-grid";
import { galleryFormSchema } from "../../schema/valiationSchema";
import { BiError } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const AdminAddGallery = () => {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.admin);
  const [images, setImages] = useState([]);

  const handleImageChange = (e) => {
    e.preventDefault();

    const fileInput = e.target;
    const maxFiles = 3;

    if (images.length + fileInput.files.length > maxFiles) {
      alert(`You can only select upto ${maxFiles} files!`);
      fileInput.value = "";
    } else {
      const files = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...files]);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    if (images.length > 0) {
      const newForm = new FormData();

      images.forEach((image) => {
        newForm.append("images", image);
      });
      newForm.append("title", e.title);
      newForm.append("description", e.description);
      newForm.append("adminId", admin._id);

      await axios
        .post(`${process.env.REACT_APP_SERVER}/gallery/create-gallery`, newForm, {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          navigate("/admin-gallery");
          setImages([]);
          toast.success(res.data.message);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Media is required");
    }
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              GALLERY
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <Formik
            initialValues={{
              title: "",
              description: "",
            }}
            validationSchema={galleryFormSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, errors }) => (
              <Form className="mt-[20px] flex flex-col gap-4 mb-4">
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    <span className="text-[#40890F]">*</span> Title :
                  </label>
                  <div className="flex flex-col w-[70%]">
                    <input
                      type="text"
                      name="title"
                      value={values.title}
                      className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                    />
                    <ErrorMessage name="title">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    <span className="text-[#40890F]">*</span> Description :
                  </label>
                  <div className="flex flex-col w-[70%]">
                    <textarea
                      type="text"
                      rows="4"
                      name="description"
                      value={values.description}
                      className="rounded-[8px] px-3 py-2 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                    />
                    <ErrorMessage name="description">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    <span className="text-[#40890F]">*</span> Upload Media :
                  </label>
                  <div className="flex items-center flex-wrap gap-2 bg-[#E5E5E5] rounded-[10px] px-2 py-1 w-[70%]">
                    <input
                      type="file"
                      name=""
                      id="upload"
                      className="hidden"
                      multiple
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                    <label htmlFor="upload">
                      <GrUploadOption
                        size={30}
                        className="cursor-pointer"
                        title="add product"
                      />
                    </label>
                    {images &&
                      images.map((i, index) => (
                        <div className="relative">
                          <img
                            src={URL.createObjectURL(i)}
                            alt=""
                            key={i}
                            className="h-[120px] w-[120px] m-2 object-contain"
                          />
                          <TiDelete
                            size={25}
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 cursor-pointer"
                            onClick={() => handleDeleteImage(index)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="w-full flex justify-end">
                  <input
                    type="submit"
                    value="Upload"
                    className="bg-[#40890F] text-white font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-5 py-2 cursor-pointer"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AdminAddGallery;
