import React from "react";
import styles from "../../styles/styles";

const CheckoutSteps = ({ active }) => {
  return (
    <div className="flex gap-3 max-w-[840px] w-[100%] m-auto">
      <h6
        className={`400px:block hidden font-Poppins font-[400] 800px:text-[16px] text-[14px] text-[#3C3C3C]`}
      >
        Cart
      </h6>
      <h6
        className={`${
          active === "information" ? "text-[#40890F] font-[600]" : "text-[#3C3C3C] font-[400]"
        } font-Poppins 800px:text-[16px] text-[14px]`}
      >
        Information
      </h6>
      <h6
        className={`${
          active === "shipping" ? "text-[#40890F] font-[600]" : "text-[#3C3C3C] font-[400]"
        } font-Poppins 800px:text-[16px] text-[14px]`}
      >
        Shipping
      </h6>
      <h6
        className={`${
          active === "payment" ? "text-[#40890F] font-[600]" : "text-[#3C3C3C] font-[400]"
        } font-Poppins 800px:text-[16px] text-[14px]`}
      >
        Payment
      </h6>
    </div>
  );
};

export default CheckoutSteps;
