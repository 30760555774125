import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "../../../styles/styles";
import { products } from "../../../static/data";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cart";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";

const Products = ({ heading }) => {
  const dispatch = useDispatch();
  const { allProducts } = useSelector((state) => state.products);
  const { cart } = useSelector((state) => state.cart);
  const [count, setCount] = useState(1);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 3,
    },
    tab: {
      breakpoint: { max: 750, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const addToCartHandler = (id, data) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = {
          ...data,
          price: data?.variants[0]?.price,
          qty: count,
        };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <div className="w-full bg-white py-[50px]">
      <div className={`${styles.container}`}>
        <div className="flex-col mx-5">
          {heading === false ? null : (
            <div className="w-full flex justify-center mb-5">
              <h2 className="font-Poppins font-[500] 800px:text-[40px] text-[30px]">
                Our Products
              </h2>
            </div>
          )}

          {/* <div className="w-full flex"> */}
          <Carousel
            responsive={responsive}
            infinite={true}
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            className="z-0"
            arrows
            containerClass="container"
            customLeftArrow={
              <FaAngleLeft
                size={30}
                className="absolute top-1/2 left-4 max-w-4 cursor-pointer text-primary-400"
              />
            }
            customRightArrow={
              <FaAngleRight
                size={30}
                className="absolute top-1/2 right-4 max-w-4 cursor-pointer text-primary-400"
              />
            }
            slidesToSlide={1}
            swipeable
          >
            {allProducts ? (
              allProducts.map((i, index) => (
                <div
                  className="flex flex-col items-center justify-center gap-2 m-5 hover:shadow-md w-[214px] h-[314px] mx-auto"
                  key={index}
                >
                  <Link to={`/product/${i._id}`}>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_URL}${i.images[0]}`}
                      alt=""
                      className="w-[200px] h-[136px]"
                    />
                  </Link>
                  <Link
                    to={`/product/${i._id}`}
                    className="font-Poppins font-[500] text-[15px] text-center"
                  >
                    {i.name}
                  </Link>

                  <Link
                    to={`/product/${i._id}`}
                    className="font-Poppins font-[600] text-[18px] text-center"
                  >
                    ₹
                    {Math.min.apply(
                      null,
                      i?.variants?.map((item) => item?.price)
                    )}
                  </Link>
                  <button
                    onClick={() => addToCartHandler(i._id, i)}
                    className={`${styles.button2}`}
                  >
                    Add to cart
                  </button>
                </div>
              ))
            ) : (
              <h1>No products to display</h1>
            )}
          </Carousel>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Products;
