import React, { useEffect } from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header activeHeading={5} />
        <div className="h-[11vh]"></div>
        <ContactInfo />
        <ContactForm />
        <Footer />
    </div>
  )
}

export default ContactPage