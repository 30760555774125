import * as Yup from "yup";

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const pincodeRegex = "^[1-9][0-9]{5}$";

export const franchiseFormSchema = Yup.object({
  Name: Yup.string().required("Name is required"),
  Email: Yup.string().email("Invalid email").required("Email is required"),
  Phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Number is required"),
  City: Yup.string().required("Location is required"),
  Investment: Yup.string().required("Investment is required"),
  Message: Yup.string().required("Message is required"),
});

export const contactFormSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().required("Message is required"),
});


export const addressFormSchema = Yup.object({
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  city: Yup.string().required("City is required"),
  pincode: Yup.string()
    .matches(pincodeRegex, "Pincode is not valid")
    .required("Pincode is required"),
  addressType: Yup.string().required("Address Type is required"),
});

export const profileFormSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.number().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
});

export const productFormSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  variants: Yup.array(
    Yup.object({
      size: Yup.string().required("size is requird"),
      unit: Yup.string().required("unit is requird"),
      price: Yup.string().required("price is requird"),
    })
  ).min(1, "You need to provide at least 1 raw  material"),
  stock: Yup.string().matches('^[0-9]*$', "stock is invalid").required("Stock is required"),
  description: Yup.string().required("Description is required"),
});

export const subAdminFormSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  // confirmPassword: Yup.string().required("Confirm password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const galleryFormSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

export const newsFormSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

export const adminProfileFormSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.number().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
});

export const loginFormSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const findAccFormSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const verCodeFormSchema = Yup.object({
  code: Yup.string().required("Code is required"),
});

export const SignupFormSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const adminPasswordFormSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  newPassword: Yup.string().required("New password is required"),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref("newPassword")], "Passwords must match")
  .required("Confirm Password is required"),
});

export const adminResetPasswordFormSchema = Yup.object({
  newPassword: Yup.string().required("New password is required"),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref("newPassword")], "Passwords must match")
  .required("Confirm Password is required"),
});
