import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TiDelete } from "react-icons/ti";
import { GrUploadOption } from "react-icons/gr";
import { useSelector } from "react-redux";
import { FiDelete } from "react-icons/fi";
import { DataGrid } from "@mui/x-data-grid";
import { LuSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const AdminGallery = () => {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.admin);
  const [galleryItems, setGalleryItems] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [pageSize, setPageSize] = useState(6);

  useEffect(() => {
    getGalleryItems();
    calcRows();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteBtn = (id) => {
    handleClickOpen();
    setSelectedId(id);
  };

  const getGalleryItems = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/gallery/get-all-gallery-items`,
        {
          withCredentials: true,
        }
      );
      setGalleryItems(data?.media);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = galleryItems?.filter((item) =>
      item.title.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(filteredData);
  };

  const calcRows = () => {
    if (window.innerHeight >= 1250) {
      setPageSize(10);
    } else if (window.innerHeight > 1150) {
      setPageSize(9);
    } else if (window.innerHeight > 1050) {
      setPageSize(8);
    } else if (window.innerHeight > 950) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(6);
    } else if (window.innerHeight > 750) {
      setPageSize(5);
    } else if (window.innerHeight > 660) {
      setPageSize(4);
    } else if (window.innerHeight > 600) {
      setPageSize(3);
    } else if (window.innerHeight > 550) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  const handleDelete = async () => {
    handleClose();

    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("deleteGallery")
    ) {
      await axios
        .delete(`${process.env.REACT_APP_SERVER}/gallery/delete-gallery-item/${selectedId}`, {
          withCredentials: true,
        })
        .then((res) => {
          toast.success(res.data.message);
          getGalleryItems();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Unauthorized");
    }
  };

  const handleAddBtn = () => {
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("createGallery")
    ) {
      navigate("/admin-add-gallery");
    } else {
      toast.error("Unauthorized");
    }
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "Id",
    //   flex: 0.8,
    //   align: "left",
    //   headerAlign: "left",
    //   headerClassName: "products-grid-header",
    //   cellClassName: "products-grid-cell",
    // },
    {
      field: "sno",
      flex: 0.5,
      minWidth: 50,
      headerName: "S.No",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <h1>{params.value}</h1>
          </>
        );
      },
    },
    {
      field: "image",
      flex: 0.8,
      minWidth: 140,
      headerName: "Media",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="h-[69px] w-[100px] flex items-center">
              <img
                // src={`${process.env.REACT_APP_BACKEND_URL}${params.value}`}
                src={`${process.env.REACT_APP_BUCKET_URL}${params.value}`}
                alt=""
                className="object-contain"
              />
            </div>
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
    },
    {
      field: " ",
      headerName: "Delete",
      flex: 1,
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      renderCell: (params) => {
        return (
          <div className="flex gap-2">
            <FiDelete
              size={20}
              onClick={() => handleDeleteBtn(params.id)}
              className="cursor-pointer text-[red]"
              title="Delete product"
            />
          </div>
        );
      },
    },
  ];

  const row = [];

  if (searchData) {
    searchData &&
    searchData.forEach((item, index) => {
      row.push({
        id: item._id,
        sno: index + 1,
        image: item.images[0],
        title: item.title,
        description: item.description,
      });
    });
  } else {
    galleryItems &&
    galleryItems.forEach((item, index) => {
      row.push({
        id: item._id,
        sno: index + 1,
        image: item.images[0],
        title: item.title,
        description: item.description,
      });
    });
  }

  const getRowHeight = (params) => {
    return 70;
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0
      ? "products-grid-row-even"
      : "products-grid-row-odd";
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              GALLERY
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] mb-[20px]">
            <div className="flex">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-[218px] h-[38px] bg-[#fff] flex items-center px-3 rounded-l-[8px]"
                placeholder="search with title"
              ></input>
              <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
                <LuSearch size={25} className="text-white" />
              </div>
            </div>
            <div className="flex items-center gap-4">
              {/* <h4 className="font-[Montserrat] font-[500] text-[14px] cursor-pointer">
                Export
              </h4> */}
              {/* <Link to="/admin-add-product"> */}
              <div onClick={handleAddBtn}>
                <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                  Add Gallery Item
                </button>
              </div>
              {/* </Link> */}
            </div>
          </div>
          {isLoading ? (
            <h1>Loading</h1>
          ) : galleryItems?.length === 0 || searchData?.length === 0 ? (
            <h1>No data found!</h1>
          ) : (
            <div className={``}>
              <DataGrid
                rows={row}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: pageSize, page: 0 },
                  },
                }}
                getRowHeight={getRowHeight}
                getRowClassName={getRowClassName}
                disableSelectionOnClick
                isRowSelectable={() => false}
              />
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Please confirm to delete the item?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleDelete} autoFocus>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminGallery;
