import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminEditNews from '../../components/Admin/AdminEditNews.jsx'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const AdminEditNewsPage = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);

    const getNews = async () => {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/press/get-all-news`, {
            withCredentials: true,
          });
          const filteredData = data && data?.news?.find((i) => i._id === id);
          setData(filteredData);
        } catch (error) {
          console.log(error);
        }
      };
    
      useEffect(() => {
        getNews();
      }, []);

  return (
    <div className='flex'>
        <AdminSidebar activeHeading={9} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminEditNews id={id} data={data} />
        </div>
    </div>
  )
}

export default AdminEditNewsPage 