import React from "react";
import Logo from "../../../assets/logoW.png";
import { FcManager } from "react-icons/fc";
import AdminNavbar from "./AdminNavbar.jsx";
import { useSelector } from "react-redux";

const AdminSidebar = ({ activeHeading }) => {
  const { admin } = useSelector((state) => state.admin);

  return (
    <div className="h-screen fixed w-[20vw] overflow-y-auto bg-[#3C3C3C] disableScrollBar">
      {/* Logo */}
      <div className="w-full flex justify-center mt-[20px]">
        <img src={Logo} alt="" className="1000px:w-[185px] px-2 1000px:px-0 1000px:h-[50.45px] 1000px:object-cover object-fit" />
      </div>
      <div className="w-full flex gap-1 justify-center mt-[20px]">
        <div className="bg-blue-500 w-[59px] h-[59px] flex justify-center items-center rounded-full">
          <img
            src={admin?.avatar}
            alt=""
            className="object-contain rounded-full"
          />
        </div>
        <div className="flex flex-col justify-center">
          <h3 className="1000px:block hidden font-[Montserrat] font-[500] text-[12px] text-white">
            {admin.role}
          </h3>
          <h4 className="1000px:block hidden font-[Montserrat] font-[400] text-[10px] text-white -mt-1">
            {admin.name}
          </h4>
        </div>
      </div>
      {/* nav items */}
      <AdminNavbar active={activeHeading} />
    </div>
  );
};

export default AdminSidebar;
