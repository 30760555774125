import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminEditProduct from '../../components/Admin/AdminEditProduct.jsx'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const AdminEditProductPage = () => {
    const { products } = useSelector((state) => state.products);
    const { id } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        const data = products && products.find((i) => i._id === id);
        setData(data);
    }, [data,products]);
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={2} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminEditProduct id={id} data={data} />
        </div>
    </div>
  )
}

export default AdminEditProductPage 