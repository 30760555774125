import React from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar.jsx'
import AdminHeader from '../../components/Admin/Layout/AdminHeader.jsx'
import AdminDashboard from '../../components/Admin/AdminDashboard.jsx'

const AdminDashboardPage = () => {
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={1} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminDashboard />
        </div>
    </div>
  )
}

export default AdminDashboardPage