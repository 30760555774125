import React, { useState } from 'react'
import styles from '../../styles/styles'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ErrorMessage, Form, Formik } from "formik";
import { loginFormSchema } from "../../schema/valiationSchema";
import { toast } from 'react-toastify';
import { loadUser } from '../../redux/actions/user';
import { useDispatch } from 'react-redux';

const Login = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e, i) => {
      // e.preventDefault();
      const newForm = new FormData();

      newForm.append("email", e.email);
      newForm.append("password", e.password);

      const config = {headers: {"Content-Type":"application/json"}, withCredentials: true}
  
      await axios
        .post(
          `${process.env.REACT_APP_SERVER}/user/login-user`,
          newForm,
          config
        )
        .then((res) => {
          i.resetForm();
          toast.success("Login success!");
          navigate(-1);
          dispatch(loadUser());
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={loginFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
      <Form>
      <div
        className={`${styles.container} absolute left-0 right-0 top-[20vh] flex items-center justify-center z-20`}
      >
        <div className="w-[783px] h-[460px] shadow-2xl bg-[#FFFFFFB0] flex gap-3 flex-col justify-center items-center">
          <h3 className="font-Poppins font-[500] text-[30px] text-black">
            Login
          </h3>
          <h6 className="font-Poppins font-[400] 800px:text-[18px] text-[15px] text-black text-center">
            Please login using account detail bellow.
          </h6>
          <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] 800px:mt-10 mt-5">
            <label
              htmlFor="email"
              className="w-[80px] font-Poppins font-[400] text-[16px] text-black "
            >
              Email
            </label>
            <div className='flex flex-col gap-1 400px:w-[300px] w-[260px]'>
              <input
                type="email"
                name="email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
              />  
              <ErrorMessage name="email">
                {(msg) => (
                  <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                  * {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
          </div>
          <div className="flex flex-col 800px:flex-row gap-5 items-center 400px:w-[380px] w-[280px] relative">
            <label
              htmlFor="password"
              className="w-[80px] font-Poppins font-[400] text-[16px] text-black"
            >
              Password
            </label>
            <div className='flex flex-col gap-1 400px:w-[300px] w-[260px]'>
              <input
                type={visible ? "type" : "password"}
                name="password"
                autoComplete="current-password"
                value={values.password}
                onChange={handleChange}
                className="w-full h-[53px] bg-transparent focus:bg-white px-3 border-[1.5px] border-[#3C3C3C]"
              />
              
              {visible ? (
                <AiOutlineEye
                  className="absolute 800px:right-2 800px:top-4 400px:right-[50px] right-[20px] top-14 cursor-pointer"
                  size={20}
                  onClick={() => setVisible(false)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="absolute 800px:right-2 800px:top-4 400px:right-[50px] right-[20px] top-14 cursor-pointer"
                  size={20}
                  onClick={() => setVisible(true)}
                />
              )}
              <ErrorMessage name="password">
                  {(msg) => (
                    <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red] self-start">
                      * {msg}
                    </div>
                  )}
                </ErrorMessage>
            </div>
          </div>
          <div className="flex">
            <h4>Not have any account?</h4>
            <Link to="/signup" className="text-black underline pl-2">
              Sign Up
            </Link>
          </div>
          <button type="submit" className={`${styles.button2}`}>
            Submit
          </button>
        </div>
      </div>
      </Form>
    )}
    </Formik>
  );
}

export default Login
