import React from 'react'
import Banner from '../../assets/footer.png'
import Logo from "../../assets/logo.png";
import { FaFacebookF, FaGooglePlusG } from 'react-icons/fa';
import { GrInstagram } from 'react-icons/gr';
import styles from '../../styles/styles'
import { Link } from 'react-router-dom';

const Footer = ({ page }) => {
  return (
    <div
      className={`${
        page === "register" ? "h-[89vh]" : "h-[65vh]"
      } w-full relative z-10`}
    >
      <img src={Banner} alt="" className="w-[100%] h-[100%] object-cover" />
      <div
        className={`${styles.container} ${page === "register" ? "800px:block hidden" : "800px:block flex flex-col"} absolute left-[50%] transform -translate-x-1/2 bottom-[20px] !py-0 800px:h-auto h-[90%]`}
      >
        <div className="flex flex-col 800px:flex-row 800px:justify-between items-center pb-1">
          <Link to='/'>
            <img src={Logo} alt="" className="w-[280px] h-[64px] hidden 800px:block" />
          </Link>
          <ul className="800px:flex hidden 800px:flex-row flex-col 800px:gap-5 800px:self-end items-center">
            <Link to='/'>
              <li className="">Home</li>
            </Link>
            <Link to='/about-us'>
              <li className="">About Us</li>
            </Link>
            <Link to='/shop'>
              <li className="">Product</li>
            </Link>
            <Link to='/franchise-info'>
              <li className="">Franchise Info</li>
            </Link>
            <Link to='/contact'>
              <li className="">Contact</li>
            </Link>
          </ul>
        </div>
        <div className='flex 800px:hidden flex-1'>
          <Link to='/'>
              <img src={Logo} alt="" className="w-[280px] h-[64px]" />
            </Link>
        </div>
        <div className='flex 800px:hidden flex-1'>
          <ul className="flex-1">
            <Link to='/'>
              <li className="font-Poppins font-normal text-[14px]">Home</li>
            </Link>
            <Link to='/about-us'>
              <li className="font-Poppins font-normal text-[14px]">About Us</li>
            </Link>
            <Link to='/shop'>
              <li className="font-Poppins font-normal text-[14px]">Product</li>
            </Link>
            <Link to='/franchise-info'>
              <li className="font-Poppins font-normal text-[14px]">Franchise Info</li>
            </Link>
          </ul>
          <ul className="flex-1">
            <Link to='/contact'>
              <li className="font-Poppins font-normal text-[14px]">Contact</li>
            </Link>
            <Link to='/gallery'>
              <li className="font-Poppins font-normal text-[14px]">Gallery</li>
            </Link>
            <Link to='/press'>
              <li className="font-Poppins font-normal text-[14px]">Press</li>
            </Link>
            <Link to='/blog'>
              <li className="font-Poppins font-normal text-[14px]">Blog</li>
            </Link>
          </ul>
        </div>
        <div className='flex 800px:hidden flex-col flex-1'>
          <h3 className='font-Poppins font-semibold'>Social</h3>
          <div className="flex gap-2">
            <Link to='https://www.facebook.com' className='w-[26px] h-[26px] rounded-full flex justify-center items-center bg-white'>
              <FaFacebookF size={18} />
            </Link>
            <Link to='https://www.instagram.com' className='w-[27px] h-[27px] rounded-full flex justify-center items-center bg-white'>
              <GrInstagram size={18} />
            </Link>
            <Link to='https://www.gmail.com' className='w-[26px] h-[26px] rounded-full flex justify-center items-center bg-white'>
              <FaGooglePlusG size={20} />
            </Link>
          </div>
        </div>
        <hr className="border-black mt-auto" />
        <div className="flex flex-col-reverse 800px:flex-row 800px:justify-between pt-1">
          <p className="self-center font-Poppins font-normal text-[14px]">copyright Ⓒ by the chai walah</p>

          <div className="800px:flex hidden gap-2 self-center ">
            <Link to='https://www.facebook.com'>
              <FaFacebookF size={18} />
            </Link>
            <Link to='https://instagram.com/thechaiwalah.in?igshid=NzZlODBkYWE4Ng=='>
              <GrInstagram size={18} />
            </Link>
            <Link to='https://www.gmail.com'>
              <FaGooglePlusG size={20} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer
