import React, { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  getAllOrdersOfAdmin,
  getAllOrdersOfUser,
} from "../../redux/actions/order";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { FiDelete } from "react-icons/fi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const AdminEnquiryList = () => {
  const [enquiries, setEnquiries] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getEnquiries = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/enquirie/get-all-enquiries`, {
        withCredentials: true,
      });
      setEnquiries(data?.enquiries);
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEnquiries();
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredEnquirie = enquiries?.filter((enquirie) =>
      enquirie?.Name?.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(filteredEnquirie);
  };
  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              ENQUIRIES
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] mb-[20px]">
            <div className="flex">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-[218px] h-[38px] bg-[#fff] flex items-center px-3 rounded-l-[8px]"
                placeholder="search with name"
              ></input>
              <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
                <LuSearch size={25} className="text-white" />
              </div>
            </div>
            {/* <div className="flex items-center gap-4">
              <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                Download All Enquiries
              </button>
            </div> */}
          </div>
          <div>
            {isLoading === true || enquiries ? (
              <EnquirieList
                enquiries={enquiries}
                setEnquiries={setEnquiries}
                searchData={searchData}
                isLoading={isLoading}
              />
            ) : (
              <h1>Unauthorized</h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const EnquirieList = ({ enquiries, setEnquiries, searchData, isLoading }) => {
  const { admin } = useSelector((state) => state.admin);
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteBtn = (id) => {
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("deleteEnquiry")
    ) {
      handleClickOpen();
      setSelectedId(id);
    } else {
      toast.error("Unauthorized");
    }
    
  };

  const handleDelete = async () => {
    handleClose();

    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_SERVER}/enquirie/delete-enquirie/${selectedId}`,
        {
          withCredentials: true,
        }
      ).then((res) => {
        toast.success(res.data.message);
        setEnquiries(res.data.enquiries)
      }).catch((error) => {
        toast.error(error?.response?.data?.message);
      })
    } catch (error) {
      console.log(error);
    }
  };

  const [pageSize, setPageSize] = useState(6);

  const calcRows = () => {
    if (window.innerHeight >= 1250) {
      setPageSize(10);
    } else if (window.innerHeight > 1150) {
      setPageSize(9);
    } else if (window.innerHeight > 1050) {
      setPageSize(8);
    } else if (window.innerHeight > 950) {
      setPageSize(7);
    } else if (window.innerHeight > 850) {
      setPageSize(6);
    } else if (window.innerHeight > 750) {
      setPageSize(5);
    } else if (window.innerHeight > 660) {
      setPageSize(4);
    } else if (window.innerHeight > 600) {
      setPageSize(3);
    } else if (window.innerHeight > 550) {
      setPageSize(2);
    } else {
      setPageSize(1);
    }
  };

  useEffect(() => {
    calcRows();
  }, []);

  const columns = [
    // { field: "id", headerName: "ID", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "message",
      headerName: "Message",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "investment",
      headerName: "Investment",
      minWidth: 130,
      flex: 1,
      headerClassName: "products-grid-header",
      cellClassName: "products-grid-cell",
      align: "center",
      headerAlign: "center",
    },
    {
      field: " ",
      headerName: "Delete",
      flex: 1,
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "products-grid-header",
      renderCell: (params) => {
        return (
          <div className="flex gap-2">
            <FiDelete
              size={20}
              onClick={() => handleDeleteBtn(params.id)}
              className="cursor-pointer text-[red]"
              title="Delete product"
            />
          </div>
        );
      },
    },
  ];

  const row = [];

  if (searchData) {
    searchData &&
      searchData.forEach((item) => {
        row.push({
          id: item._id,
          name: item.Name,
          email: item.Email,
          phone: item.Phone,
          location: item.City,
          message: item.Message,
          investment: "₹ " + item.Investment,
        });
      });
  } else {
    enquiries &&
      enquiries.forEach((item) => {
        row.push({
          id: item._id,
          name: item.Name,
          email: item.Email,
          phone: item.Phone,
          location: item.City,
          message: item.Message,
          investment: "₹ " + item.Investment,
        });
      });
  }

  const getRowHeight = (params) => {
    return 70;
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0
      ? "products-grid-row-even"
      : "products-grid-row-odd";
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-end border-b">
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : enquiries?.length === 0 || searchData?.length === 0 ? (
        <h1>No data found!</h1>
      ) : (
        <div className={``}>
          <DataGrid
            rows={row}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: pageSize, page: 0 },
              },
            }}
            getRowHeight={getRowHeight}
            getRowClassName={getRowClassName}
            disableSelectionOnClick
            isRowSelectable={() => false}
            slots={{ toolbar: CustomToolbar }}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Please confirm to delete the enquirie?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDelete} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default AdminEnquiryList;
