import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ShippingMethod = () => {
  const { user } = useSelector((state) => state.user);

  const [orderData, setOrderData] = useState([]);

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);

  return (
    <div className="flex flex-col gap-5 mt-16 max-w-[840px] w-[100%] m-auto">
      <div className="w-full border-[1.5px] border-[#3C3C3C80] flex flex-col gap-3 px-5 py-4">
        <div className="flex w-full">
          <h4 className="w-[70px] font-Poppins font-[400] text-[14px] text-[#3C3C3C80]">
            Contact
          </h4>
          <h4 className="flex-1 font-Poppins font-[400] text-[14px] text-[#3C3C3C] truncate">
            {user.email}
          </h4>
        </div>
        <hr className="border-[#3C3C3C80]" />
        <div className="flex w-full">
          <h4 className="w-[70px] font-Poppins font-[400] text-[14px] text-[#3C3C3C80]">
            Ship to
          </h4>
          <h4 className="flex-1 font-Poppins font-[400] text-[14px] text-[#3C3C3C] truncate">
            {orderData?.shippingAddress?.state}, {orderData?.shippingAddress?.city}
          </h4>
        </div>
      </div>
      <h3 className="font-Poppins font-[500] text-[25px]">Shipping charge</h3>
      <div className="w-full border-[1.5px] border-[#3C3C3C80] flex justify-between px-5 py-4">
        <h4 className="font-Poppins font-[400] text-[14px] text-[#3C3C3C]">
          Standard Shipping
        </h4>
        <h4 className="font-Poppins font-[400] text-[14px] text-[#3C3C3C]">
          ₹{orderData?.shipping}
        </h4>
      </div>
      <div className="flex justify-between items-center gap-3 flex-wrap">
        <Link to="/checkout">
          <div className="flex gap-2">
            <AiOutlineLeft
              size={20}
              className="text-[#40890F] cursor-pointer"
            />
            <h5 className="font-Poppins font-[300] text-[14px] text-[#40890F] cursor-pointer">
              Return to information
            </h5>
          </div>
        </Link>
        <Link to="/payment">
          <button className="text-[#fff] bg-[#3C3C3C] border-[1.5px] border-[#3C3C3C] rounded-lg w-[218px] h-[36px] font-Poppins font-[12px] cursor-pointer">
            Continue to payment
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ShippingMethod;
