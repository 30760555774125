import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { GiCash, GiReceiveMoney } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import { getAllUsers } from "../../redux/actions/user";
import { RiNewspaperLine } from "react-icons/ri";
import { BsHandbagFill } from "react-icons/bs";
import { BiSolidUserCircle } from "react-icons/bi";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { MdShoppingCart } from "react-icons/md";
import { FaCartArrowDown } from "react-icons/fa";
import { HiCreditCard } from "react-icons/hi";
import axios from "axios";
import Chart from 'react-apexcharts'

const AdminDashboard = () => {
  const { allProducts } = useSelector((state) => state.products);
  const { adminOrders } = useSelector((state) => state.order);
  const { allUsers } = useSelector((state) => state.user);
  const { admin } = useSelector((state) => state.admin);
  const [totalSales, setTotalSales] = useState("")
  const [salesPercentage, setSalesPercentage] = useState(null)
  const [salesFilter, setSalesFilter] = useState("day")
  const [totalCredit, setTotalCredit] = useState("")
  const [creditPercentage, setCreditPercentage] = useState(null)
  const [creditFilter, setCreditFilter] = useState("day")
  const [totalCod, setTotalCod] = useState("")
  const [codPercentage, setCodPercentage] = useState(null)
  const [codFilter, setCodFilter] = useState("day")
  const [reveneuFilter, setReveneuFilter] = useState("year")
  const [totalReveneu, setTotalReveneu] = useState("")
  const [totalReveneuSeries, setTotalReveneuSeries] = useState([])
  const [totalSalesSeries, setTotalSalesSeries] = useState([])
  const [totalTransactionSeries, setTotalTransactionSeries] = useState([])
  const [totalCodSeries, setTotalCodSeries] = useState([])
  const [readDashboard, setReadDashboard] = useState(false);

  const dispatch = useDispatch();

  const sortedProducts =
    allProducts && [...allProducts].sort((a, b) => b.sold_out - a.sold_out);
  const topRatedProducts = sortedProducts?.slice(0, 3);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllUsers());
  }, []);

  const sortedOrders =
    adminOrders && [...adminOrders]?.sort((a, b) => b.createdAt - a.createdAt);
  const latest4Orders = sortedOrders?.slice(0, 4);

  const getTotalSales = async (salesFilter) => {
    let startDate;
    let compareDate;
    let today = new Date();

    if(salesFilter === 'day'){
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today);
      compareDate = new Date(startDate);
      compareDate.setDate(compareDate.getDate() - 1)
    } else if (salesFilter === 'week') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.setDate(today.getDate() - 6));
      compareDate = new Date(startDate);
      compareDate.setDate(compareDate.getUTCDate() - 6);
    } else if (salesFilter === 'month') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      compareDate = new Date(startDate);
      compareDate.setUTCFullYear(compareDate.getUTCFullYear(), compareDate.getUTCMonth() - 1, 1);
    } else {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), 0, 1);
      compareDate = new Date(startDate);
      compareDate.setUTCFullYear(compareDate.getUTCFullYear() - 1, 0, 1);
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-sales?salesFilter=${salesFilter}&endDate=${new Date()}&startDate=${startDate}&compareDate=${compareDate}`, {
        withCredentials: true,
      });
      setTotalSales(data?.sumTotalSales);
      setSalesPercentage(data?.percentageDifference)
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const getTotalCredit = async (type) => {
    let startDate;
    let compareDate;
    let today = new Date();

    if(type === 'day'){
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today);
      compareDate = new Date(startDate);
      compareDate.setDate(compareDate.getDate() - 1)
    } else if (type === 'week') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.setDate(today.getDate() - 6));
      compareDate = new Date(startDate);
      compareDate.setDate(compareDate.getUTCDate() - 6);
    } else if (type === 'month') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      compareDate = new Date(startDate);
      compareDate.setUTCFullYear(compareDate.getUTCFullYear(), compareDate.getUTCMonth() - 1, 1);
    } else {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), 0, 1);
      compareDate = new Date(startDate);
      compareDate.setUTCFullYear(compareDate.getUTCFullYear() - 1, 0, 1);
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-credit?type=${type}&endDate=${new Date()}&startDate=${startDate}`, {
        withCredentials: true,
      });
      setTotalCredit(data?.sumTotalCredit);
      setCreditPercentage(data?.percentageDifference)
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const getTotalCod = async (type) => {
    let startDate;
    let compareDate;
    let today = new Date();

    if(type === 'day'){
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today);
      compareDate = new Date(startDate);
      compareDate.setDate(compareDate.getDate() - 1)
    } else if (type === 'week') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.setDate(today.getDate() - 6));
      compareDate = new Date(startDate);
      compareDate.setDate(compareDate.getUTCDate() - 6);
    } else if (type === 'month') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      compareDate = new Date(startDate);
      compareDate.setUTCFullYear(compareDate.getUTCFullYear(), compareDate.getUTCMonth() - 1, 1);
    } else {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), 0, 1);
      compareDate = new Date(startDate);
      compareDate.setUTCFullYear(compareDate.getUTCFullYear() - 1, 0, 1);
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-cod?type=${type}&endDate=${new Date()}&startDate=${startDate}`, {
        withCredentials: true,
      });
      setTotalCod(data?.sumTotalCod);
      setCodPercentage(data?.percentageDifference)
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const getTotalReveneu = async (reveneuFilter) => {
    let startDate;
    let today = new Date();

    if(reveneuFilter === 'day'){
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today);
    } else if (reveneuFilter === 'week') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.setDate(today.getDate() - 6));
    } else if (reveneuFilter === 'month') {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    } else {
      today.setHours(0, 0, 0, 0);
      startDate = new Date(today.getFullYear(), 0, 1);
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-revenue?reveneuFilter=${reveneuFilter}&endDate=${new Date()}&startDate=${startDate}`, {
        withCredentials: true,
      });
      setTotalReveneu(data?.sumTotalRevnue);
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const getGraphData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-revenue-graph-data?endDate=${new Date()}`, {
        withCredentials: true,
      });
      setTotalReveneuSeries(data?.results)
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  }

  const getSalesGraphData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-sales-graph-data?endDate=${new Date()}`, {
        withCredentials: true,
      });
      setTotalSalesSeries(data?.results)
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  }

  const getTransactionGraphData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-transaction-graph-data?endDate=${new Date()}`, {
        withCredentials: true,
      });
      setTotalTransactionSeries(data?.results)
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  }

  const getCodGraphData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/order/get-total-cod-graph-data?endDate=${new Date()}`, {
        withCredentials: true,
      });
      setTotalCodSeries(data?.results)
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  }
  
  useEffect(() => {
    getCodGraphData()
    getTransactionGraphData()
    getSalesGraphData();
    getGraphData();
  }, []);

  useEffect(() => {
    getTotalReveneu(reveneuFilter);
  }, [reveneuFilter]);

  useEffect(() => {
    getTotalSales(salesFilter);
  }, [salesFilter]);

  useEffect(() => {
    getTotalCredit(creditFilter);
  }, [creditFilter]);

  useEffect(() => {
    getTotalCod(codFilter);
  }, [codFilter]);

  var spark1 = {
    chart: {
      id: 'sparkline1',
      type: 'line',
      height: 140,
      sparkline: {
        enabled: true
      },
      group: 'sparklines'
    },
    series: [{
      name: 'purple',
      data: [25, 66, 41, 59, 25, 44, 12, 36, 9, 21]
    }],
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'right'
      },
      x: {
        show: false
      }
    },
    colors: ['#734CEA']
    // title: {
    //   text: '439',
    //   style: {
    //     fontSize: '26px'
    //   }
    // },
  }

  var spark2 = {
    chart: {
      id: 'sparkline2',
      type: 'line',
      height: 140,
      sparkline: {
        enabled: true
      },
      group: 'sparklines'
    },
    series: [{
      name: 'green',
      data: [12, 14, 2, 47, 32, 44, 14, 55, 41, 69]
    }],
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'right'
      },
      x: {
        show: false
      }
    },
    colors: ['#34bfa3']
    // title: {
    //   text: '387',
    //   style: {
    //     fontSize: '26px'
    //   }
    // },
  }

  var spark3 = {
    chart: {
      id: 'sparkline3',
      type: 'line',
      height: 140,
      sparkline: {
        enabled: true
      },
      group: 'sparklines'
    },
    series: [{
      name: 'red',
      data: [47, 45, 74, 32, 56, 31, 44, 33, 45, 19]
    }],
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'right'
      },
      x: {
        show: false
      }
    },
    colors: ['#f4516c'],
    // title: {
    //   text: '577',
    //   style: {
    //     fontSize: '26px'
    //   }
    // },
  }

  var optionsLine = {
    series: [{
      name: "Desktops",
      data: [40, 120, 200, 105, 10, 120, 90, 34, 65, 18, 17, 9]
  }],
    chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  title: {
    text: 'Total Reveneu',
    align: 'left'
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
  }
  };

  useEffect(() => {
    if(admin?.permissions.includes("readDashboard") || admin?.role === "Master Admin"){
      setReadDashboard(true)
    }
  }, [admin])

  return (
    <div className="w-[80vw] mt-[11vh] h-[89vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-auto px-[20px] py-[20px]`}>
        {readDashboard ?
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              DASHBOARD
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex gap-5 items-center justify-between mt-[20px] overflow-x-auto disableScrollBar">
            <div className="bg-white p-5 min-w-[260px] rounded-[10px]">
              <div className="hidden justify-end">
                <select
                  className="h-[35px] w-max rounded-[8px] px-1 border border-gray-300 focus:outline-none bg-[#40890F] text-white"
                  value={reveneuFilter}
                  onChange={(e) => setReveneuFilter(e.target.value)}
                >
                  <option value="day" className="bg-[#F2F2F2] text-black">Day</option>
                  <option value="week" className="bg-[#F2F2F2] text-black">Week</option>
                  <option value="month" className="bg-[#F2F2F2] text-black">Month</option>
                  <option value="year" className="bg-[#F2F2F2] text-black">Year</option>
                </select>
              </div>
              <div className="flex gap-1">
                <div className="w-[75px] h-[75px] rounded-full bg-[#40890F] flex justify-center items-center">
                  <GiCash size={35} className="text-[#fff]" />
                </div>
                <div className="flex flex-col justify-center">
                  <h3 className="font-[Montserrat] font-[500] text-[14px]">
                    Total Revenue
                  </h3>
                  <h3 className="font-[Montserrat] font-[600] text-[22px]">
                    {/* ₹ {admin?.availableBalance?.toFixed(2)} {totalReveneu} */}
                    ₹ {totalReveneu}
                  </h3>
                </div>
              </div>
            </div>
            <div className="bg-white flex gap-1 p-5 min-w-[260px] rounded-[10px]">
              <div className="w-[75px] h-[75px] rounded-full bg-[#40890F] flex justify-center items-center">
                <RiNewspaperLine size={35} className="text-[#fff]" />
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="font-[Montserrat] font-[500] text-[14px]">
                  Total Orders
                </h3>
                <h3 className="font-[Montserrat] font-[600] text-[22px]">
                  {adminOrders?.length}
                </h3>
              </div>
            </div>
            <div className="bg-white flex gap-1 p-5 min-w-[260px] rounded-[10px]">
              <div className="w-[75px] h-[75px] rounded-full bg-[#40890F] flex justify-center items-center">
                <BsHandbagFill size={35} className="text-[#fff]" />
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="font-[Montserrat] font-[500] text-[14px]">
                  Total Products
                </h3>
                <h3 className="font-[Montserrat] font-[600] text-[22px]">
                  {allProducts?.length}
                </h3>
              </div>
            </div>
            <div className="bg-white flex gap-1 p-5 min-w-[260px] rounded-[10px]">
              <div className="w-[75px] h-[75px] rounded-full bg-[#40890F] flex justify-center items-center">
                <BiSolidUserCircle size={35} className="text-[#fff]" />
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="font-[Montserrat] font-[500] text-[14px]">
                  Total Customers
                </h3>
                <h3 className="font-[Montserrat] font-[600] text-[22px]">
                  {allUsers?.length}
                </h3>
              </div>
            </div>
          </div>
          <div className="flex flex-col 1000px:flex-row gap-[20px] w-full mt-[20px]">
            <div className="bg-white flex flex-col gap-1 p-5 1000px:w-[60%] w-full h-full rounded-[10px]">
              {/* <h3 className="font-[Montserrat] font-[500] text-[14px]">
                Total Revenue
              </h3> */}
              <Chart options={optionsLine} series={totalReveneuSeries} height={220} />
            </div>
            <div className="bg-white flex-1 flex flex-col gap-1 p-5 1000px:w-max w-full rounded-[10px]">
              <h3 className="font-[Montserrat] font-[500] text-[14px]">
                Best Selling Product
              </h3>
              <table className="border-[#3C3C3C80] border-t">
                {topRatedProducts &&
                  topRatedProducts?.map((item, index) => (
                    <tr className="flex justify-between items-center border-[#3C3C3C80] border-b py-2">
                      <td className="font-[Montserrat] font-[500] text-[18px] w-[15%]">
                        <img
                          src={`${process.env.REACT_APP_BUCKET_URL}${item.images[0]}`}
                          alt=""
                          className="object-contain h-[45px] w-[65px]"
                        />
                      </td>
                      <td className="w-[25%]">
                        <h3 className="font-[Montserrat] font-[500] text-[16px] whitespace-nowrap overflow-hidden text-ellipsis">
                          {item.name}
                        </h3>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[15%]">
                        <div className="flex flex-col">
                          <h3 className="font-[Montserrat] font-[400] text-[9px]">
                            Price
                          </h3>
                          {Math.min.apply(
                            null,
                            item?.variants?.map((item) => item?.price)
                          )}
                        </div>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[15%]">
                        <div className="flex flex-col">
                          <h3 className="font-[Montserrat] font-[400] text-[9px]">
                            Orders
                          </h3>
                          {item.sold_out}
                        </div>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[15%]">
                        <div className="flex flex-col">
                          <h3 className="font-[Montserrat] font-[400] text-[9px]">
                            Stock
                          </h3>
                          {item.stock}
                        </div>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[15%]">
                        <div className="flex flex-col">
                          <h3 className="font-[Montserrat] font-[400] text-[9px]">
                            Amount
                          </h3>
                          ₹
                          {Math.min.apply(
                            null,
                            item?.variants?.map((item) => item?.price)
                          ) * item.sold_out}
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
          <div className="flex 1000px:flex-row flex-col gap-5 justify-between mt-[20px]">
            <div className="bg-white flex flex-col gap-1 p-5 flex-1 rounded-[10px]">
              <div className="flex justify-between items-center">
                <h3 className="font-[Montserrat] font-[500] text-[14px]">
                  Total Sales
                </h3>
                <select
                  className="h-[35px] w-max rounded-[8px] px-1 border border-gray-300 focus:outline-none bg-[#40890F] text-white"
                  value={salesFilter}
                  onChange={(e) => setSalesFilter(e.target.value)}
                >
                  <option value="day" className="bg-[#F2F2F2] text-black">Day</option>
                  <option value="week" className="bg-[#F2F2F2] text-black">Week</option>
                  <option value="month" className="bg-[#F2F2F2] text-black">Month</option>
                  <option value="year" className="bg-[#F2F2F2] text-black">Year</option>
                </select>
              </div>
              <div className="h-[140px] flex justify-between items-center">
                <Chart options={spark1} series={totalSalesSeries} height={130} />
                <h1 className={`font-[Montserrat] font-[600] text-[19px] ${salesPercentage >= 0 ? 'text-[#40890F]' : 'text-[#FF6254]'}`}>
                  {salesPercentage?.toFixed(0)}%
                </h1>           
              </div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-1">
                  <h3 className="font-[Montserrat] font-[500] text-[14px]">
                    Sales last month
                  </h3>
                  <h1 className="font-[Montserrat] font-[600] text-[34px]">
                    {totalSales}
                  </h1>
                  <div className="flex gap-3 items-center">
                    <h6 className="font-[Montserrat] font-[500] text-[12px] text-[#3C3C3C80]">
                      0.25%
                    </h6>
                    <IoChevronUpOutline
                      size={20}
                      className="text-[#3C3C3C80]"
                    />
                    {/* <IoChevronDownOutline size={20} className='text-[#3C3C3C80]' /> */}
                  </div>
                </div>
                <div className="w-[63px] h-[63px] bg-[#40890F] rounded-[5px] flex justify-center items-center">
                  <FaCartArrowDown size={30} className="text-white" />
                </div>
              </div>
              <h3 className="font-[Montserrat] font-[600] text-[14px]">
                Gross sales of August
              </h3>
              <h5 className="font-[Montserrat] font-[500] text-[12px] text-[#3C3C3C80]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </h5>
            </div>

            <div className="bg-white flex flex-col gap-1 p-5 flex-1 rounded-[10px]">
              <div className="flex justify-between items-center">
                <h3 className="font-[Montserrat] font-[500] text-[14px]">
                  Total Card Transaction
                </h3>
                <select
                  className="h-[35px] w-max rounded-[8px] px-1 border border-gray-300 focus:outline-none bg-[#40890F] text-white"
                  value={creditFilter}
                  onChange={(e) => setCreditFilter(e.target.value)}
                >
                  <option value="day" className="bg-[#F2F2F2] text-black">Day</option>
                  <option value="week" className="bg-[#F2F2F2] text-black">Week</option>
                  <option value="month" className="bg-[#F2F2F2] text-black">Month</option>
                  <option value="year" className="bg-[#F2F2F2] text-black">Year</option>
                </select>
              </div>
              <div className="h-[140px] flex justify-end items-center">
                <Chart options={spark2} series={totalTransactionSeries} height={130} />
                <h1 className={`font-[Montserrat] font-[600] text-[19px] ${creditPercentage >= 0 ? 'text-[#40890F]' : 'text-[#FF6254]'}`}>
                  {creditPercentage?.toFixed(0)}%
                </h1>           
              </div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-1">
                  <h3 className="font-[Montserrat] font-[500] text-[14px]">
                    Credit
                  </h3>
                  <h1 className="font-[Montserrat] font-[600] text-[34px]">
                    {totalCredit}
                  </h1>
                  <div className="flex gap-3 items-center">
                    <h6 className="font-[Montserrat] font-[500] text-[12px] text-[#3C3C3C80]">
                      0.25%
                    </h6>
                    <IoChevronUpOutline
                      size={20}
                      className="text-[#3C3C3C80]"
                    />
                    {/* <IoChevronDownOutline size={20} className='text-[#3C3C3C80]' /> */}
                  </div>
                </div>
                <div className="w-[63px] h-[63px] bg-[#40890F] rounded-[5px] flex justify-center items-center">
                  <HiCreditCard size={30} className="text-white" />
                </div>
              </div>
              <h3 className="font-[Montserrat] font-[600] text-[14px]">
                Gross sales of August
              </h3>
              <h5 className="font-[Montserrat] font-[500] text-[12px] text-[#3C3C3C80]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </h5>
            </div>

            <div className="bg-white flex flex-col gap-1 p-5 flex-1 rounded-[10px]">
              <div className="flex justify-between items-center">
                <h3 className="font-[Montserrat] font-[500] text-[14px]">
                  Total COD
                </h3>
                <select
                  className="h-[35px] w-max rounded-[8px] px-1 border border-gray-300 focus:outline-none bg-[#40890F] text-white"
                  value={codFilter}
                  onChange={(e) => setCodFilter(e.target.value)}
                >
                  <option value="day" className="bg-[#F2F2F2] text-black">Day</option>
                  <option value="week" className="bg-[#F2F2F2] text-black">Week</option>
                  <option value="month" className="bg-[#F2F2F2] text-black">Month</option>
                  <option value="year" className="bg-[#F2F2F2] text-black">Year</option>
                </select>
              </div>
              <div className="h-[140px] flex justify-end items-center">
                <Chart options={spark3} series={totalCodSeries} height={130} />
                <h1 className={`font-[Montserrat] font-[600] text-[19px] ${codPercentage >= 0 ? 'text-[#40890F]' : 'text-[#FF6254]'}`}>
                  {codPercentage?.toFixed(0)}%
                </h1>           
              </div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-1">
                  <h3 className="font-[Montserrat] font-[500] text-[14px]">
                    Cash on hand
                  </h3>
                  <h1 className="font-[Montserrat] font-[600] text-[34px]">
                    {totalCod}
                  </h1>
                  <div className="flex gap-3 items-center">
                    <h6 className="font-[Montserrat] font-[500] text-[12px] text-[#3C3C3C80]">
                      0.25%
                    </h6>
                    <IoChevronUpOutline
                      size={20}
                      className="text-[#3C3C3C80]"
                    />
                    {/* <IoChevronDownOutline size={20} className='text-[#3C3C3C80]' /> */}
                  </div>
                </div>
                <div className="w-[63px] h-[63px] bg-[#40890F] rounded-[5px] flex justify-center items-center">
                  <GiReceiveMoney size={30} className="text-white" />
                </div>
              </div>
              <h3 className="font-[Montserrat] font-[600] text-[14px]">
                Gross sales of August
              </h3>
              <h5 className="font-[Montserrat] font-[500] text-[12px] text-[#3C3C3C80]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </h5>
            </div>
          </div>
          {/* <div className="flex items-center justify-between mt-[20px] overflow-x-auto disableScrollBar">

            <div className="bg-white flex flex-col gap-1 p-5 w-max 1000px:w-[100%] rounded-[10px]">
              <h3 className="font-[Montserrat] font-[500] text-[14px]">
                Latest Orders
              </h3>
              <table className="border-[#3C3C3C80] border-t">
                {latest4Orders &&
                  latest4Orders?.map((item, index) => (
                    <tr className="flex justify-between items-center border-[#3C3C3C80] border-b py-2">
                      <td className="font-[Montserrat] font-[500] text-[18px] w-[10%]">
                        <h3 className="font-[Montserrat] font-[500] text-[16px] whitespace-nowrap overflow-hidden text-ellipsis">
                          {item._id?.slice(0, 8)}
                        </h3>
                      </td>
                      <td className="w-[15%]">
                        <h3 className="font-[Montserrat] font-[500] text-[16px] whitespace-nowrap overflow-hidden text-ellipsis">
                          {item.user.name}
                        </h3>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[25%]">
                        <div className="flex flex-col">
                          <h3 className="font-[Montserrat] font-[400] text-[9px]">
                            Email
                          </h3>
                          <h3 className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {item.user.email}
                          </h3>
                        </div>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[10%]">
                        <div className="flex flex-col">
                          <h3 className="font-[Montserrat] font-[400] text-[9px]">
                            Price
                          </h3>
                          {item.totalPrice}
                        </div>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[20%]">
                        <div className="flex flex-col whitespace-nowrap overflow-hidden text-ellipsis">
                          <h3 className="font-[Montserrat] font-[400] text-[9px] ">
                            Status
                          </h3>
                          <div
                            className={`${
                              item.status === "Delivered"
                                ? "bg-[#40890F33] text-[#40890F]"
                                : "bg-[#D11F1F33] text-[#D11F1F]"
                            } w-max px-3 py-1 rounded-[50px]`}
                          >
                            {item.status}
                          </div>
                        </div>
                      </td>
                      <td className="font-[Montserrat] font-[500] text-[16px] w-[20%]">
                        <div className="flex flex-col">
                          <h3 className="font-[Montserrat] font-[400] text-[9px]">
                            Create At
                          </h3>
                          {item.createdAt?.slice(0, 10)}
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div> */}
        </div>
        : <h1>Unauthorized</h1>}
      </div>
    </div>
  );
};

export default AdminDashboard;
