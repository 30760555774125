import React from "react";
import Banner3 from "../../assets/main-banner.png";
import xmasbanner from "../../assets/HomeBanner.png";
import xmasSm from "../../assets/HomeBanner.png";
import xmasMd from "../../assets/HomeBanner.png";
import xmasMd2 from "../../assets/xmas-md2.png";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";

const Hero = ({ page }) => {
  return (
    <div className="w-full 500px:h-[89vh] h-[60vh]">
      {page === "home" ? (
        // <div className="w-full h-full relative flex items-center">
        //   <img src={Banner3} alt="" className="w-full h-full object-cover" />
        //   <div
        //     className={`${styles.container} absolute left-0 right-0 mx-auto text-center w-max`}
        //   >
        //     <h1 className="font-Roboto font-[500] text-[8vw] text-white">
        //       THE CHAI WALAH
        //     </h1>
        //     <h1 className="font-Roboto font-[300] text-[3vw] text-white 1000px:-mt-10 800px:-mt-5 -mt-3">
        //       TRULY HANDMADE ARTISANAL TEAS
        //     </h1>
        //   </div>
        // </div>
        window.innerWidth > 800 ? (
          <div className="w-full h-full relative flex items-center">
            <img src={xmasbanner} alt="" className="w-full h-[89vh]" />
            <div
              className={`absolute inset-0 flex flex-col items-center justify-center text-center text-white`}
            >
              <h1 className="font-Roboto font-[500] text-[8vw]">
                THE CHAI WALAH
              </h1>
              <h1 className="font-Roboto font-[300] text-[3vw]">
                TRULY HANDMADE ARTISANAL TEAS
              </h1>
            </div>
          </div>
        ) : window.innerWidth > 500 ? (
          <div className="w-full h-full relative flex items-center">
            <img src={xmasMd} alt="" className="w-full h-full object-cover" />
            <div
              className={` absolute inset-0 flex flex-col items-center justify-center text-center text-white`}
            >
              <h1 className="font-Roboto font-[500] text-[8vw]">
                THE CHAI WALAH
              </h1>
              <h1 className="font-Roboto font-[300] text-[3vw]">
                TRULY HANDMADE ARTISANAL TEAS
              </h1>
            </div>
          </div>
        ) : (
          <div className="w-full h-full relative flex items-center">
            <img src={xmasSm} alt="" className="w-full h-full object-cover" />
            <div
              className={` absolute inset-0 flex flex-col items-center justify-center text-center text-white`}
            >
              <h1 className="font-Roboto font-[500] text-[8vw]">
                THE CHAI WALAH
              </h1>
              <h1 className="font-Roboto font-[300] text-[3vw]">
                TRULY HANDMADE ARTISANAL TEAS
              </h1>
            </div>
          </div>
        )
      ) : page === "aboutus" ? (
        <div className="w-[100%] h-[100%] relative">
          <img
            src={Banner3}
            alt=""
            className="w-[100%] h-[100%] object-cover"
          />
          <div
            className={`${styles.container} absolute left-0 right-0 top-[15vh] w-11/12 mx-auto h-auto`}
          >
            <h1 className="font-Poppins font-[500] text-[72px] text-white">
              ABOUT
            </h1>
            <p className="font-Poppins font-[400] text-[16px] text-white text-justify 1000px:w-1/2 mb-3 line-clamp-3 500px:line-clamp-none">
              THE CHAI WALAH is founded by Faisal Yousaf AKA Mr.Chai Walah while
              he was living in England, where he worked as a certified Barista
              in the speciality coffee industry and ran an independent coffee
              roastery in trendy East London. His love affair with caffeine
              filled coffee, tea and passion for spices, herbs his Indian
              heritage and nostalgia of morning chai rituals he used to have in
              his childhood back in India led him to experiment with chai to
              find roots back to India. Coming back home after being an expact
              for so long and gallivanting around, he was inspired from his
              travels through hidden tea rooms, vibrant night markets, highland
              plantations and nomadic tents, where he discovered real tea. In
              these distant lands, where traditional recipes are handed down
              from generation to generation, the knowledge of spices and
              preperations lived on for centuries. He missed all that since
              being back and could not find anything similar, so he brought all
              that tea knowledge home to bring those old flavoures to the new
              world which led to a humble beginnings of "THE CHAI WALAH" from a
              custom built market cart in a village street of southern India.{" "}
            </p>
            <Link to="/shop">
              <button className="font-Poppins font-[500] text-[20px] text-white border-[1px] border-[#fff] px-2 py-1">
                Try our powder mix
              </button>
            </Link>
          </div>
        </div>
      ) : page === "franchise" ? (
        <div className="w-[100%] h-[100%] relative">
          <img
            src={Banner3}
            alt=""
            className="w-[100%] h-[100%] object-cover"
          />
          <div
            className={`${styles.container} absolute left-0 right-0 top-[30vh] w-11/12 mx-auto h-auto`}
          >
            <h1 className="font-Poppins font-[500] text-[30px] text-white">
              Find about The Chai Walah franchise
            </h1>
            <p className="font-Poppins font-[400] text-[16px] text-white text-justify 1000px:w-1/2 mb-3 line-clamp-3 500px:line-clamp-none">
              Join us in the journey of elevating chai to new heights. Explore
              our range of chai powders and experience the true essence of this
              timeless beverage. Together, we'll brew success, one cup at a
              time.
            </p>
          </div>
        </div>
      ) : page === "gallery" ? (
        <div className="w-full h-full relative flex items-center">
          <img src={Banner3} alt="" className="w-full h-full object-cover" />
          <div
            className={`${styles.container} absolute left-0 right-0 mx-auto text-center w-max`}
          >
            <h1 className="font-Roboto font-[500] text-[8vw] text-white">
              GALLERY
            </h1>
          </div>
        </div>
      ) : page === "blog" ? (
        <div className="w-full h-full relative flex items-center">
          <img src={Banner3} alt="" className="w-full h-full object-cover" />
          <div
            className={`${styles.container} absolute left-0 right-0 mx-auto text-center w-max`}
          >
            <h1 className="font-Roboto font-[500] text-[8vw] text-white">
              BLOG
            </h1>
          </div>
        </div>
      ) : page === "press" ? (
        <div className="w-full h-full relative flex items-center">
          <img src={Banner3} alt="" className="w-full h-full object-cover" />
          <div
            className={`${styles.container} absolute left-0 right-0 mx-auto text-center w-max`}
          >
            <h1 className="font-Roboto font-[500] text-[8vw] text-white">
              PRESS
            </h1>
          </div>
        </div>
      ) : (
        <div className="w-full h-full relative flex items-center">
          <img src={Banner3} alt="" className="w-full h-full object-cover" />
          <div
            className={`${styles.container} absolute left-0 right-0 mx-auto text-center w-max`}
          >
            <h1 className="font-Roboto font-[500] text-[8vw] text-white">
              PRODUCTS
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
