import React from "react";
import { adminNavItems } from "../../../static/data";
import { Link } from "react-router-dom";
import { AiOutlineDashboard } from "react-icons/ai";
import axios from "axios";
import { useDispatch } from "react-redux";

const AdminNavbar = ({ active }) => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    axios.get(`${process.env.REACT_APP_SERVER}/admin/logout`, {
      withCredentials: true,
    });
    dispatch({type: "LogoutAdmin"});
  };
  return (
    <div className="mt-[50px] flex flex-col gap-4 mx-[30px] items-center 1000px:items-start">
      {adminNavItems &&
        adminNavItems.map((i, index) => (
          <div className="1000px:w-full 1000px:flex-grow">
            {index === 10 ? (
              <div
                onClick={handleLogout}
                className={`${
                  active === index + 1
                    ? "bg-gradient-to-r from-gray-600 via-gray-600 to-gray-900"
                    : ""
                } py-2 px-4 rounded-[20px] flex items-center gap-3 hover:bg-gradient-to-r from-gray-600 via-gray-600 to-gray-900 cursor-pointer`}
              >
                {i.icon}
                <h2
                  className={`1000px:block hidden font-[Montserrat] font-[500] text-[16px] text-white`}
                >
                  {i.title}
                </h2>
              </div>
            ) : (
              <Link
                to={i.url}
                className={`${
                  active === index + 1
                    ? "bg-gradient-to-r from-gray-600 via-gray-600 to-gray-900"
                    : ""
                } py-2 px-4 rounded-[20px] flex items-center gap-3 hover:bg-gradient-to-r from-gray-600 via-gray-600 to-gray-900 w-max 1000px:w-full`}
              >
                {i.icon}
                <h2
                  className={`1000px:block hidden font-[Montserrat] font-[500] text-[16px] text-white`}
                >
                  {i.title}
                </h2>
              </Link>
            )}
          </div>
        ))}
    </div>
  );
};

export default AdminNavbar;
