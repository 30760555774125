import React from "react";
import Products from "../../components/Route/Products/Products";
import Image1 from "../../assets/blog2.png";
import Image2 from "../../assets/blog1.png";
import styles from "../../styles/styles";

const Blog = () => {
  return (
    <div className="w-full bg-[#F4F3F3]">
      <div
        className={`${styles.container} !py-[0] flex 800px:flex-row flex-col items-center gap-20 `}
      >
        <div className="800px:w-1/2 w-full flex flex-col gap-3 justify-center py-3 800px:py-0">
          <h4 className="font-Poppins font-[500] text-[30px]">Tea Powder</h4>
          <p className="font-Poppins font-[300] text-[15px] text-justify">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text.There are many variations of passages of Lorem Ipsum
            available, but the majority have suffered alteration in some form,
            by injected humour, or randomised words which don't look even
            slightly believable. If you are going to use a passage of Lorem
            Ipsum, you need to be sure there isn't anything embarrassing hidden
            in the middle of text. There are many variations of passages of
            Lorem Ipsum available, but the majority have suffered alteration in
            some form, by injected humour, or randomised words which don't look
            even slightly believable. If you are going to use a passage of Lorem
            Ipsum, you need to be sure there isn't anything embarrassing hidden
            in the middle of text
          </p>
        </div>
        <div className="800px:w-1/2 400px:w-[70%] w-full 800px:h-[544px] h-auto flex justify-center">
          <img src={Image1} alt="" className="h-[100%] object-contain" />
        </div>
      </div>
      {/* <div className="w-full bg-white h-10"></div> */}
      <Products heading={false} />
      <div
        className={`${styles.container} !py-[0] flex 800px:flex-row flex-col items-center gap-20 `}
      >
        <div className="800px:w-1/2 400px:w-[70%] w-full 800px:h-[544px] h-auto flex justify-center">
          <img src={Image2} alt="" className="h-[100%] object-contain" />
        </div>
        <div className="800px:w-1/2 w-full flex flex-col gap-3 justify-center py-3 800px:py-0">
          <h4 className="font-Poppins font-[500] text-[30px]">Tea Powder</h4>
          <p className="font-Poppins font-[300] text-[15px] text-justify">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text.There are many variations of passages of Lorem Ipsum
            available, but the majority have suffered alteration in some form,
            by injected humour, or randomised words which don't look even
            slightly believable. If you are going to use a passage of Lorem
            Ipsum, you need to be sure there isn't anything embarrassing hidden
            in the middle of text. There are many variations of passages of
            Lorem Ipsum available, but the majority have suffered alteration in
            some form, by injected humour, or randomised words which don't look
            even slightly believable. If you are going to use a passage of Lorem
            Ipsum, you need to be sure there isn't anything embarrassing hidden
            in the middle of text
          </p>
        </div>
      </div>
    </div>
  );
};

export default Blog;
