import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

const Press = () => {
  const [news, setNews] = useState("");
  const [pageNum, setPageNum] = useState(1);

  const getNews = async () => {
    try {
      let limit = 5;
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/press/get-all-news-public?pageNum=${pageNum}&limit=${limit}`,
        {
          withCredentials: true,
        }
      );
      setNews(data?.news);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    getNews();
  }, [pageNum]);

  return (
    <div className="w-full bg-white py-16">
      <div
        className={`${styles.container} ${news?.docs?.length > 4 ? "800px:grid-rows-2 grid-rows-5 800px:grid-cols-4 grid-cols-1" : "800px:grid-rows-5 grid-rows-5 800px:grid-cols-2 grid-cols-1"} 800px:h-[70vh] h-[140vh] grid  grid-flow-row gap-10`}
      >
        {news.docs &&
          news.docs.map((i, index) => (
            <Link
                to={`/press/${i._id}`}
              className={`${
                index === 1 ? "800px:col-span-2 800px:row-span-2 col-span-1 row-span-1" : "col-span-1 row-span-1"
              } ${news?.docs?.length > 4 ? "" : "800px:row-span-2 800px:col-span-1"} rounded-[19px] flex flex-col justify-end px-3 py-2 relative cursor-pointer`}
            >
              <img
                src={`${process.env.REACT_APP_BUCKET_URL}${i.images[0]}`}
                alt=""
                className="absolute inset-0 w-full h-full object-cover rounded-[19px]"
              />
              <h1
                className="font-[Poppins] font-[600] text-[16px] text-white whitespace-nowrap overflow-hidden text-ellipsis z-10 cursor-pointer"
              >
                {i.title}
              </h1>
              <h4 className="font-[Poppins] font-[400] text-[14px] text-white whitespace-nowrap overflow-hidden text-ellipsis z-10">
                {i.description}
              </h4>
              <h6 className="font-[Poppins] font-[400] text-[12px] text-[#9A9A9A] whitespace-nowrap overflow-hidden text-ellipsis z-10">
                {i.createdAt?.slice(0, 10)}
              </h6>
            </Link>
          ))}
      </div>
      <div className="flex justify-center">
        <Stack spacing={2}>
          <Pagination
            count={news?.totalPages}
            page={pageNum}
            onChange={(e, number) => setPageNum(number)}
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

export default Press;
