import React from 'react'
import { useSelector } from 'react-redux';

const CartItems = () => {
  const { cart } = useSelector((state) => state.cart);

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.price,
    0
    );

    // this is shipping cost variable
    let shipping;
    if(subTotalPrice >= 500) {
      shipping = 0;
    } else {
      shipping = 50;
    }

    const totalPrice = (subTotalPrice + shipping).toFixed(2);
  
  return (
    <div className="800px:flex hidden w-[40%] h-screen bg-[#F4F3F3] px-16 py-20 flex-col gap-3">
        {cart &&
          cart.map((i, index) => (
            <div
              key={index}
              className="flex gap-3 max-w-[560px] w-[100%] shadow-lg rounded-lg"
            >
              <div className="relative bg-white w-[120px] h-[120px] rounded-lg flex justify-center items-center">
                <div className="absolute -top-2 right-0 w-[20px] h-[20px] bg-[#40890F] text-white text-[10px] flex justify-center items-center rounded-full">
                  {i.qty}
                </div>
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}${i?.images[0]}`}
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="flex flex-col mt-2">
                <h4 className="font-Poppins font-[400] text-[18px]">
                  {i.name}
                </h4>
                <h4 className="font-Poppins font-[500] text-[18px] text-[#40890F]">
                  ₹{i.price}
                </h4>
              </div>
            </div>
          ))}

        <div className="flex justify-between tems-center max-w-[560px] w-[100%]">
          <h5 className="font-Poppins font-[300] text-[18px]">Subtotal</h5>
          <h4 className="font-Poppins font-[300] text-[18px]">₹{subTotalPrice}</h4>
        </div>
        <div className="flex justify-between tems-center max-w-[560px] w-[100%]">
          <h5 className="font-Poppins font-[300] text-[18px]">Shipping</h5>
          <h4 className="font-Poppins font-[300] text-[18px]">{shipping.toFixed(2)}</h4>
        </div>
        <div className="flex justify-between tems-center max-w-[560px] w-[100%]">
          <h4 className="font-Poppins font-[500] text-[20px]">Total</h4>
          <h4 className="font-Poppins font-[500] text-[18px]">₹{totalPrice}</h4>
        </div>
    </div>
  )
}

export default CartItems