import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ErrorMessage, Form, Formik } from "formik";
import { BiError } from "react-icons/bi";
import { useSelector } from 'react-redux'
import { adminPasswordFormSchema } from '../../schema/valiationSchema'

const AdminProfileSettingsPassword = () => {
    const { admin } = useSelector((state) => state.admin);

    const passwordChangeHandler = async (e, i) => {

        const password = e.password;
        const newPassword = e.newPassword;
        const confirmPassword = e.confirmPassword;

        await axios
          .put(
            `${process.env.REACT_APP_SERVER}/admin/update-admin-password`,
            { password, newPassword, confirmPassword },
            { withCredentials: true }
          )
          .then((res) => {
            toast.success(res.data.message);
            i.resetForm();
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
        <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
            <div className='w-full h-full'>
                <div className="flex justify-between">
                    <div className="flex flex-col">
                        <h2 className='font-[Montserrat] font-[600] text-[20px]'>PROFILE SETTINGS</h2>
                        <span className='font-[Montserrat] font-[500] text-[10px] -mt-1'>THE CHAI WALAH Admin Panel</span>
                    </div>
                    <Link to="/admin-settings">
                        <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                            Edit Profile
                        </button>
                    </Link>
                </div>
                <Formik
            initialValues={{
                password: "",
                newPassword: "",
                confirmPassword: "",
            }}
            validationSchema={adminPasswordFormSchema}
            onSubmit={passwordChangeHandler}
          >
            {({ values, handleChange }) => (
              <Form className="mt-[20px] flex flex-col gap-4">

             
                    <div className="w-full flex justify-between items-center">
                        <label className="font-[Montserrat] font-[500] text-[16px]">
                            <span className='text-[#40890F]'>*</span> Old Password :
                        </label>
                        <div className="flex flex-col w-[70%]">

                        <input
                            type="password"
                            name="password"
                            value={values.password}
                            className='h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                            onChange={handleChange}
                            // placeholder="Enter your product price..."
                            />
                        <ErrorMessage name="password">
                      {(msg) => (
                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-center">
                        <label className="font-[Montserrat] font-[500] text-[16px]">
                            <span className='text-[#40890F]'>*</span> New Password :
                        </label>
                        <div className="flex flex-col w-[70%]">

                        <input
                            type="password"
                            name="newPassword"
                            value={values.newPassword}
                            className='h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                            onChange={handleChange}
                            // placeholder="Enter your product price..."
                            />
                        <ErrorMessage name="newPassword">
                      {(msg) => (
                          <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-center">
                        <label className="font-[Montserrat] font-[500] text-[16px]">
                            <span className='text-[#40890F]'>*</span> Confirm Password :
                        </label>
                        <div className="flex flex-col w-[70%]">

                        <input
                            type="password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            className='h-[40px] rounded-[8px] px-3 border border-gray-300 w-[100%] focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                            onChange={handleChange}
                            // placeholder="Enter your product price..."
                        />
                        <ErrorMessage name="confirmPassword">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                      </div>
                    </div>
                    <div className="w-full flex justify-end">
                        <input type="submit" value="save" className="bg-[#40890F] text-white font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-5 py-2 cursor-pointer" />
                    </div>
                    </Form>
            )}
            </Formik>            </div>
        </div>
    </div>
  )
}

export default AdminProfileSettingsPassword  