import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Country, State } from "country-state-city";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";

const ShippingAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);

  const [name, setName] = useState(user && user.name);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [country, setCountry] = useState("IN");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [apartment, setApartment] = useState("");
  const [pinCode, setPinCode] = useState(null);
  const [userInfo, setUserInfo] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
      setOpen(false);
  };

  const handleSwitchAccount = () => {
    setOpen(true);
  }

  const handleLogout = () => {
    handleClose()
    axios.get(`${process.env.REACT_APP_SERVER}/user/logout`, {withCredentials: true}).then((res) => {
      dispatch({
        type: "LogoutUser",
      });
      localStorage.removeItem("userData")
      navigate('/login');
    }).catch((error) => {
        console.log(error?.response?.data?.message);
    })
  };

  const totalPrice = cart.reduce((acc, item) => acc + item.qty * item.price, 0);

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.price,
    0
  );

    // this is shipping cost variable
    let shipping;
    if(subTotalPrice >= 500) {
      shipping = 0;
    } else {
      shipping = 50;
    }

  const paymentSubmit = () => {
    if (
      name === "" ||
      phoneNumber === "" ||
      state === "" ||
      district === "" ||
      pinCode === null ||
      country === "" ||
      city === ""
    ) {
      toast.error("Please choose your delivery address!");
    } else {
      const shippingAddress = {
        country,
        state,
        district,
        city,
        pinCode,
        name,
        phoneNumber,
      };

      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        shipping,
        //  discountPrice,
        shippingAddress,
        user,
      };

      // update local storage with the updated orders array
      localStorage.setItem("latestOrder", JSON.stringify(orderData));
      navigate("/shipping");
    }
  };

  return (
    <div className="flex flex-col gap-3 mt-16 max-w-[840px] w-[100%] m-auto mb-5">
      <h3 className="font-Poppins font-[500] text-[22px]">Contact</h3>
      <div className="flex gap-3 flex-wrap overflow-hidden">
        <h4 className="font-Poppins font-[400] text-[18px]">{user.name}</h4>
        <h4 className="font-Poppins font-[400] text-[18px] truncate">{user.email}</h4>
      </div>
      <h5 onClick={handleSwitchAccount} className="font-Poppins font-[400] text-[16px] text-[#40890F] cursor-pointer">
        Switch Account
      </h5>
      {/* <div className="flex gap-3">
              <input type="checkbox" name="" id="" />
              <h4 className="font-Poppins font-[400] text-[18px]">
                Email me with news and offers
              </h4>
            </div> */}
      <h3 className="font-Poppins font-[500] text-[25px]">Shipping address</h3>
      <div className="flex gap-8">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
          placeholder="Name"
        />
        <input
          type="number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
          placeholder="Phone number"
        />
      </div>
      <select
        disabled
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
      >
        <option value="" className="block pb-2">
          Choose your country
        </option>
        {Country &&
          Country.getAllCountries().map((item) => (
            <option key={item.isoCode} value={item.isoCode}>
              {item.name}
            </option>
          ))}
      </select>
      <select
        value={state}
        onChange={(e) => setState(e.target.value)}
        className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
      >
        <option value="" className="block pb-2">
          Choose your state
        </option>
        {State &&
          State.getStatesOfCountry(country).map((item) => (
            <option key={item.isoCode} value={item.isoCode}>
              {item.name}
            </option>
          ))}
      </select>
      <div className="flex gap-8">
        <input
          type="text"
          value={district}
          onChange={(e) => setDistrict(e.target.value)}
          className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
          placeholder="District"
        />
        <input
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
          placeholder="City"
        />
        <input
          type="number"
          value={pinCode}
          onChange={(e) => setPinCode(e.target.value)}
          className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
          placeholder="PIN Code"
        />
      </div>
      <input
        type="text"
        value={apartment}
        onChange={(e) => setApartment(e.target.value)}
        className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3"
        placeholder="Apartment"
      />
      <div className="flex gap-2 items-center">
        <h5
          className="text-[18px] cursor-pointer inline-block"
          onClick={() => setUserInfo(!userInfo)}
        >
          Choose From saved address
        </h5>
        {userInfo && (
          <div className="flex gap-2">
            {user && user.addresses.length > 0 ?
              user.addresses.map((item, index) => (
                <div className="w-full flex mt-1">
                  <input
                    type="radio"
                    name="address-type"
                    className="mr-3"
                    value={item.addressType}
                    onClick={() =>
                      setDistrict(item.district) ||
                      setState(item.state) ||
                      setPinCode(item.pinCode) ||
                      setCountry(item.country) ||
                      setCity(item.city)
                    }
                  />
                  <h2>{item.addressType}</h2>
                </div>
              )) : (<h5 className="text-[red] font-semibold">No saved address!</h5>)}
          </div>
        )}
      </div>

      <div className="flex justify-between items-center flex-wrap gap-3">
        <Link to="/cart" className="flex gap-2">
          <AiOutlineLeft size={20} className="text-[#40890F] cursor-pointer" />
          <h5 className="font-Poppins font-[300] text-[14px] text-[#40890F] cursor-pointer">
            Return to cart
          </h5>
        </Link>
        <button
          className="text-[#fff] bg-[#3C3C3C] border-[1.5px] border-[#3C3C3C] rounded-lg w-[218px] h-[36px] font-Poppins font-[12px] cursor-pointer"
          onClick={paymentSubmit}
        >
          Continue to shipping
        </button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {"Please confirm to logout from your current account?"}
        </DialogTitle>
        <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleLogout} autoFocus>
            Confirm
        </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShippingAddress;
