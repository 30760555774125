import React, { useEffect, useState } from "react";
import { FcManager } from "react-icons/fc";
import { IoIosNotificationsOutline } from "react-icons/io";
import { LuSearch } from "react-icons/lu";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {io} from "socket.io-client";

const AdminHeader = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.admin);
  const [dropDown, setDropDown] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  
  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_URL);
    socket.on("connect", () => {
    });

    socket.on("updateNotification", (data) => {
      getNotificationItems();
    });

    socket.on("disconnect", () => {
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
  }, []);

  useEffect(() => {
    getNotificationItems();
  }, []);

  const getNotificationItems = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/notification/get-all-notification`
      );
      setNotifications(data?.notifications);
    } catch (error) {
      console.log(error);
    }
  };

  const clearNotification = async () => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_SERVER}/notification/delete-notifications`,
        {
          withCredentials: true,
        }
      );
      setNotifications("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = async () => {
    axios.get(`${process.env.REACT_APP_SERVER}/admin/logout`, {
      withCredentials: true,
    });
    dispatch({type: "LogoutAdmin"});
  };

  return (
    <div className="h-[11vh] w-[80vw] fixed bg-white flex justify-end items-center gap-2 pr-[20px]">
      {/* <div className="flex">
        <input
          className="w-[218px] h-[38px] bg-[#F2F2F2] flex items-center px-3 rounded-l-[8px]"
          placeholder="search"
        ></input>
        <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
          <LuSearch size={25} className="text-white" />
        </div>
      </div> */}
      <div
        className="relative cursor-pointer w-[300px] flex justify-end"
        onMouseEnter={() => setNotificationDropDown(true)}
        onMouseLeave={() => setNotificationDropDown(false)}
      >
        {notifications.length > 0 && (
          <div className="bg-[#40890F] w-2 h-2 rounded-full absolute right-2 top-1"></div>
        )}
        <IoIosNotificationsOutline
          size={35}
          className={`${notificationDropDown ? "text-[red]" : null}`}
        />
        {notificationDropDown ? (
          <div className="w-[300px] max-h-[23vh] bg-white absolute right-0 top-full mt-0.5 rounded-b-md shadow-sm overflow-y-auto disableScrollBar z-10 flex flex-col">
            <div
              className={`flex flex-col rounded-b-md max-h-[18vh] overflow-y-auto disableScrollBar`}
              onClick={() => setNotificationDropDown(!notificationDropDown)}
            >
              {notifications &&
                notifications?.map((item, index) => (
                  <Link
                    to="/admin-orders"
                    className="w-full flex justify-between hover:bg-[#E2E2E2] px-1 py-1 border-[#979494] border-b-[0.5px]" 
                  >
                    <h3 className="w-[80%] cursor-pointer select-none text-[#3C3C3C] font-Poppins text-[14px] font-normal text-ellipsis whitespace-nowrap truncate ">
                      {item.message}
                    </h3>
                    <h2 className="w-[30%] flex justify-end text-[#585858] font-Poppins text-[14px] font-normal">
                      {item.createdAt?.slice(11, 16)}
                    </h2>
                  </Link>
                ))}
              {notifications.length === 0 && (
                <div
                  className="w-full flex justify-center"
                  onClick={clearNotification}
                >
                  <h3 className="text-[16px] font-semibold">
                    No notifications
                  </h3>
                </div>
              )}
              
            </div>
            {notifications.length > 0 && (
                <div
                  className="w-full flex justify-center"
                  onClick={clearNotification}
                >
                  <h3 className="text-[16px] font-semibold">Mark all read</h3>
                </div>
              )}
          </div>
        ) : null}
      </div>
      <div className="bg-blue-500 w-[49px] h-[49px] flex justify-center items-center rounded-full">
        <img
          src={admin?.avatar}
          alt=""
          className="object-contain rounded-full"
        />
      </div>
      <div
        className="flex items-center relative px-2 py-2 rounded-md border border-[#fff] hover:border-red-500"
        onMouseEnter={() => setDropDown(true)}
        onMouseLeave={() => setDropDown(false)}
      >
        <h3 className="font-[Montserrat] font-[500] text-[12px] cursor-pointer">
          {admin.role}
        </h3>
        <MdKeyboardArrowDown size={20} className="cursor-pointer" />
        {dropDown ? (
          <div className=" w-full bg-white absolute right-0 top-full mt-0.5 rounded-b-md shadow-sm">
            <div
              className={`flex flex-col rounded-b-md`}
              onClick={() => setDropDown(!dropDown)}
            >
              <Link to="/admin-settings" className="w-full">
                <h3 className="cursor-pointer select-none text-[#3C3C3C] text-center hover:bg-[#E2E2E2] w-full text-[18px] font-normal py-1">
                  Edit Profile
                </h3>
              </Link>
              <h3 className="cursor-pointer select-none text-[#3C3C3C] text-center hover:bg-[#E2E2E2] w-full text-[18px] font-normal py-1" onClick={handleLogout}>
                Logout
              </h3>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdminHeader;
