import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Banner from "../assets/footer.png";
import Error from "../assets/error.png";
import Success from "../assets/success.png";
import axios from "axios";
import { GrLinkNext } from "react-icons/gr";

const ActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activation_token) {
      const activationEmail = async () => {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_SERVER}/user/activation`,
            {
              activation_token,
            }
          );
          console.log(res.data.message);
        } catch (error) {
          console.log(error?.response?.data?.message);
          setError(true);
        }
      };
      activationEmail();
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#000",
        backgroundImage: `url(${Banner})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          boxShadow: "0px 0px 44px 0px #0000001A",
        }}
        className="800px:w-[60vw] w-[80vw] 800px:h-[40vh] h-[30vh] rounded-2xl flex justify-center items-center bg-[#FFFFFFCC] py-[20px] 1100px:px-[120px] 880px:px-[90px] px-[40px]"
      >
        {error ? (
          <div className="flex flex-col items-center gap-3">
            <img
              src={Error}
              alt="error"
              className="object-fit max-h-[8vh] max-w-[10vw]"
            />
            <p className="font-Poppins font-[500] 1100px:text-[30px] 800px:text-[26px] 400px:text-[23px] text-[16px] text-center">
              Your token is expired!
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-3">
            <img
              src={Success}
              alt="error"
              className="object-fit max-h-[8vh] max-w-[10vw]"
            />
            <p className="font-Poppins font-[500] 1100px:text-[30px] 800px:text-[26px] 400px:text-[23px] text-[16px] text-center">
              Your account has been created successfully!
            </p>
            <Link to="/login" className="bg-[#40890F] flex gap-2 items-center text-white px-5 py-1 rounded-xl font-Poppins font-[500] 800px:text-[20px] 400px:text-[16px] text-[12px]">
              Login now <GrLinkNext size={19} className="text-white bg-white rounded-full p-1" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivationPage;
