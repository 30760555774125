import axios from "axios";
import React, { useEffect, useState } from "react";
import { GrUploadOption } from "react-icons/gr";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FiDelete, FiEdit } from "react-icons/fi";
import { LuSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const AdminPress = () => {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.admin);
  const [publishedNews, setPublishedNews] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [newsRow, setNewsRow] = useState("");

  const getPublishedNews = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/press/get-all-news`, {
        withCredentials: true,
      });
      setSearchData(data?.news);
      setPublishedNews(data?.news);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPublishedNews();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteBtn = (id) => {
    handleClickOpen();
    setSelectedId(id);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = publishedNews?.filter((item) =>
      item.title.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(filteredData);
  };

  const setData = () => {
    if (searchData) {
      setNewsRow(searchData);
    } else {
      setNewsRow(publishedNews);
    }
  };

  useEffect(() => {
    setData();
  }, [searchData]);

  const handleAddBtn = () => {
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("createNews")
    ) {
      navigate("/admin-add-news");
    } else {
      toast.error("Unauthorized");
    }
  };

  const handleDelete = async () => {
    handleClose();

    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("deleteNews")
    ) {
      await axios
        .delete(`${process.env.REACT_APP_SERVER}/press/delete-news/${selectedId}`, {
          withCredentials: true,
        })
        .then((res) => {
          toast.success(res.data.message);
          getPublishedNews();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Unauthorized");
    }
  };

  const handleEditBtn = (id, title, description) => {
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("updateNews")
    ) {
      navigate(`/admin-edit-news/${id}`);
    } else {
      toast.error("Unauthorized");
    }
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">PRESS</h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] mb-[20px]">
            <div className="flex">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-[218px] h-[38px] bg-[#fff] flex items-center px-3 rounded-l-[8px]"
                placeholder="search with title"
              ></input>
              <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
                <LuSearch size={25} className="text-white" />
              </div>
            </div>
            <div className="flex items-center gap-4">
              {/* <h4 className="font-[Montserrat] font-[500] text-[14px] cursor-pointer">
                Export
              </h4> */}
              <div onClick={handleAddBtn}>
                <button className="bg-[#3C3C3C] text-white rounded-md px-2.5 py-2 font-[Montserrat] font-[500] text-[14px]">
                  Add News
                </button>
              </div>
            </div>
          </div>
          <h2 className="font-[Montserrat] font-[600] text-[20px]">
            Published News
          </h2>
          <div className="w-full mt-4 h-[65vh] overflow-y-auto disableScrollBar">
            {newsRow &&
              newsRow?.map((item, index) => (
                <tr
                  className={`flex justify-between items-center rounded-lg py-2 px-2 max-w-[1400px] ${
                    index % 2 === 0 ? "bg-white" : null
                  } `}
                >
                  <td className="font-[Montserrat] font-[500] text-[18px] w-[20%]">
                    <img
                      // src={`${process.env.REACT_APP_BACKEND_URL}/${item.images[0]}`}
                      src={`${process.env.REACT_APP_BUCKET_URL}${item.images[0]}`}
                      alt=""
                      className="object-contain h-[82px] w-[118px] rounded-lg"
                    />
                  </td>
                  <td className="w-[70%] flex flex-col">
                    <h3 className="font-[Poppins] font-[500] text-[16px] whitespace-nowrap overflow-hidden text-ellipsis">
                      {item.title}
                    </h3>
                    <h4 className="font-[Poppins] font-[400] text-[14px] whitespace-nowrap overflow-hidden text-ellipsis">
                      {item.description}
                    </h4>
                    <h6 className="font-[Poppins] font-[400] text-[12px] text-[#9A9A9A] whitespace-nowrap overflow-hidden text-ellipsis">
                      {item.createdAt?.slice(0, 10)}
                    </h6>
                  </td>
                  <td className="w-[10%] flex justify-end">
                    <div className="flex gap-2">
                      <div onClick={() => handleEditBtn(item._id)}>
                        <FiEdit
                          size={20}
                          className="cursor-pointer text-[green]"
                        />
                      </div>
                      <FiDelete
                        size={20}
                        onClick={() => handleDeleteBtn(item._id)}
                        className="cursor-pointer text-[red]"
                      />
                    </div>
                  </td>
                </tr>
              ))}
              {publishedNews?.length === 0 || searchData?.length === 0 && (
                <h1>No news found</h1>
              )}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Please confirm to delete the item?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDelete} autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPress;
