import React from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminCustomerList from '../../components/Admin/AdminCustomerList'

const AdminCustomersList = () => {
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={4} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminCustomerList />
        </div>
    </div>
  )
}

export default AdminCustomersList