import React, { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getAllUsers, updatUserStatus } from "../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const AdminCustomerList = () => {
  const { allUsers, getUsersError, isLoading } = useSelector(
    (state) => state.user
  );
  const [status, setStatus] = useState("all");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredUsers = allUsers?.filter((user) =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(filteredUsers);
  };

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      setStatus("search");
    } else {
      setStatus("all");
    }
  }, [searchTerm]);

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              CUSTOMER LIST
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="flex justify-between mt-[20px] mb-[20px]">
            <div className="flex">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-[218px] h-[38px] bg-[#fff] flex items-center px-3 rounded-l-[8px]"
                placeholder="search with customer name"
              ></input>
              <div className="w-[38px] h-38px] bg-[#40890F] flex justify-center items-center rounded-r-[8px]">
                <LuSearch size={25} className="text-white" />
              </div>
            </div>
            <div className="flex items-center bg-white rounded-md px-1">
              <div
                className={`${
                  status === "all"
                    ? "bg-green-200 text-[#40890F]"
                    : "bg-white text-[#667085]"
                } font-[Montserrat] font-[600] text-[14px] px-2 py-1 rounded-md cursor-pointer`}
                onClick={() => setStatus("all")}
              >
                All Status
              </div>
              <div
                className={`${
                  status === "active"
                    ? "bg-green-200 text-[#40890F]"
                    : "bg-white text-[#667085]"
                } font-[Montserrat] font-[600] text-[14px] px-2 py-1 rounded-md cursor-pointer`}
                onClick={() => setStatus("active")}
              >
                Active
              </div>
              <div
                className={`${
                  status === "blocked"
                    ? "bg-green-200 text-[#40890F]"
                    : "bg-white text-[#667085]"
                } font-[Montserrat] font-[600] text-[14px] px-2 py-1 rounded-md cursor-pointer`}
                onClick={() => setStatus("blocked")}
              >
                Blocked
              </div>
            </div>
          </div>
          <div>
            {getUsersError ? (
              <h1>{getUsersError}</h1>
            ) : (
              <CustomerList
                status={status}
                searchData={searchData}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerList = ({ status, searchData, isLoading }) => {
  const { admin } = useSelector((state) => state.admin);
  const { allUsers, error } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const handleStatusUpdate = (id) => {
    if (
      admin?.role === "Master Admin" ||
      admin?.permissions.includes("updateCustomer")
    ) {
      dispatch(updatUserStatus(id));
    } else {
      toast.error("Unauthorized");
    }
    // dispatch(updatUserStatus(id))
  };

  const activeUsers = allUsers?.filter((item) => item.blocked === false);
  const blockedUsers = allUsers?.filter((item) => item.blocked == true);

  // allUsers?.forEach((user) => {
  //   dispatch(getAllOrdersOfUser(user?._id));
  // })

  // balance = orders?.reduce((sum, order) => {
  //   if(order?.paymentInfo.type === "Cash On Delivery") {
  //     return sum + order.totalPrice
  //   }
  //   return sum;
  // }, 0)

  if (
    (status === "active" && (!activeUsers || activeUsers.length === 0)) ||
    (status === "blocked" && (!blockedUsers || blockedUsers.length === 0)) ||
    (status === "search" && (!searchData || searchData.length === 0)) ||
    !allUsers ||
    allUsers.length === 0
  ) {
    return <p>No users found.</p>;
  }

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : (
        <div className="flex gap-10 flex-wrap overflow-y-auto h-[71vh]">
          {status === "active"
            ? activeUsers &&
              activeUsers?.map((item, index) => (
                <div className="bg-white w-[200px] h-max rounded-[10px] p-3">
                  {/* <div className="flex justify-between">
                <input
                  type="checkbox"
                  name=""
                  id="termsCheckbox"
                  className="cursor-pointer"
                />
                <BsThreeDotsVertical size={20} className="cursor-pointer" />
              </div> */}
                  <div className="w-full flex flex-col gap-2 items-center">
                    <div className="w-[79px] h-[79px] rounded-full bg-[#E0E2E7]">
                      <img
                        src={item?.avatar}
                        alt=""
                        className="object-contain rounded-full"
                      />
                    </div>
                    <h3 className="font-[Montserrat] font-[600] text-[14px]">
                      {item?.name}
                    </h3>
                    <div className="flex gap-1 px-1 py-0.5 shadow-sm border-1 border-[#3C3C3C] rounded-[16px] bg-[#d0d5dd]">
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5 text-[#40890F] bg-green-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 text-[#D11F1F] bg-red-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Blocked
                        </div>
                      )}
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5  rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          block
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          unblock
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="border-[#C2C6CE] border-dashed border-[1px] mt-4"></div>
                  <div className=" mt-4 flex justify-between">
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Orders
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        {item?.orderCount}
                      </h3>
                    </div>
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Balance
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        ₹
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            : status === "blocked"
            ? blockedUsers &&
              blockedUsers?.map((item, index) => (
                <div className="bg-white w-[200px] h-max rounded-[10px] p-3">
                  {/* <div className="flex justify-between">
                <input
                  type="checkbox"
                  name=""
                  id="termsCheckbox"
                  className="cursor-pointer"
                />
                <BsThreeDotsVertical size={20} className="cursor-pointer" />
              </div> */}
                  <div className="w-full flex flex-col gap-2 items-center">
                    <div className="w-[79px] h-[79px] rounded-full bg-[#E0E2E7]">
                      <img
                        src={item?.avatar}
                        alt=""
                        className="object-contain rounded-full"
                      />
                    </div>
                    <h3 className="font-[Montserrat] font-[600] text-[14px]">
                      {item?.name}
                    </h3>
                    <div className="flex gap-1 px-1 py-0.5 shadow-sm border-1 border-[#3C3C3C] rounded-[16px] bg-[#d0d5dd]">
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5 text-[#40890F] bg-green-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 text-[#D11F1F] bg-red-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Blocked
                        </div>
                      )}
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5  rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          block
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          unblock
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="border-[#C2C6CE] border-dashed border-[1px] mt-4"></div>
                  <div className=" mt-4 flex justify-between">
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Orders
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        {item?.orderCount}
                      </h3>
                    </div>
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Balance
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        ₹
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            : status === "search"
            ? searchData &&
              searchData?.map((item, index) => (
                <div className="bg-white w-[200px] h-max rounded-[10px] p-3">
                  {/* <div className="flex justify-between">
                <input
                  type="checkbox"
                  name=""
                  id="termsCheckbox"
                  className="cursor-pointer"
                />
                <BsThreeDotsVertical size={20} className="cursor-pointer" />
              </div> */}
                  <div className="w-full flex flex-col gap-2 items-center">
                    <div className="w-[79px] h-[79px] rounded-full bg-[#E0E2E7]">
                      <img
                        src={item?.avatar}
                        alt=""
                        className="object-contain rounded-full"
                      />
                    </div>
                    <h3 className="font-[Montserrat] font-[600] text-[14px]">
                      {item?.name}
                    </h3>
                    <div className="flex gap-1 px-1 py-0.5 shadow-sm border-1 border-[#3C3C3C] rounded-[16px] bg-[#d0d5dd]">
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5 text-[#40890F] bg-green-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 text-[#D11F1F] bg-red-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Blocked
                        </div>
                      )}
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5  rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          block
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          unblock
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="border-[#C2C6CE] border-dashed border-[1px] mt-4"></div>
                  <div className=" mt-4 flex justify-between">
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Orders
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        {item?.orderCount}
                      </h3>
                    </div>
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Balance
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        ₹
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            : allUsers &&
              allUsers?.map((item, index) => (
                <div className="bg-white w-[200px] h-max rounded-[10px] p-3">
                  {/* <div className="flex justify-between">
                <input
                  type="checkbox"
                  name=""
                  id="termsCheckbox"
                  className="cursor-pointer"
                />
                <BsThreeDotsVertical size={20} className="cursor-pointer" />
              </div> */}
                  <div className="w-full flex flex-col gap-2 items-center">
                    <div className="w-[79px] h-[79px] rounded-full bg-[#E0E2E7]">
                      <img
                        src={item?.avatar}
                        alt=""
                        className="object-contain rounded-full"
                      />
                    </div>
                    <h3 className="font-[Montserrat] font-[600] text-[14px]">
                      {item?.name}
                    </h3>
                    <div className="flex gap-1 px-1 py-0.5 shadow-sm border-1 border-[#3C3C3C] rounded-[16px] bg-[#d0d5dd]">
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5 text-[#40890F] bg-green-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 text-[#D11F1F] bg-red-200 rounded-[16px] font-[Montserrat] font-[600] text-[14px]`}
                        >
                          Blocked
                        </div>
                      )}
                      {item?.blocked === false ? (
                        <div
                          className={`py-0.5 px-1.5  rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          block
                        </div>
                      ) : (
                        <div
                          className={`py-0.5 px-1.5 rounded-[16px] font-[Montserrat] font-[600] text-[14px] cursor-pointer`}
                          onClick={() => handleStatusUpdate(item._id)}
                        >
                          unblock
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="border-[#C2C6CE] border-dashed border-[1px] mt-4"></div>
                  <div className=" mt-4 flex justify-between">
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Orders
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        {item?.orderCount}
                      </h3>
                    </div>
                    <div className="flex flex-col items-center flex-1">
                      <h3 className="font-[Montserrat] font-[400] text-[12px] text-[#667085]">
                        Balance
                      </h3>
                      <h3 className="font-[Montserrat] font-[500] text-[12px]">
                        ₹
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default AdminCustomerList;
