import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import {
  AiFillStar,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineStar,
} from "react-icons/ai";
import { LiaHeart, LiaHeartSolid, LiaShippingFastSolid } from "react-icons/lia";
import { IoMailOutline } from "react-icons/io5";
import { TbBrandVisa, TbRulerMeasure } from "react-icons/tb";
import { GrAmazon } from "react-icons/gr";
import { FaApplePay, FaGooglePay } from "react-icons/fa";
import "../../styles/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../redux/actions/cart";
import { Link, useNavigate } from "react-router-dom";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import Ratings from "./Ratings";
import axios from "axios";
import { Popover, Typography } from "@mui/material";

const ProductDetails = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const [click, setClick] = useState(false);
  const [count, setCount] = useState(1);
  const [column, setColumn] = useState("description");
  const [select, setSelect] = useState(0);
  const [reviews, setReviews] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [sizeAnchorEl, setSizeAnchorEl] = useState(null);
  const handlePopoverClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };
  const handlePopoverClickSize = (event) => {
    setSizeAnchorEl(event.currentTarget);
  };
  const handleProfileSize = () => {
    setSizeAnchorEl(null);
  };
  const openProfile = Boolean(profileAnchorEl);
  const openSizeGuide = Boolean(sizeAnchorEl);
  const idProfile = openProfile ? "simple-popoverProfile" : undefined;
  const idSizeGuide = openSizeGuide ? "simple-popoverProfile" : undefined;

  useEffect(() => {
    if (data?.variants?.length) {
      const lowestPriceVariant = data?.variants?.reduce((min, variant) => {
        const price = parseInt(variant.price, 10);
        const minPrice = parseInt(min.price, 10);
        return price < minPrice ? variant : min;
      }, data?.variants[0]);
      setSelectedSize(lowestPriceVariant);
    }
  }, [data]);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, price: selectedSize.price, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const buyNowHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      navigate("/cart");
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, price: selectedSize.price, qty: count };
        dispatch(addTocart(cartData));
        navigate("/cart");
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const getReviews = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}/review/get-product-review/${id}`
    );
    setReviews(data.reviews);
    setColumn("reviews");
  };

  return (
    <div>
      <div className={`${styles.container}`}>
        {data ? (
          <div className="flex flex-col gap-5 py-[50px] w-full">
            <div className="flex 800px:flex-row flex-col w-full gap-10">
              <div className="800px:w-[25%] w-full h-[432px] flex flex-col justify-center items-center border-solid border-[1px] border-[#ECECEC]">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}${data.images[select]}`}
                  alt=""
                  className="w-[336px] h-[248px] object-contain"
                />
                <div className="flex gap-3 w-full mt-3 overflow-x-scroll">
                  {data &&
                    data.images.map((i, index) => (
                      <img
                        src={`${process.env.REACT_APP_BUCKET_URL}${i}`}
                        alt=""
                        className={`${
                          select === index
                            ? "border-[1px] border-[#000]"
                            : "null"
                        } w-[100px] h-[100px] object-contain cursor-pointer`}
                        onClick={() => setSelect(index)}
                      />
                    ))}
                </div>
              </div>
              <div className="flex flex-col gap-2 800px:w-[75%] w-full">
                <h3 className="font-Poppins font-[500] text-[24px]">
                  {/* Organic Herbal Green Tea */}
                  {data.name}
                </h3>
                <h6 className="font-Poppins font-[400] text-[14px]">
                  Availability:{" "}
                  <span className="text-[#40890F]">
                    {data.stock} left in stock
                  </span>
                </h6>
                <hr className="border-black" />
                <div className="flex">
                  <Ratings rating={data?.ratings} />
                  <p className="font-Poppins ml-2 font-[300] text-[14px]">
                    {data.reviews.length} reviews
                  </p>
                </div>
                <hr />
                <h2 className="font-Poppins font-[600] text-[30px] text-[#40890F]">
                  {/* ₹190 */}₹ {selectedSize?.price * count}
                </h2>
                <hr />
                <p className="font-Poppins font-[300] text-[12px]">
                  {data.description}
                </p>
                <div className="flex gap-2">
                  <h6 className="font-[400] text-[16px]">Size:</h6>
                  {data?.variants.map((item, index) => (
                    <button
                      className={`font-Poppins font-[400] text-[14px] ${
                        selectedSize?._id == item?._id
                          ? "bg-[#40890F] text-white"
                          : "bg-[#ECECEC] text-[#3C3C3C]"
                      } w-[53px] h-[29px] rounded-lg`}
                      onClick={() => setSelectedSize(item)}
                    >
                      {item.size + item.unit}
                    </button>
                  ))}
                </div>
                <div className="flex 800px:gap-5 gap-2 800px:flex-row flex-col">
                  <div className="flex gap-1 items-center" onClick={handlePopoverClickSize}>
                    <TbRulerMeasure size={20} />
                    <p className="font-Poppins font-[300] text-[12.69] cursor-pointer">
                      Size Guide
                    </p>
                  </div>
                  <Popover
                    id={idSizeGuide}
                    open={openSizeGuide}
                    anchorEl={sizeAnchorEl}
                    onClose={handleProfileSize}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{ zIndex: 10000, top: 5 }}
                    slotProps={{
                      paper: {
                        sx: {
                          overflow: "visible",
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 28,
                            width: 10,
                            height: 10,
                            backgroundColor: "#fff",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            transform: "translateY(-50%) rotate(45deg)",
                            boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                          },
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        px: 2,
                        py: 1,
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        border: "1px solid black"
                      }}
                    >
                      Choose the weight you want from above
                    </Typography>
                  </Popover>
                  <div className="flex gap-1 items-center" onClick={handlePopoverClick}>
                    <LiaShippingFastSolid size={20} />
                    <p className="font-Poppins font-[300] text-[12.69] cursor-pointer">
                      Shipping
                    </p>
                  </div>
                  <Popover
                    id={idProfile}
                    open={openProfile}
                    anchorEl={profileAnchorEl}
                    onClose={handleProfileClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{ zIndex: 10000, top: 5, }}
                    slotProps={{
                      paper: {
                        sx: {
                          overflow: "visible",
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 28,
                            width: 10,
                            height: 10,
                            backgroundColor: "#fff",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            transform: "translateY(-50%) rotate(45deg)",
                            boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                          },
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        px: 2,
                        py: 1,
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        border: "1px solid black",
                      }}
                    >
                      Purchase above 500 to get free shipping!
                    </Typography>
                  </Popover>
                  <Link to="/contact">
                    <div className="flex gap-1 items-center">
                      <IoMailOutline size={20} />
                      <p className="font-Poppins font-[300] text-[12.69]">
                        Ask about this product
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="flex gap-4">
                  <div className="w-[75px] h-[36px] flex justify-between items-center border-solid border-[1px] rounded-lg border-[#3C3C3C]">
                    <AiOutlineLeft
                      size={20}
                      onClick={decrementCount}
                      className="cursor-pointer"
                    />
                    {count}
                    <AiOutlineRight
                      size={20}
                      onClick={incrementCount}
                      className="cursor-pointer"
                    />
                  </div>
                  <button
                    onClick={() => addToCartHandler(data._id)}
                    className="text-[#fff] bg-[#3C3C3C] border-[1.5px] border-[#3C3C3C] rounded-lg w-[218px] h-[36px] font-Poppins font-[12px] cursor-pointer"
                  >
                    Add To Cart
                  </button>
                  <div className="w-[43px] h-[36px] rounded-lg bg-[#3C3C3C] cursor-pointer flex justify-center items-center">
                    {click === false ? (
                      <LiaHeart
                        size={25}
                        className="text-[#fff] cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        title="add to wishlist"
                      />
                    ) : (
                      <LiaHeartSolid
                        size={25}
                        className="text-[#fff] cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        title="remove from wishlist"
                      />
                    )}
                    {/* <LiaHeart size={25} className="text-[#fff]" /> */}
                  </div>
                </div>
                {/* <Link to="/cart"> */}
                <button
                  onClick={() => buyNowHandler(data._id)}
                  className="text-[#fff] bg-[#40890F] rounded-lg max-w-[368px] h-[36px] font-Poppins font-[12px] cursor-pointer"
                >
                  Buy It Now
                </button>
                {/* </Link> */}
                <h6 className="font-Poppins font-[400] text-[16px]">
                  Guaranteed safe checkout
                </h6>
                <fiv className="flex gap-5">
                  <div className="w-[48px] h-[28px] flex justify-center items-center border-solid border-[1px] rounded-lg border-[#ECECEC]">
                    <GrAmazon size={20} />
                  </div>
                  <div className="w-[48px] h-[28px] flex justify-center items-center border-solid border-[1px] rounded-lg border-[#ECECEC]">
                    <FaApplePay size={30} />
                  </div>
                  <div className="w-[48px] h-[28px] flex justify-center items-center border-solid border-[1px] rounded-lg border-[#ECECEC]">
                    <FaGooglePay size={30} />
                  </div>
                  <div className="w-[48px] h-[28px] flex justify-center items-center border-solid border-[1px] rounded-lg border-[#ECECEC]">
                    <TbBrandVisa size={30} />
                  </div>
                </fiv>
              </div>
            </div>

            <div className="flex flex-col w-full border-solid border-[1px] border-[#ECECEC]">
              <div className="400px:h-[50px] h-[105px] bg-[#ECECEC] flex 400px:flex-row flex-col justify-around items-center">
                <h4
                  className={`${
                    column === "description" ? "text-[#40890F]" : "text-[#000]"
                  } font-Poppins font-[500] text-[15px] uppercase prodNav relative cursor-pointer`}
                  onClick={() => setColumn("description")}
                >
                  Description
                </h4>
                <h4
                  className={`${
                    column === "reviews" ? "text-[#40890F]" : "text-[#000]"
                  } font-Poppins font-[500] text-[15px] uppercase prodNav relative cursor-pointer`}
                  onClick={() => getReviews(data._id)}
                >
                  Reviews
                </h4>
                <h4
                  className={`${
                    column === "shipping policy"
                      ? "text-[#40890F]"
                      : "text-[#000]"
                  } font-Poppins font-[500] text-[15px] uppercase prodNav relative cursor-pointer`}
                  onClick={() => setColumn("shipping policy")}
                >
                  Shipping policy
                </h4>
              </div>
              <div className="min-h-[210px] p-7">
                {column === "description" && (
                  <p className="font-Poppins font-[300] text-[13px] ">
                    {data.description}
                  </p>
                )}
                {column === "reviews" && (
                  <>
                    {reviews &&
                      reviews.map((item, index) => (
                        <div className="flex flex-col gap-1 pb-3">
                          <div className="flex gap-2 items-center">
                            <h4 className="font-Poppins font-[500] text-[16px]">
                              {item.userName}
                            </h4>
                            <Ratings rating={item?.rating} />
                          </div>
                          <h6 className="font-Poppins font-[400] text-[15px]">
                            {item.comment}
                          </h6>
                        </div>
                      ))}
                    <div className="w-full flex justify-center">
                      {reviews && reviews.length === 0 && (
                        <h5>No Reviews found for this product!</h5>
                      )}
                    </div>
                  </>
                )}
                {column === "shipping policy" && (
                  <p className="font-Poppins font-[300] text-[13px]">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Deserunt eum, molestias sed et repudiandae perferendis
                    labore incidunt eos voluptates sunt, vel reiciendis eveniet
                    magni praesentium explicabo expedita veritatis neque. Quos
                    sint accusantium provident eum cupiditate quam officiis,
                    illum distinctio quod alias illo. Recusandae autem tempore,
                    expedita saepe ratione, non quod quidem tenetur eum neque
                    exercitationem sequi fugiat, voluptatum sit necessitatibus
                    quos accusantium cupiditate laudantium rerum odit explicabo
                    minus ad repellendus odio! Quo consequuntur aut at quia
                    delectus tempore, ducimus numquam, ipsam voluptatem debitis
                    explicabo! Minus et obcaecati reprehenderit cumque
                    dignissimos aut fuga odio ipsam nulla eligendi ab voluptates
                    tempora saepe iste, maiores quibusdam molestiae dolore
                    eaque? Distinctio ducimus exercitationem earum consectetur
                    hic quae consequuntur nostrum illum id pariatur temporibus
                    eveniet nobis labore obcaecati, corporis cupiditate maiores
                    quaerat alias! Accusantium nulla praesentium saepe ea, quo
                    consequuntur autem facilis expedita! Odio adipisci ipsum
                    repudiandae natus similique quod tenetur impedit.
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductDetails;
