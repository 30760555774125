import React from "react";
import Image1 from "../../assets/history1.png";
import Image2 from "../../assets/history2.png";
import styles from "../../styles/styles";

const About = () => {
  return (
    <div className="w-full bg-[#F4F3F3]">
      <div
        className={`${styles.container} !py-[0] flex flex-col md:flex-row items-center gap-20 `}
      >
        <div className="md:w-1/2 flex flex-col gap-3 justify-center">
          <h4 className="font-Poppins font-[500] text-[30px] 800px:mt-0 mt-3">
            Our History
          </h4>
          <p className="font-Poppins font-[300] text-[15px] text-justify">
            THE CHAI WALAH is founded by Faisal Yousaf AKA Mr.Chai Walah while
            he was living in England, where he worked as a certified Barista in
            the speciality coffee industry and ran an independent coffee
            roastery in trendy East London. His love affair with caffeine filled
            coffee, tea and passion for spices, herbs his Indian heritage and
            nostalgia of morning chai rituals he used to have in his childhood
            back in India led him to experiment with chai to find roots back to
            India.
          </p>
        </div>
        <div className="md:w-1/2 h-[544px] flex justify-center">
          <img src={Image1} alt="" className="h-[100%] object-contain" />
        </div>
      </div>
      <div className="w-full bg-white h-10"></div>
      <div
        className={`${styles.container} !py-[0] flex flex-col md:flex-row items-center gap-20 `}
      >
        <div className="md:w-1/2 h-[544px] flex justify-center">
          <img src={Image2} alt="" className="h-[100%] object-contain" />
        </div>
        <div className="md:w-1/2">
          <p className="font-Poppins font-[300] text-[15px] text-justify 800px:mb-0 mb-3">
            Coming back home after being an expact for so long and gallivanting
            around, he was inspired from his travels through hidden tea rooms,
            vibrant night markets, highland plantations and nomadic tents, where
            he discovered real tea. In these distant lands, where traditional
            recipes are handed down from generation to generation, the knowledge
            of spices and preperations lived on for centuries. He missed all
            that since being back and could not find anything similar, so he
            brought all that tea knowledge home to bring those old flavoures to
            the new world which led to a humble beginnings of "THE CHAI WALAH"
            from a custom built market cart in a village street of southern
            India.
          </p>
        </div>
      </div>
      <div className="w-full bg-white pt-10">
        <div
          className={`${styles.container} flex flex-col md:flex-row py-16 gap-20`}
        >
          <div className="md:w-1/2 flex flex-col gap-3 items-start">
            <h4 className="font-Poppins font-[500] text-[30px]">Mission</h4>
            <p className="font-Poppins font-[300] text-[15px] text-justify">
              To provide aspiring entrepreneurs an opportunity to get in
              entrepreneurship by becoming our franchisee and create job
              oppotunities and skill development in entrepreneurship for the
              youth within their local communities.
            </p>
          </div>
          <div className="md:w-1/2 flex flex-col gap-3 items-start">
            <h4 className="font-Poppins font-[500] text-[30px]">Vision</h4>
            <p className="font-Poppins font-[300] text-[15px] text-justify">
              To revolutionise the way how India drinks chai from a road side
              tea stall by serving up the most hygienic cup of chai on-the-go
              and disrupt the industry with cutting edge tech .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
