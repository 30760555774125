import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Wishlist = ({ setIsVisible }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
    setIsVisible(false);
  };
  return (
    <div className="w-[250px] flex flex-col">
      <div className="flex flex-col h-[11vh]">
        <div className="h-full px-3 flex justify-between items-center">
          <div className={`flex items-center`}>
            <AiOutlineHeart size={25} />
            <h5 className="pl-2 text-[20px] font-[500]">
              {wishlist && wishlist.length === 0 ? (
                <h5>Wishlist</h5>
              ) : (
                <h5>{wishlist.length} items</h5>
              )}
            </h5>
          </div>
          <RxCross1
            size={25}
            className="cursor-pointer"
            onClick={() => setIsVisible(false)}
          />
        </div>
      </div>
      {/* cart single items */}
      <div className="w-full border-t">
        {wishlist?.length > 0 ? (
          wishlist &&
          wishlist.map((i, index) => (
            <CartSingle
              key={index}
              data={i}
              removeFromWishlistHandler={removeFromWishlistHandler}
              addToCartHandler={addToCartHandler}
            />
          ))
        ) : (
          <h1 className="p-2">Wishlist Items is empty!</h1>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value, setValue] = useState(1);
  const totalPrice = data?.variants[0]?.price * value;
  return (
    <div className="border-b p-4">
      <div className="w-full flex items-center">
        <RxCross1
          className="cursor-pointer text-[25px]" 
          onClick={() => removeFromWishlistHandler(data)}
        />
        <img
          src={`${process.env.REACT_APP_BUCKET_URL}${data?.images[0]}`}
          alt=""
          className="w-[80px] h-[80px] object-contain ml-2"
        />

        <Link to={`/product/${data._id}`} className="pl-[5px] w-[300px]">
          {/* <div className='pl-[5px] w-[300px]'> */}
          <h1 className="font-[600] text-[17px] truncate">{data.name}</h1>
          <h4 className="font-[600] text-[15px] pt-[3px] text-[#d02222] font-Roboto">
            ₹{totalPrice}
          </h4>
          {/* </div> */}
        </Link>

        <div className="flex justify-end">
          <MdOutlineAddShoppingCart
            size={20}
            onClick={() => addToCartHandler(data)}
            className="cursor-pointer"
            title="Add to cart"
          />
        </div>
      </div>
    </div>
  );
};
export default Wishlist;
