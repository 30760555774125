import React from 'react'
import AdminSidebar from '../../components/Admin/Layout/AdminSidebar'
import AdminHeader from '../../components/Admin/Layout/AdminHeader'
import AdminProfileSettingsPassword from '../../components/Admin/AdminProfileSettingsPassword.jsx'

const AdminSettingsPasswordPage = () => {
  return (
    <div className='flex'>
        <AdminSidebar activeHeading={8} />
        <div className="flex flex-col ml-[20vw]">
            <AdminHeader />
            <AdminProfileSettingsPassword />
        </div>
    </div>
  )
}

export default AdminSettingsPasswordPage     