import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import App from './App.js';
import Store from './redux/store';

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();
