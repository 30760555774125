import React, { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { contactFormSchema } from "../../schema/valiationSchema";
import styles from "../../styles/styles";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#3C3C3C",
    },
  },
});

const ContactForm = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e, i) => {
    setLoading(true);
    const config = { headers: { "Content-Type": "application/json" } };

    const newForm = new FormData();

    newForm.append("name", e.name);
    newForm.append("email", e.email);
    newForm.append("subject", e.subject);
    newForm.append("message", e.message);

    axios
      .post(
        `https://script.google.com/macros/s/AKfycbzex3D5lh9ojisy9-RDBZ9ZUZiXRKo2_U-a1CDNxoUguM5gmyhO1j6XO7LV-Uklb7FKig/exec`,
        newForm,
      )
      .then((res) => {
        setLoading(false);
        toast.success('message sent successfully');
        i.resetForm();
      })
      .catch((error) => {
        setLoading(false);
        toast.error('error occured');
        console.log(error)
      });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        subject: "",
        message: "",
      }}
      validationSchema={contactFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
        <div className={`${styles.container}`}>
          <Form className="flex flex-col gap-3 items-center w-full">
            <h2 className="font-Poppins font-[500] 400px:text-[30px] text-[24px]">
              Ask us anything here
            </h2>
            <div className="flex gap-3 w-full">
              <div className="flex flex-col w-full">
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  value={values.name}
                  onChange={handleChange}
                  className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 bg-[#F4F3F3]"
                  placeholder="Name *"
                />
                <ErrorMessage name="name">
                  {(msg) => (
                    <div className="w-full flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red]">
                      * {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className="flex flex-col w-full">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 bg-[#F4F3F3]"
                  placeholder="Email *"
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <div className="w-full flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red]">
                      * {msg}
                    </div>
                  )}
                </ErrorMessage>
              </div>
            </div>
            <input
              type="text"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              className="w-full h-[53px] border-[1.5px] border-[#3C3C3C40] px-3 bg-[#F4F3F3]"
              placeholder="Subject *"
            />
            <textarea
              name="message"
              value={values.message}
              onChange={handleChange}
              id=""
              cols="30"
              rows="7"
              placeholder="Message *"
              className="w-full border-[1.5px] focus:border-[#3C3C3C40] border-[#3C3C3C40] px-3 bg-[#F4F3F3] py-3"
            ></textarea>
            <ErrorMessage name="message">
              {(msg) => (
                <div className="w-full flex items-center gap-1 font-Poppins font-[400] text-[14px] text-left px-2 py-0.5 rounded-md text-[red]">
                  * {msg}
                </div>
              )}
            </ErrorMessage>
            <button
              type="submit"
              className={
                loading
                  ? `${styles.LoadingBtn} !bg-transparent !border-transparent`
                  : `${styles.button1} !text-[20px]`
              }
            >
              {!loading ? (
                <h1>Send Message</h1>
              ) : (
                <ThemeProvider theme={customTheme}>
                  <CircularProgress color="primary" size="1.5rem" />
                </ThemeProvider>
              )}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ContactForm;
