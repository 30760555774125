import axios from "axios";

// create product
export const createProduct = (newForm) => async (dispatch) => {
    try {
      dispatch({
        type: "productCreateRequest",
      });

      const config = {headers: {"Content-Type":"multipart/form-data"}};

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER}/product/create-product`,
        newForm,
        {withCredentials: true},
        config,
      );
      dispatch({
        type: "productCreateSuccess",
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: "productCreateFail",
        payload: error?.response?.data?.message,
      });
    }
  };

  // get All Products 
  export const getAllProductsAdmin = () => async (dispatch) => {
    try {
      dispatch({
        type: "getAllProductsAdminRequest",
      });
  
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/product/get-all-products-admin`,
        {withCredentials: true}
      );
      dispatch({
        type: "getAllProductsAdminSuccess",
        payload: data.products,
      });
    } catch (error) {
      dispatch({
        type: "getAllProductsAdminFailed",
        payload: error?.response?.data?.message,
      });
    }
  };

    // delete product of a shop
    export const deleteProduct = (id) => async (dispatch) => {
    try {
      dispatch({
        type: "deleteProductRequest",
      });
  
      const { data } = await axios.delete(
        `${process.env.REACT_APP_SERVER}/product/delete-product/${id}`,
        {
          withCredentials: true,
        }
      );
  
      dispatch({
        type: "deleteProductSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "deleteProductFailed",
        payload: error?.response?.data?.message,
      });
    }
  };

 // get all products
 export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });
    
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess", 
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error?.response?.data?.message,
    });
  }
};


