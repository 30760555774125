import React from 'react'
import ShippingMethod from '../components/Checkout/ShippingMethod.jsx'
import CheckoutSteps from '../components/Checkout/CheckoutSteps.jsx'
import CartItems from '../components/Checkout/CartItems.jsx'
import MobileCartItems from '../components/Checkout/MobileCartItems.jsx'

const ShippingPage = () => {
  return (
    <div className={`flex w-full h-screen`}>
      {/* left side */}
      <div className="800px:w-[60%] w-full h-screen bg-white p-10">
      <div className='flex justify-between'>
        <CheckoutSteps active={"shipping"} />
        <MobileCartItems />
      </div>
        <ShippingMethod />
      </div>
      {/* right side */}
      <CartItems />
    </div>
  )
}

export default ShippingPage