import React, { useState } from "react";
import Chai1 from "../../../assets/oolong.png";
import Chai2 from "../../../assets/tisane.png";
import styles from "../../../styles/styles";

const SpecialChai = () => {
  const [seeMore, setSeeMore] = useState(false);

  const handleClick = () => {
    setSeeMore(!seeMore);
  };
  return (
    <div className={`${styles.container} pb-[50px]`}>
      <div className="flex 800px:flex-row flex-col justify-between px-5 800px:px-0">
        <div className="w-full 800px:w-[40%] flex justify-center">
          <img
            src={Chai1}
            alt=""
            className="object-contain 800px:w-full 800px:h-full w-[60%]"
          />
        </div>
        <div className="w-full 800px:w-[55%] flex flex-col justify-center">
          <h2 className="font-Poppins font-[600] 800px:text-[40px] text-[30px]">
            Signature Chai
          </h2>
          <p className="font-Poppins font-[300] text-[14px] text-justify">
            Experience the epitome of tea excellence with our Signature Chai, a
            carefully curated blend that transcends the ordinary. Our expertly
            crafted infusion combines the finest tea leaves with a symphony of
            aromatic spices, creating a sensory delight. The robust flavor
            profile unfolds layers of warmth, awakening the senses with every
            sip. From the first fragrant note to the lingering finish, our
            Signature Chai is a journey through exquisite taste. Elevate your
            tea ritual with this extraordinary blend, an embodiment of
            craftsmanship and passion, delivering a transformative tea
            experience that lingers on the palate and enriches your tea-loving
            moments.
          </p>
        </div>
      </div>
      <div className="flex 800px:flex-row flex-col-reverse justify-between px-5 800px:px-0">
        <div className="w-full 800px:w-[55%] flex flex-col justify-center">
          <h2 className="font-Poppins font-[600] 800px:text-[40px] text-[30px]">
            Indian Masala Chai
          </h2>
          <p className="font-Poppins font-[300] text-[14px] text-justify">
            Embark on a sensory journey with our authentic Indian Masala Chai, a
            tribute to the rich tapestry of flavors that define traditional
            chai. Handcrafted with a symphony of robust Assam tea leaves, warm
            spices like cardamom, cinnamon, and cloves, our blend is an aromatic
            embrace of India's chai culture. Simmered to perfection, the
            infusion unfolds layers of bold flavor, offering a harmonious
            balance of spice and sweetness. Indulge in the warmth and nostalgia
            of this age-old recipe, an invitation to savor the heritage and
            warmth of Indian tea rituals. Experience the soulful comfort of our
            Indian Masala Chai in every rejuvenating cup.
          </p>
        </div>
        <div className="w-full 800px:w-[40%] flex justify-center">
          <img
            src={Chai2}
            alt=""
            className="object-contain 800px:w-full 800px:h-full w-[60%]"
          />
        </div>
      </div>
      <div
        className={`${
          seeMore ? "block" : "hidden"
        } flex 800px:flex-row flex-col justify-between px-5 800px:px-0`}
      >
        <div className="w-full 800px:w-[40%] flex justify-center">
          <img
            src={Chai1}
            alt=""
            className="object-contain 800px:w-full 800px:h-full w-[60%]"
          />
        </div>
        <div className="w-full 800px:w-[55%] flex flex-col justify-center">
          <h2 className="font-Poppins font-[600] 800px:text-[40px] text-[30px]">
            Original Kadak chai
          </h2>
          <p className="font-Poppins font-[300] text-[14px] text-justify">
            Sip the bold essence of our Original Kadak Chai, an invigorating
            brew inspired by the streets of India. Crafted with robust Assam tea
            leaves, ground spices, and a touch of jaggery, each cup is a
            celebration of authentic flavor. Experience the robustness that
            defines true kadak chai, delivering an energizing kick and a
            symphony of aromatic spices. Our signature blend captures the spirit
            of roadside chaiwalas, promising a soul-stirring experience. Unleash
            the true kadak essence in every sip, a perfect blend that stands as
            a testament to the heart and vigor of India's cherished chai culture
          </p>
        </div>
      </div>
      <div
        className={`${
          seeMore ? "block" : "hidden"
        } flex 800px:flex-row flex-col-reverse justify-between px-5 800px:px-0`}
      >
        <div className="w-full 800px:w-[55%] flex flex-col justify-center">
          <h2 className="font-Poppins font-[600] 800px:text-[40px] text-[30px]">
            Banarasi paan Chai
          </h2>
          <p className="font-Poppins font-[300] text-[14px] text-justify">
            Introducing our Banarasi Paan Chai, a unique fusion that pays homage
            to the vibrant flavors of Banaras. Immerse yourself in the essence
            of paan, blended with robust Assam tea leaves, creating a melody of
            tastes. Infused with betel leaves, cardamom, and sweetened with a
            hint of gulkand, this chai is an aromatic journey through the
            gullies of Banaras. The sweet and spicy notes harmonize, offering a
            symphony of flavors that captivates the palate. Indulge in the
            cultural richness of Banaras with each fragrant sip, as our Banarasi
            Paan Chai transcends tradition, delivering an extraordinary chai
            experience.
          </p>
        </div>
        <div className="w-full 800px:w-[40%] flex justify-center">
          <img
            src={Chai2}
            alt=""
            className="object-contain 800px:w-full 800px:h-full w-[60%]"
          />
        </div>
      </div>
      <div
        className={`${
          seeMore ? "block" : "hidden"
        } flex 800px:flex-row flex-col justify-between px-5 800px:px-0`}
      >
        <div className="w-full 800px:w-[40%] flex justify-center">
          <img
            src={Chai1}
            alt=""
            className="object-contain 800px:w-full 800px:h-full w-[60%]"
          />
        </div>
        <div className="w-full 800px:w-[55%] flex flex-col justify-center">
          <h2 className="font-Poppins font-[600] 800px:text-[40px] text-[30px]">
            Wayanadan cardomom and rose chai
          </h2>
          <p className="font-Poppins font-[300] text-[14px] text-justify">
            Discover the enchanting allure of our Wavanadan Cardamom and Rose
            Chai, a symphony of flavors inspired by the gardens of Wayanad.
            Delicately blended with the warmth of cardamom and the fragrant
            essence of roses, this chai offers a sensory escape. Handpicked
            Assam tea leaves provide a robust foundation, creating a harmonious
            infusion. Immerse yourself in the rich cultural tapestry of Wayanad
            with every sip – a dance of floral notes and aromatic spices.
            Indulge in the exquisite balance of tradition and innovation, as our
            Wavanadan Cardamom and Rose Chai transports you to the serene
            landscapes of Wayanad, encapsulating its essence in a single cup.
          </p>
        </div>
      </div>
      <div className="flex justify-center w-full mt-5">
        <button className={`${styles.button1}`} onClick={handleClick}>
          {seeMore ? "See less" : "See more"}
        </button>
      </div>
    </div>
  );
};

export default SpecialChai;
