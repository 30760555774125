import axios from "axios";
import React, { useEffect, useState } from "react";
import { GrUploadOption } from "react-icons/gr";
import { TiDelete } from "react-icons/ti";
import { ErrorMessage, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { newsFormSchema } from "../../schema/valiationSchema";
import { BiError } from "react-icons/bi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AdminEditNews = ({ id, data }) => {
  const navigate = useNavigate();

  const { admin } = useSelector((state) => state.admin);
  const [images, setImages] = useState(data && data.images);
  const [newImages, setNewImages] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [open, setOpen] = React.useState(false);
  const [idx, setIdx] = useState("");
  const [img, setImg] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      setImages(data.images);

      setInitialValues((preInitialValues) => ({
        ...preInitialValues,
        title: data && data.title,
        description: data && data.description,
      }));
    }
  }, [data]);

  const handleImageChange = (e) => {
    e.preventDefault();

    const fileInput = e.target;
    const maxFiles = 1;

    if (images.length + newImages?.length + fileInput.files.length > maxFiles) {
      alert(`You can only select upto ${maxFiles} files!`);
      fileInput.value = "";
    } else {
      const files = Array.from(e.target.files);
      setNewImages((prevImages) => [...prevImages, ...files]);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleDeleteImage1 = async (index, img) => {
    handleClose();
    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.put(
        `${process.env.REACT_APP_SERVER}/press/delete-news-image/${id}?img=${img}`,
        {
          withCredentials: true,
        },
        config
      );
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteImage2 = (index) => {
    const updatedImages = [...newImages];
    updatedImages.splice(index, 1);
    setNewImages(updatedImages);
  };

  const handleUpdateBtn = async (e) => {
    if (images?.length > 0 || newImages?.length > 0) {
      if (
        admin?.role === "Master Admin" ||
        admin?.permissions.includes("updateNews")
      ) {
        const formData = new FormData();

        newImages.forEach((image) => {
          formData.append("images", image);
        });
        formData.append("title", e.title);
        formData.append("description", e.description);
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        };
        const title = e.title;
        const description = e.description;
        await axios
          .put(
            `${process.env.REACT_APP_SERVER}/press/update-news/${id}`,
            formData,
            config
          )
          .then((res) => {
            toast.success(res.data.message);
            setImages([]);
            navigate("/admin-press");
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      } else {
        toast.error("Unauthorized");
      }
    } else {
      toast.error("Image is required");
    }
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">PRESS</h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={newsFormSchema}
            enableReinitialize={true}
            onSubmit={handleUpdateBtn}
          >
            {({ values, handleChange, errors }) => (
              <Form className="mt-[20px] flex flex-col gap-4">
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    <span className="text-[#40890F]">*</span> Title :
                  </label>
                  <div className="flex flex-col w-[80%]">
                    <input
                      type="text"
                      name="title"
                      value={values.title}
                      className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-[70%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                    />
                    <ErrorMessage name="title">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    <span className="text-[#40890F]">*</span> Description :
                  </label>
                  <div className="flex flex-col w-[80%]">
                    <textarea
                      type="text"
                      rows="4"
                      name="description"
                      value={values.description}
                      className="rounded-[8px] px-3 py-2 border border-gray-300 w-[70%] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      onChange={handleChange}
                    />
                    <ErrorMessage name="description">
                      {(msg) => (
                        <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                          * {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <label className="font-[Montserrat] font-[500] text-[16px]">
                    <span className="text-[#40890F]">*</span> Upload Images :
                  </label>
                  <div className="flex items-center flex-wrap gap-2 bg-[#E5E5E5] rounded-[10px] px-2 py-1 w-[70%]">
                    <input
                      type="file"
                      name=""
                      id="upload"
                      className="hidden"
                      multiple
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                    <label htmlFor="upload">
                      <GrUploadOption
                        size={30}
                        className="cursor-pointer"
                        title="add product"
                      />
                    </label>
                    {images &&
                      images.map((i, index) => (
                        <div className="relative">
                          {/* <img
                        src={URL.createObjectURL(i)}
                        alt=""
                        key={i}
                        className="h-[120px] w-[120px] m-2 object-contain"
                      /> */}
                          <img
                            // src={`${process.env.REACT_APP_BACKEND_URL}/${i}`}
                            src={`${process.env.REACT_APP_BUCKET_URL}${i}`}
                            alt=""
                            key={i}
                            className="h-[120px] w-[120px] m-2 object-contain"
                          />
                          <TiDelete
                            size={25}
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 cursor-pointer"
                            // onClick={() => handleDeleteImage(index)}
                            onClick={() => {handleClickOpen();setIdx(index);setImg(i)}}
                          />

                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Please confirm to delete the image?"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Deleted image cannot be discarded
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button
                                onClick={() => handleDeleteImage1(idx, img)}
                                autoFocus
                              >
                                Confirm
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      ))}
                      {newImages &&
                          newImages?.map((i, index) => (
                            <div className="relative">
                              <img
                                src={URL.createObjectURL(i)}
                                alt=""
                                key={i}
                                className="h-[120px] w-[120px] m-2 object-contain"
                              />
                              {/* <img src={`${process.env.REACT_APP_BACKEND_URL}/${i}`} alt="" key={i} className='h-[120px] w-[120px] m-2 object-contain' /> */}
                              <TiDelete
                                size={25}
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 cursor-pointer"
                                onClick={() => handleDeleteImage2(index)}
                              />
                            </div>
                          ))}
                  </div>
                </div>
                <div className="w-full flex gap-3 justify-end">
                  <Link to="/admin-press">
                    <div className="bg-[#bbbaba] text-black font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-5 py-2 cursor-pointer">
                      Discard
                    </div>
                  </Link>
                  <button
                    className="bg-[#40890F] text-white font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-5 py-2 cursor-pointer"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AdminEditNews;
