import React, { useEffect } from 'react'
import Header from '../components/Layout/Header'
import Hero from '../components/Layout/Hero'
import Footer from '../components/Layout/Footer'
import Press from '../components/Press/Press.jsx'

const PressPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header activeHeading={8} />
      <div className="h-[11vh]"></div>
      <Hero page={'press'} />
      <Press />
      <Footer />
    </div>
  );
}

export default PressPage 