import React, { useEffect } from 'react'
import Header from '../components/Layout/Header'
import Hero from '../components/Layout/Hero'
import Footer from '../components/Layout/Footer'
import Gallery from '../components/Gallery/Gallery.jsx'

const GalleryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header activeHeading={6} />
      <div className="h-[11vh]"></div>
      <Hero page={'gallery'} />
      <Gallery />
      <Footer />
    </div>
  );
}

export default GalleryPage  