import React, { useCallback, useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineStar,
} from "react-icons/ai";
import { LiaHeart, LiaHeartSolid } from "react-icons/lia";
import styles from "../../styles/styles";
import { products } from "../../static/data";
import { CgLayoutList, CgLayoutGrid } from "react-icons/cg";
import "../../styles/styles.css";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { useDispatch, useSelector } from "react-redux";
import Ratings from "../ProductDetails/Ratings";
import axios from "axios";
import Drawer from "@mui/material/Drawer";

const ProductList = () => {
  const { allProducts } = useSelector((state) => state.products);

  return (
    <div>
      <div className={`${styles.container}`}>
        <marquee className="font-Poppins font-[500] text-[20px]"> Our Website is revamping for any queries related franchise,  please contact us</marquee>
        <div className="flex 800px:flex-row flex-col h-auto gap-5">
          <ProductSidebar allProducts={allProducts} />
          <Products allProducts={allProducts} />
        </div>
      </div>
    </div>
  );
};

const ProductSidebar = ({ allProducts }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filterProducts = allProducts?.filter((product) =>
      product.name.toLowerCase().includes(term.toLowerCase())
    );
    setSearchData(filterProducts);
  };

  // const sortedProducts = [...allProducts].sort((a, b) => b.sold_out - a.sold_out);
  // const topRatedProducts = sortedProducts.slice(0, 3);

  const [topRatedProducts, setTopRatedProducts] = useState([]);
  const [bestSellers, setbestSellers] = useState([]);

  useEffect(() => {
    if (Array.isArray(allProducts)) {
      const sorted = [...allProducts].sort((a, b) => b.sold_out - a.sold_out);
      const topRated = sorted.slice(0, 3);
      setTopRatedProducts(topRated);
      const bestSellers = topRatedProducts.map((item) => item.name);
      setbestSellers(bestSellers);
    }
  }, [allProducts]);

  return (
    <div className="800px:w-[30%] w-full h-[100%] flex flex-col gap-5">
      {/* search box */}
      <div className="flex flex-col gap-2 justify-center px-5 h-[182px] w-full bg-[#F4F3F3]">
        <h3 className="font-Poppins font-[500] text-[24px]">Search</h3>
        <div className="w-full relative">
          <input
            type="text"
            placeholder="Search our products..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="h-[50px] w-full px-2"
          />
          <AiOutlineSearch
            size={30}
            className="absolute right-2 top-1.5 cursor-pointer"
          />
          {searchData && searchData.length !== 0 && searchTerm ? (
            <div className="absolute min-h-[20vh] bg-slate-50 shadow-sm z-[9] p-4 w-full">
              {searchData &&
                searchData.map((i, index) => {
                  const d = i.name;
                  const Product_name = d.replace(/\s+/g, "-");
                  return (
                    <Link to={`/product/${i._id}`}>
                      <div className="w-full flex items-center py-3">
                        <img
                          src={`${process.env.REACT_APP_BUCKET_URL}${i.images[0]}`}
                          alt="product"
                          className="w-[40px] h-[40px] mr-[10px] object-contain"
                        />
                        <h1>{i.name}</h1>
                      </div>
                    </Link>
                  );
                })}
            </div>
          ) : null}
          {searchTerm && searchData.length == 0 ? (
            <div className="absolute min-h-[20vh] bg-slate-50 shadow-sm z-[9] p-4 w-full">
              <h1>No data found!</h1>
            </div>
          ) : null}
        </div>
      </div>

      {/* top rated products */}
      <div className="hidden 800px:flex flex-col gap-2 justify-center p-5 w-full bg-[#F4F3F3]">
        <h3 className="font-Poppins font-[500] text-[24px]">
          Top Sold Products
        </h3>

        {topRatedProducts?.map((i, index) => (
          <div className="flex flex-col">
            <div className="flex gap-2">
              <div className="bg-white h-[94px] w-[108px] hidden 900px:flex items-center">
                <img
                  src={`${process.env.REACT_APP_BUCKET_URL}${
                    i.images && i.images[0]
                  }`}
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="flex flex-col gap-1 justify-center">
                <div className="flex">
                  <Ratings rating={i?.ratings} />
                </div>
                <Link to={`/product/${i._id}`}>
                  <h6 className="font-Poppins font-[500] text-[14px]">
                    {i.name}
                  </h6>
                </Link>
                <h6 className="font-Poppins font-[600] text-[18px] text-[#40890F]">
                  ₹
                  {Math.min.apply(
                    null,
                    i?.variants?.map((item) => item?.price)
                  )}
                </h6>
              </div>
            </div>
          </div>
        ))}
        <h3 className="font-Poppins font-[500] text-[24px]">
          Types of Tea
        </h3>
        <h6 className="font-Poppins font-[400] text-[18px]">
          Signature Chai
        </h6>
        <h6 className="font-Poppins font-[400] text-[18px]">
          Indian masala chai
        </h6>
        <h6 className="font-Poppins font-[400] text-[18px]">
          orginal kadak Chai
        </h6>
        <h6 className="font-Poppins font-[400] text-[18px]">
          Banarasi paan chai
        </h6>
        <h6 className="font-Poppins font-[400] text-[18px]">
          Wayanadan cardomom & rose chai
        </h6>
      </div>

      {/* size */}
      <div className="hidden 800px:flex flex-col gap-2 justify-center px-5 h-[182px] w-full bg-[#F4F3F3]">
        <h3 className="font-[500] text-[24px]">Size</h3>
        <div className="flex gap-3 flex-wrap">
          <div className="border-[1.5px] border-[#3C3C3C] w-[53px] h[29px] flex justify-center">
            <p>100 G</p>
          </div>
          <div className="border-[1.5px] border-[#3C3C3C] w-[53px] h[23px] flex justify-center">
            <p>250 G</p>
          </div>
          <div className="border-[1.5px] border-[#3C3C3C] w-[53px] h[23px] flex justify-center">
            <p>500 G</p>
          </div>
          <div className="border-[1.5px] border-[#3C3C3C] w-[53px] h[23px] flex justify-center">
            <p>1 KG</p>
          </div>
          <div className="border-[1.5px] border-[#3C3C3C] w-[53px] h[23px] flex justify-center">
            <p>3 KG</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Products = ({ allProducts }) => {
  const [products, setProducts] = useState("");
  const [active, setActive] = useState("grid");
  const [sort, setSort] = useState("bestSelling");
  const [pageNum, setPageNum] = useState(1);

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  const getProducts = async () => {
    let limit;
    if (active === "grid") {
      if (window.innerWidth > 1210) {
        limit = 9;
      } else if (window.innerWidth > 825) {
        limit = 6;
      } else if (window.innerWidth > 565) {
        limit = 6;
      } else {
        limit = 3;
      }
    } else {
      limit = 3;
    }
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}/product/get-all-paginated-products?pageNum=${pageNum}&limit=${limit}&sort=${sort}`
    );
    setProducts(data.products);
  };

  useEffect(() => {
    getProducts();
  }, [active, pageNum, sort]);

  const setLayoutGrid = () => {
    setActive("grid");
  };

  const setLayoutList = () => {
    setActive("list");
  };

  const bestSellingSortedProduct =
    products.docs && [...products.docs].sort((a, b) => b.sold_out - a.sold_out);
  const highestPriceSortedProduct =
    products.docs && [...products.docs].sort((a, b) => b.price - a.price);
  const lowestPriceSortedProduct =
    products.docs && [...products.docs].sort((a, b) => a.price - b.price);

  const [bestSellers, setBestSellers] = useState([]);
  const [topRatedProducts, setTopRatedProducts] = useState([]);
  useEffect(() => {
    if (Array.isArray(allProducts)) {
      const sorted = [...allProducts].sort((a, b) => b.sold_out - a.sold_out);
      const topRated = sorted.slice(0, 3);
      setTopRatedProducts(topRated);
    }
  }, [allProducts]);
  useEffect(() => {
    if (topRatedProducts.length > 0) {
      const bestSellers = topRatedProducts.map((item) => item.name);
      setBestSellers(bestSellers);
    }
  }, [topRatedProducts]);

  return (
    <div className="800px:w-[70%] w-full flex flex-col gap-5">
      <div className="w-full h-[70px] border-[1.5px] border-[#3C3C3C] flex justify-between items-center p-5 ">
        <div className="flex">
          <CgLayoutGrid
            size={50}
            className="cursor-pointer"
            onClick={setLayoutGrid}
          />
          <CgLayoutList
            size={50}
            className="-ml-4 cursor-pointer"
            onClick={setLayoutList}
          />
        </div>
        <p className="800px:flex hidden font-Poppins font-[500] text-[14px]">
          Showing {products?.pagingCounter} -{" "}
          {products.hasNextPage
            ? products?.pagingCounter + products?.limit - 1
            : products?.totalDocs}{" "}
          of {products?.totalDocs} result
        </p>
        <div className="400px:flex hidden items-center">
          <p className="font-Poppins font-[500] text-[14px]">Sort by:</p>
          <div className="h-[36px] w-[157px] border-[1.5px] border-[#3C3C3C] ml-2 flex justify-center">
            <select
              name="sort-by"
              id="sort-by"
              onChange={(e) => setSort(e.target.value)}
            >
              <option value="bestSelling">Best seller</option>
              <option value="lowPrice">Price - low to high</option>
              <option value="highPrice">Price - high to low</option>
            </select>
          </div>
        </div>
        <div className="400px:hidden flex">
          <button
            onClick={openDrawer}
            className="font-Poppins font-[500] text-[14px]"
          >
            Filter
          </button>
        </div>
        <Drawer anchor="bottom" open={isVisible} onClose={closeDrawer}>
          <div className="h-[30vh] overflow-y-auto">
            <div className="flex items-center py-5 px-3">
              <p className="font-Poppins font-[500] text-[14px]">Sort by:</p>
              <div className="h-[36px] w-[157px] border-[1.5px] border-[#3C3C3C] bg-white ml-2 flex justify-center">
                <select
                  name="sort-by"
                  id="sort-by"
                  onChange={(e) => {setSort(e.target.value); closeDrawer()}}
                  className="bg-white"
                >
                  <option value="bestSelling">Best seller</option>
                  <option value="lowPrice">Price - low to high</option>
                  <option value="highPrice">Price - high to low</option>
                </select>
              </div>
            </div>
          </div>
        </Drawer>
      </div>

      <p className="800px:hidden flex font-Poppins font-[500] text-[14px]">
        Showing {products?.pagingCounter} -{" "}
        {products.hasNextPage
          ? products?.pagingCounter + products?.limit - 1
          : products?.totalDocs}{" "}
        of {products?.totalDocs} result
      </p>

      {active === "grid" ? (
        <div className="flex flex-wrap">
          {sort === "bestSelling"
            ? products.docs &&
              products.docs.map((i, index) => (
                <ProductCard1
                  key={index}
                  image={i.images[0]}
                  name={i.name}
                  price={Math.min.apply(
                    null,
                    i?.variants?.map((item) => item?.price)
                  )}
                  data={i}
                  bestSellers={bestSellers}
                />
              ))
            : sort === "highPrice"
            ? products.docs &&
              products.docs.map((i, index) => (
                <ProductCard1
                  key={index}
                  image={i.images[0]}
                  name={i.name}
                  price={Math.min.apply(
                    null,
                    i?.variants?.map((item) => item?.price)
                  )}
                  data={i}
                  bestSellers={bestSellers}
                />
              ))
            : products.docs &&
              products.docs.map((i, index) => (
                <ProductCard1
                  key={index}
                  image={i.images[0]}
                  name={i.name}
                  price={Math.min.apply(
                    null,
                    i?.variants?.map((item) => item?.price)
                  )}
                  data={i}
                  bestSellers={bestSellers}
                />
              ))}
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          {sort === "bestSelling"
            ? products.docs.map((i, index) => (
                <ProductCard2
                  key={index}
                  image={i.images[0]}
                  name={i.name}
                  price={Math.min.apply(
                    null,
                    i?.variants?.map((item) => item?.price)
                  )}
                  description={i.description}
                  data={i}
                  bestSellers={bestSellers}
                />
              ))
            : sort === "highPrice"
            ? products.docs.map((i, index) => (
                <ProductCard2
                  key={index}
                  image={i.images[0]}
                  name={i.name}
                  price={Math.min.apply(
                    null,
                    i?.variants?.map((item) => item?.price)
                  )}
                  description={i.description}
                  data={i}
                  bestSellers={bestSellers}
                />
              ))
            : products.docs.map((i, index) => (
                <ProductCard2
                  key={index}
                  image={i.images[0]}
                  name={i.name}
                  price={Math.min.apply(
                    null,
                    i?.variants?.map((item) => item?.price)
                  )}
                  description={i.description}
                  data={i}
                  bestSellers={bestSellers}
                />
              ))}
        </div>
      )}
      <div className="flex justify-end mt-auto">
        <Stack spacing={2}>
          <Pagination
            count={products?.totalPages}
            page={pageNum}
            onChange={(e, number) => setPageNum(number)}
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

const ProductCard1 = ({ image, name, price, data, bestSellers }) => {
  const dispatch = useDispatch();
  const { wishlist } = useSelector((state) => state.wishlist);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <div className="min-w-[254px] w-full 550px:w-1/2 1150px:w-1/3 h-[290px] mb-5">
      <div className="relative h-full 550px:mr-5 mr-0 shadow-xl hover:shadow-none transition-shadow duration-300 flex flex-col items-center justify-center gap-2">
        <div className="triangle absolute top-0 right-0 bg-[#F4F3F3]  py-[45px] px-[45px]"></div>
        {bestSellers?.includes(name) && (
          <div className="400px:hidden block bg-[red] absolute top-5 left-0 text-white px-2 ribbon font-Poppins font-[500] text-[13px]">
            Best seller
          </div>
        )}
        {click === false ? (
          <LiaHeart
            size={30}
            className="absolute top-3 right-3 cursor-pointer"
            onClick={() => addToWishlistHandler(data)}
            title="add to wishlist"
          />
        ) : (
          <LiaHeartSolid
            size={30}
            className="absolute top-3 right-3 cursor-pointer text-[#40890F]"
            onClick={() => removeFromWishlistHandler(data)}
            title="remove from wishlist"
          />
        )}
        <div className="w-[190px] h-[130px]">
          <img
            src={`${process.env.REACT_APP_BUCKET_URL}${image}`}
            alt=""
            className="object-contain"
          />
        </div>
        <div className="flex">
          <Ratings rating={data?.ratings} />
        </div>
        <Link
          to={`/product/${data._id}`}
          className="flex flex-col items-center"
        >
          <h6 className="font-Poppins font-[500] text-[14px]">{name}</h6>
          <h6 className="font-Poppins font-[600] text-[18px] text-[#40890F]">
            ₹{price}
          </h6>
        </Link>
      </div>
    </div>
  );
};

const ProductCard2 = ({ image, name, price, description, data }) => {
  const dispatch = useDispatch();
  const { wishlist } = useSelector((state) => state.wishlist);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <div className="w-full h-auto py-10 pr-5 shadow-xl hover:shadow-none transition-shadow duration-300 flex 400px:flex-row flex-col gap-2 relative">
      <div className="triangle absolute top-0 right-0 bg-[#F4F3F3]  py-[45px] px-[45px]"></div>
      {click === false ? (
        <LiaHeart
          size={30}
          className="absolute top-3 right-3 cursor-pointer"
          onClick={() => addToWishlistHandler(data)}
          title="add to wishlist"
        />
      ) : (
        <LiaHeartSolid
          size={30}
          className="absolute top-3 right-3 cursor-pointer text-[#40890F]"
          onClick={() => removeFromWishlistHandler(data)}
          title="remove from wishlist"
        />
      )}
      <div className="400px:w-[30%] w-full h-full flex justify-center items-center">
        <img
          src={`${process.env.REACT_APP_BUCKET_URL}${image}`}
          alt=""
          className="w-[194px] h-[132px] object-contain"
        />
      </div>
      <div className="400px:w-[70%] w-full 400px:px-0 px-3 flex flex-col gap-2">
        <Link
          // to={`/product/${name.replace(/\s+/g, "-")}`}
          to={`/product/${data._id}`}
          className="font-Poppins font-[500] text-[14px]"
        >
          {name}
        </Link>
        <div className="flex">
          <Ratings rating={data?.ratings} />
          <p className="ml-2 font-Poppins font-[300] text-[14px]">
            {data?.reviews?.length} reviews
          </p>
        </div>
        <h6 className="font-Poppins font-[600] text-[18px] text-[#40890F]">
          ₹{price}
        </h6>
        <hr />
        <p className="font-Poppins font-[300] text-[14px]">{description}</p>
      </div>
    </div>
  );
};

export default ProductList;
