import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import styles from "../../styles/styles";
import Navbar from "./Navbar";
import { BiMenuAltLeft } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import Drawer from "@mui/material/Drawer";

const Header = ({ activeHeading }) => {
  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  return (
    <>
      <div className="w-full h-[11vh] bg-white shadow-md fixed z-50">
        <div className={`${styles.container} !py-0`}>
          <div className="hidden 800px:h-[11vh] 800px:flex items-center justify-between">
            {/* logo */}
            <div>
              <Link to="/">
                <img
                  src={Logo}
                  alt=""
                  className="1000px:w-[280px] 1000px:h-[66px] 800px:w-[20vw] 800px:h-[40px] w-[240px] h-[54px]"
                />
              </Link>
            </div>
            {/* nav items */}
            <div className="flex items-center justify-center">
              <Navbar active={activeHeading} />
            </div>
          </div>

          {/* mobile section */}
          <div className="800px:hidden flex h-[11vh] items-center ">
            <div>
              <BiMenuAltLeft
                size={40}
                className="ml-4 mt-3"
                onClick={openDrawer}
              />
            </div>
            <div className="mx-auto">
              <Link to="/">
                <img src={Logo} alt="" className="w-[240px] h-[54px]" />
              </Link>
            </div>
          </div>

          <Drawer anchor="left" open={isVisible} onClose={closeDrawer}>
            <div className="min-w-[250px] flex justify-end pr-3">
              <RxCross1
                size={30}
                className="ml-4 mt-5"
                onClick={closeDrawer}
              />
            </div>

            <div className="w-full pl-3 h-screen">
              <Navbar active={activeHeading} />
            </div>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default Header;
