import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ErrorMessage, Form, Formik } from "formik";
import { subAdminFormSchema } from "../../schema/valiationSchema";
import { BiError } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const AdminAddSubAdmin = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("account");
  const [permissions, setPermissions] = useState([]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((permission) => permission !== value));
    }
  };

  const handleSubmit = async (e, i) => {
    if (permissions?.length > 0) {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };

    const newForm = new FormData();

    newForm.append("name", e.name);
    newForm.append("email", e.email);
    newForm.append("password", e.password);
    newForm.append("confirmPassword", e.confirmPassword);
    newForm.append("permissions", JSON.stringify(permissions));

    axios
      .post(`${process.env.REACT_APP_SERVER}/admin/create-subAdmin`, newForm, config)
      .then((res) => {
        toast.success(res.data.message);
        setPermissions([]);
        // setTab("account");
        navigate("/admin-subAdmin");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });

    } else {
      toast.error("Atleast 1 permission is required");
    }
  };

  return (
    <div className="w-[80vw] h-[89vh] mt-[11vh] overflow-y-auto bg-[#F2F2F2]">
      <div className={`max-w-[1400px] w-[100%] h-[89vh] px-[20px] py-[20px]`}>
        <div className="w-full h-full">
          <div className="flex flex-col">
            <h2 className="font-[Montserrat] font-[600] text-[20px]">
              CREATE SUB-ADMIN
            </h2>
            <span className="font-[Montserrat] font-[500] text-[10px] -mt-1">
              THE CHAI WALAH Admin Panel
            </span>
          </div>
          <div className="w-full h-[70vh] rounded-[8px] bg-white mt-[20px]">
            <div className="flex gap-5 h-[7vh] items-center px-5">
              <h3
                onClick={() => setTab("account")}
                className={`${
                  tab === "account" ? "border-b-2 border-[#40890F]" : "null"
                } h-full px-3 flex items-center font-[Montserrat] font-[500] text-[18px] cursor-pointer`}
              >
                Account
              </h3>
              <h3
                onClick={() => setTab("permission")}
                className={`${
                  tab === "permission" ? "border-b-2 border-[#40890F]" : "null"
                } h-full px-3 flex items-center font-[Montserrat] font-[500] text-[18px] cursor-pointer`}
              >
                Permission
              </h3>
            </div>
            <hr className="text-[#3C3C3C80]" />
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={subAdminFormSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange }) => (
                <Form autoComplete="off">
                  {/* <form onSubmit={handleSubmit}> */}
                  {tab === "account" ? (
                    <div className="px-5 mt-[20px]">
                      <h2 className="font-[Montserrat] font-[600] text-[20px]">
                        Account Details
                      </h2>
                      <div className="mt-[20px] flex flex-col gap-4">
                        <div className="w-full flex justify-between items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] w-[30%]">
                            <span className="text-[#40890F]">*</span> Name :
                          </label>
                          <div className="flex flex-col w-full">
                            <input
                              type="text"
                              name="name"
                              value={values.name}
                              className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              onChange={handleChange}
                              // placeholder="Enter your product price..."
                            />
                            <ErrorMessage name="name">
                              {(msg) => (
                                <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                                  *{" "}
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="w-full flex justify-between items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] w-[30%]">
                            <span className="text-[#40890F]">*</span> Email :
                          </label>
                          <div className="flex flex-col w-full">
                            <input
                              type="text"
                              name="email"
                              value={values.email}
                              className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              onChange={handleChange}
                              // placeholder="Enter your product price..."
                              autoComplete="off"
                            />
                            <ErrorMessage name="email">
                              {(msg) => (
                                <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                                  *{" "}
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="w-full flex justify-between items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] w-[30%]">
                            <span className="text-[#40890F]">*</span> Password :
                          </label>
                          <div className="flex flex-col w-full">
                            <input
                              type="password"
                              name="password"
                              value={values.password}
                              className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              onChange={handleChange}
                              // placeholder="Enter your product price..."
                              autoComplete="off"
                            />
                            <ErrorMessage name="password">
                              {(msg) => (
                                <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                                  *{" "}
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="w-full flex justify-between items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] w-[30%]">
                            <span className="text-[#40890F]">*</span> Confirm
                            Password :
                          </label>
                          <div className="flex flex-col w-full">
                            <input
                              type="password"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              className="h-[40px] rounded-[8px] px-3 border border-gray-300 w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              onChange={handleChange}
                              // placeholder="Enter your product price..."
                              
                            />
                            <ErrorMessage name="confirmPassword">
                              {(msg) => (
                                <div className="flex items-center gap-1 font-Poppins font-[400] text-[14px] text-[red] text-left px-2 py-0.5 rounded-md -mt-0 self-start">
                                  *{" "}
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="w-full flex justify-end">
                          <div
                            className="bg-[#40890F] text-white font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-5 py-2"
                            onClick={() => setTab("permission")}
                          >
                            Next
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="px-5 mt-[20px]">
                      <h2 className="font-[Montserrat] font-[600] text-[20px]">
                        Enable required permissions
                      </h2>
                      <div className="mt-[20px] flex flex-col gap-6">
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Product Managment :
                          </label>

                          <label
                            htmlFor="create-product"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Create{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="create-product"
                            id="create-product"
                            value="createProduct"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("createProduct")}
                          />
                          <label
                            htmlFor="read-product"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-product"
                            id="read-product"
                            value="readProduct"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readProduct")}
                          />
                          <label
                            htmlFor="update-product"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Update{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="update-product"
                            id="update-product"
                            value="updateProduct"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("updateProduct")}
                          />
                          <label
                            htmlFor="delete-product"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Delete{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="delete-product"
                            id="delete-product"
                            value="deleteProduct"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("deleteProduct")}
                          />
                        </div>
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Press Managment :
                          </label>

                          <label
                            htmlFor="create-news"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Create{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="create-news"
                            id="create-news"
                            value="createNews"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("createNews")}
                          />
                          <label
                            htmlFor="read-news"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-news"
                            id="read-news"
                            value="readNews"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readNews")}
                          />
                          <label
                            htmlFor="update-news"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Update{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="update-news"
                            id="update-news"
                            value="updateNews"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("updateNews")}
                          />
                          <label
                            htmlFor="delete-news"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Delete{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="delete-news"
                            id="delete-news"
                            value="deleteNews"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("deleteNews")}
                          />
                        </div>
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Gallery Managment :
                          </label>

                          <label
                            htmlFor="create-gallery"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Create{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="create-gallery"
                            id="create-gallery"
                            value="createGallery"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("createGallery")}
                          />
                          <label
                            htmlFor="read-gallery"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-gallery"
                            id="read-gallery"
                            value="readGallery"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readGallery")}
                          />
                          <label
                            htmlFor="update-gallery"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Delete{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="delete-gallery"
                            id="delete-gallery"
                            value="deleteGallery"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("deleteGallery")}
                          />
                        </div>
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Order Managment :
                          </label>

                          <label
                            htmlFor="read-order"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-order"
                            id="read-order"
                            value="readOrder"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readOrder")}
                          />
                          <label
                            htmlFor="update-order"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Update{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="update-order"
                            id="update-order"
                            value="updateOrder"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("updateOrder")}
                          />
                        </div>
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Customer Managment :
                          </label>

                          <label
                            htmlFor="read-customer"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-customer"
                            id="read-customer"
                            value="readCustomer"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readCustomer")}
                          />
                          <label
                            htmlFor="update-customer"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Update{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="update-customer"
                            id="update-customer"
                            value="updateCustomer"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("updateCustomer")}
                          />
                        </div>
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Review Managment :
                          </label>

                          <label
                            htmlFor="read-review"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-review"
                            id="read-review"
                            value="readReview"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readReview")}
                          />
                          <label
                            htmlFor="update-review"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Update{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="update-review"
                            id="update-review"
                            value="updateReview"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("updateReview")}
                          />
                        </div>
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Enquiry Managment :
                          </label>

                          <label
                            htmlFor="read-enquiry"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-enquiry"
                            id="read-enquiry"
                            value="readEnquiry"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readEnquiry")}
                          />
                          <label
                            htmlFor="delete-enquiry"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Delete{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="delete-enquiry"
                            id="delete-enquiry"
                            value="deleteEnquiry"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("deleteEnquiry")}
                          />
                        </div>
                        <div className="w-full flex gap-2 items-center">
                          <label className="font-[Montserrat] font-[500] text-[16px] pr-5 w-[20%]">
                            Dashboard Managment :
                          </label>

                          <label
                            htmlFor="read-dashboard"
                            className="font-[Montserrat] font-[400] text-[16px]"
                          >
                            {" "}
                            Read{" "}
                          </label>
                          <input
                            type="checkbox"
                            name="read-dashboard"
                            id="read-dashboard"
                            value="readDashboard"
                            onChange={handleCheckboxChange}
                            checked={permissions.includes("readDashboard")}
                          />
                        </div>
                        <div className="w-full flex justify-end">
                          <button className="bg-[#40890F] text-white font-[Montserrat] font-[500] text-[16px] rounded-[7px] px-5 py-2">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* </form> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddSubAdmin;
