import React, { useEffect, useState } from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import NewsDetails from '../components/Press/NewsDetails.jsx'
import { useParams } from 'react-router-dom'
import { products } from '../static/data'
import { useSelector } from 'react-redux'
import axios from 'axios'

const NewsDetailsPage = () => {
  const { id } = useParams();

  const [news, setNews] = useState("");
  const [latest, setLatest] = useState("");

    const getNews = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/press/get-news/${id}`, {
            withCredentials: true,
        });
        setNews(data?.news);
        } catch (error) {
        console.log(error);
        }
    };

    const getLatest = async () => {
        try {
          let limit = 5;
          const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER}/press/get-all-news-public?pageNum=1&limit=${limit}`,
            {
              withCredentials: true,
            }
          );
          setLatest(data?.news);
        } catch (error) {
          console.log(error);
        }
      };

    useEffect(() => {
        getNews();
        getLatest();
    }, []);

  return (
    <div>
      <Header />
      <div className="h-[11vh]"></div>
      <NewsDetails data={news} latest={latest.docs} />
      <Footer />
    </div>
  );
}

export default NewsDetailsPage 