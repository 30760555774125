import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData"))
    : null,
  isAuthenticated: false,
};

export const userReducer = createReducer(initialState, {
  LoadUserRequest: (state) => {
    state.loading = true;
  },
  LoadUserSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.user = action.payload;
  },
  LoadUserFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },

  LogoutUser: (state, action) => {
    state.isAuthenticated = false;
    state.user = null;
  },

  // update user information
  updateUserInfoRequest: (state) => {
    state.loading = true;
  },
  updateUserInfoSuccess: (state, action) => {
    state.loading = false;
    state.user = action.payload;
  },
  updateUserInfoFailed: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // update user address
  updateUserAddressRequest: (state) => {
    state.addressloading = true;
  },
  updateUserAddressSuccess: (state, action) => {
    state.addressloading = false;
    state.successMessage = action.payload.successMessage;
    state.user = action.payload.user;
  },
  updateUserAddressFailed: (state, action) => {
    state.addressloading = false;
    state.error = action.payload;
  },

  // delete user address
  deleteUserAddressRequest: (state) => {
    state.addressloading = true;
  },
  deleteUserAddressSuccess: (state, action) => {
    state.addressloading = false;
    state.successMessage = action.payload.successMessage;
    state.user = action.payload.user;
  },
  deleteUserAddressFailed: (state, action) => {
    state.addressloading = false;
    state.error = action.payload;
  },

  // update user status -- admin
  updateUserStatusRequest: (state) => {
    state.statusloading = true;
  },
  updateUserStatusSuccess: (state, action) => {
    state.statusloading = false;
    state.successMessage = action.payload.successMessage;
    state.allUsers = action.payload.allUsers;
  },
  updateUserStatusFailed: (state, action) => {
    state.statusloading = false;
    state.error = action.payload;
  },

  // get all users
  getAllUsersRequest: (state) => {
    state.isLoading = true;
  },
  getAllUsersSuccess: (state, action) => {
    state.isLoading = false;
    state.allUsers = action.payload;
  },
  getAllUsersFailed: (state, action) => {
    state.isLoading = false;
    state.getUsersError = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
